import * as actionTypes from '../actionTypes';
import { updateObject } from './utility';

const initialState = {
  isLoading: true,
  error: null,

  activeConversationId: null,

  recipients: [],
  tickets: [],
};

const setLoading = (action, state) => {
  return updateObject(state, {
    isLoading: true,
  });
};

const resetLoading = (action, state) => {
  return updateObject(state, {
    isLoading: false,
  });
};
const setTickets = (action, state) => {
  return updateObject(state, {
    isLoading: false,
    tickets: action.payload,
  });
};

const setConversation = (action, state) => {
  console.log(action);
  return updateObject(state, {
    isLoading: false,
    activeConversationId: action.payload,
  });
};

const chat = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_LOADING:
      return setLoading(action, state);
    case actionTypes.RESET_LOADING:
      return resetLoading(action, state);
    case actionTypes.SET_TICKETS:
      return setTickets(action, state);
    case actionTypes.SET_CONVERSATION:
      return setConversation(action, state);

    default:
      return state;
  }
};

export default chat;
