import React, { useEffect, useState, memo, useCallback, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MerchantWalletAddress from './model/MerchantWalletAddress';
import MessageModal from './MessageModal';
import { faSignOutAlt, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import {
  Nav,
  Image,
  Card,
  Navbar as MyNavbar,
  Dropdown,
  Container,
  ListGroup,
} from '@themesberg/react-bootstrap';
import _ from 'lodash';
import notif from '../assets/img/icons/notif.svg';
import { IconButton, Stack, Typography } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import profile3 from '../assets/img/team/vanilla-profile3.png';
import { Routes } from '../routes';
import arrowDown from '../assets/img/icons/arrowDown.svg';
import { toast } from 'react-toastify';
import alert from '../assets/sound/notifAlert.wav';
import PaymentModal from './PaymentModal';
import * as actionTypes from '../redux/actionTypes';
import Label from './Label';
import WS from '../utils/WsClient';
import { toggleSidebar } from '../redux/actions/config';
import ListIcon from '@mui/icons-material/List';
import ButtonPrimary from './ButtonPrimary';
import { loadNextNotifications } from '../redux/actions/notification';
import InfiniteScroll from 'react-infinite-scroll-component';
import { formatWithoutTimezone } from '../utils/common';

const Navbar = ({ handleQRDialog, getNotificationHistoryAction }) => {
  const { showSidebar, pageTitle, pageSubTitle } = useSelector(
    (state) => state.configReducer
  );
  const { userRole } = useSelector((state) => state.authReducer);

  const { permission, user:{role:{key}} } = useSelector((state) => state.profileReducer);

  // const [newNotifications, setNewNotifications] = useState(false);
  // const [merchantDetail, setMerchantDetail] = useState(null);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [isMerchantWalletAddressModel, setisMerchantWalletAddressModel] =
    useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [userCurrency, setUserCurrency] = useState({
    symbol: '',
    decimalPlace: 2,
    symbolPrefix: false,
  });
  const [notificationPage, setNotificationPage] = useState(1);

  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { notifications, newNotifications, hasMore } = useSelector(
    (state) => state.notificationReducer
  );
  const merchantDetail = useSelector(
    (state) => state.businessReducer.businessDetails
  );
  const {is_mediator:isMediator} = useSelector((state) => state.profileReducer?.user);

  const playAudio = () => {
    const audio = new Audio(alert);
    audio.load();
    audio.play();
  };
  const toastContent = useCallback(
    (title, message, metadata) => (
      <Stack sx={{ flexDirection: 'column' }}>
        <Stack sx={{ mb: 2 }}>
          <Stack>
            <Typography
              variant="subtitle2"
              sx={{
                // wordBreak: { sm: 'break-all', md: 'initial' },
                color: 'black',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {title ?? '--'}
            </Typography>
          </Stack>
          <Stack>
            <Typography
              sx={{
                width: '100%',
                color: '#4a4a4a',
                whiteSpace: 'normal',
              }}
              variant="caption"
            >
              {message}
            </Typography>
          </Stack>
        </Stack>
        {metadata?.link && (
          <ButtonPrimary
            onClick={() => {
              if (metadata?.link) {
                history.push(metadata?.link);
              }
            }}
            style={{ width: '100%' }}
          >
            View
          </ButtonPrimary>
        )}
      </Stack>
    ),
    [history]
  );
  const subscribeNotification = useCallback(async () => {
    let token = localStorage.getItem('accessToken');
    WS.initNotification(token, () => {
      WS.recieiveNotification((notification) => {
        if (!_.isEmpty(notification)) {
          playAudio();
          // When new notification arrives show it in toast, update store saying new notification available, and fetch notification history to show in notification
          toast.info(
            toastContent(
              notification?.title,
              notification?.message,
              notification?.metadata
            ),
            { icon: false, autoClose: false }
          );
          dispatch({
            type: actionTypes.SET_NEW_NOTIFICATION_AVAILABLE,
            payload: true,
          });
          dispatch(loadNextNotifications(0, 5));
        }
      });
    });
  }, [dispatch, toastContent]);

  useEffect(() => {
    subscribeNotification();
    dispatch(loadNextNotifications(0, 5));
  }, [subscribeNotification, dispatch]);

  const togglePayModal = () => {
    setShowPaymentModal((prevState) => !prevState);
  };

  const toggleMessageModal = () => {
    setShowMessageModal((prevState) => !prevState);
  };

  let user = JSON.parse(localStorage.getItem('user-info'));

  function logOut() {
    localStorage.clear();
    dispatch({ type: actionTypes.CLEAR_STORE_DATA });
    window.location.href = '/';
  }

  const Notification = (props) => {
    const {
      message,
      title,
      image = profile3,
      modified_date,
      metadata,
      id,
    } = props;
    console.log(props);
    return (
      <ListGroup.Item
        key={id}
        action
        className="border-bottom border-light"
        style={{ cursor: metadata?.link ? 'pointer' : 'default' }}
        onClick={() => {
          if (metadata?.link) {
            history.push(metadata.link);
          }
        }}
      >
        <Stack sx={{ flexDirection: 'column' }}>
          <Stack>
            <Typography
              variant="subtitle2"
              sx={{
                color: 'black',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                lineHeight: 1.1,
              }}
            >
              {title ?? '--'} {id}
            </Typography>
          </Stack>
          <Stack>
            <Typography
              sx={{
                color: '#4a4a4a',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
              variant="caption"
            >
              {message}
            </Typography>
            <Stack
              sx={{ flexDirection: 'row', justifyContent: 'space-between' }}
            >
              <Typography
                sx={{
                  color: '#4a4a4a',
                }}
                variant="caption"
              >
                {formatWithoutTimezone(modified_date, 'dd MMM yyyy')}
              </Typography>
              <Typography
                sx={{
                  color: '#4a4a4a',
                }}
                variant="caption"
              >
                {formatWithoutTimezone(modified_date, 'HH:mm a')}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </ListGroup.Item>
    );
  };

  return (
    <MyNavbar variant="dark" expanded className=" bg-white nav-container">
      <Container fluid className="px-0">
        <div className={`d-flex justify-content-between w-100`}>
          <div className="d-flex align-items-start gap-2">
            {!showSidebar && (
              <IconButton
                aria-label="toggle passphrase visibility"
                onClick={() => {
                  dispatch(toggleSidebar());
                }}
                sx={{
                  color: 'black',
                }}
              >
                {<ListIcon />}
              </IconButton>
            )}
            <div>
              <h3 className="m-0 page-title">{pageTitle}</h3>
              <p>{pageSubTitle}</p>
            </div>
          </div>

          <Nav className="align-items-center gap-2">
            {merchantDetail?.status === 'unfunded' ? (
              <div className="alert-msg">
                <p>Your wallet is not funded </p>
                {userRole === 'administrator' ? (
                  <p>
                    Click{' '}
                    <span
                      className="link"
                      onClick={() => {
                        setisMerchantWalletAddressModel(true);
                      }}
                    >
                      here
                    </span>{' '}
                    to correct it.
                  </p>
                ) : null}
              </div>
            ) : merchantDetail?.status === 'trust_line_not_set' &&
              location.pathname !== '/merchantprofile/wallets' ? (
              <div className="alert-msg">
                <p>Your wallets are not activated</p>
                {userRole === 'administrator' ? (
                  <p>
                    {' '}
                    Click{' '}
                    <span
                      className="link"
                      onClick={() => {
                        history.push('/merchantprofile/wallets');
                      }}
                    >
                      {' '}
                      here{' '}
                    </span>
                    to correct it.
                  </p>
                ) : null}
              </div>
            ) : null}
            {permission?.remittances?.outward?.permission?.add[key] &&
            <ButtonPrimary
              onClick={() => {
                history.push(Routes.AddOutwardRemittance.path);
              }}
            >
              Pay Now
            </ButtonPrimary>
}
            {
              <span className="mb-0 font-small fw-bold font-fam">
                {user?.mto?.name}{' '}
              </span>
            }
            {
              <Label
                variant={'ghost'}
                color={'pending'}
                sx={{ textTransform: 'capitalize' }}
              >
                {`MTO: ${process.env.REACT_APP_STB_ENV}`}
              </Label>
            }
            <Dropdown
              as={Nav.Item}
              onToggle={(isOpened) => {
                console.log(isOpened);
                dispatch({
                  type: actionTypes.SET_NEW_NOTIFICATION_AVAILABLE,
                  payload: false,
                });
              }}
            >
              <Dropdown.Toggle
                as={Nav.Link}
                className="text-dark icon-notifications"
              >
                <span className="icon icon-sm">
                  <img src={notif} alt="bell" srcSet="" />
                  {newNotifications ? (
                    <span className="icon-badge rounded-circle unread-notifications" />
                  ) : null}
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu
                className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-center mt-2 py-0"
                id="scrollableDiv"
                style={{
                  maxWidth: '50rem',
                  maxHeight: '400px',
                  overflow: 'auto',
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    p: 2,
                    pb: 1,
                    color: 'black',
                    borderBottom: '1px solid #eaedf2',
                  }}
                >
                  Notifications
                </Typography>
                <ListGroup className="list-group-flush">
                  <InfiniteScroll
                    dataLength={notifications.length}
                    next={() => {
                      setNotificationPage((prev) => {
                        dispatch(loadNextNotifications(prev, 5));
                        return prev + 1;
                      });
                    }}
                    hasMore={hasMore}
                    loader={
                      <Dropdown.Item
                        style={{ textAlign: 'center', cursor: 'default' }}
                      >
                        Loading...
                      </Dropdown.Item>
                    }
                    scrollableTarget="scrollableDiv"
                    endMessage={
                      <Dropdown.Item
                        style={{ textAlign: 'center', cursor: 'default' }}
                      >
                        You have seen it all for now!
                      </Dropdown.Item>
                    }
                  >
                    {notifications.map((n, i) => (
                      <Notification key={n.id} {...n} />
                    ))}
                  </InfiniteScroll>
                </ListGroup>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                <div className="media d-flex align-items-center gap-2">
                  <Image
                    src={profile3}
                    className="user-avatar md-avatar rounded-circle"
                  />
                  <div className="media-body ms-2 text-dark d-lg-flex flex-column align-items-left d-none">
                    <span className="mb-0 font-small fw-bold font-fam">
                      {user?.first_name} {user?.last_name}
                    </span>
                  </div>
                  <img src={arrowDown} alt="arrow down" />
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                <Dropdown.Item
                  className="fw-bold"
                  onClick={() => history.push(Routes.Profile.path)}
                >
                  <FontAwesomeIcon icon={faUserCircle} className="me-2" /> My
                  Profile
                </Dropdown.Item>
                {/* <Dropdown.Item
                  className="fw-bold"
                  onClick={() => history.push(Routes.Settings.path)}
                >
                  <FontAwesomeIcon icon={faCog} className="me-2" /> Settings
                </Dropdown.Item> */}
                <Dropdown.Item
                  className="fw-bold"
                  onClick={() => history.push(Routes.Support.path)}
                >
                  <FontAwesomeIcon icon={faUserShield} className="me-2" />
                  Support
                </Dropdown.Item>

                <Dropdown.Divider />

                <Dropdown.Item onClick={logOut} className="fw-bold">
                  <Card.Link>
                    <FontAwesomeIcon
                      icon={faSignOutAlt}
                      className="text-danger me-2"
                    />
                    Logout
                  </Card.Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Container>
      {showPaymentModal && (
        <PaymentModal
          userCurrency={userCurrency}
          open={showPaymentModal}
          togglePayModal={togglePayModal}
          goBack={() => {
            togglePayModal();
            setisMerchantWalletAddressModel(!isMerchantWalletAddressModel);
          }}
        />
      )}
      {isMerchantWalletAddressModel && (
        <MerchantWalletAddress
          userCurrency={userCurrency}
          open={isMerchantWalletAddressModel}
          handleClose={() =>
            setisMerchantWalletAddressModel(!isMerchantWalletAddressModel)
          }
          togglePayModal={togglePayModal}
          toggleMessageModal={toggleMessageModal}
        />
      )}
      {showMessageModal ? (
        <MessageModal open={showMessageModal} onClose={toggleMessageModal} />
      ) : null}
    </MyNavbar>
  );
};

export default memo(Navbar);
