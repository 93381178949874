import React from 'react';
import { startCase, toLower } from 'lodash';
import { Box, Stack, Typography, LinearProgress, Avatar } from '@mui/material';
import { formatWithoutTimezone, rowsPerPageOptions } from '../../../../../../utils/common';

import { DataGrid } from '@mui/x-data-grid';
import { tableCellStyle } from '../../../../../../utils/common';
import createAvatar from '../../../../../../utils/createAvatar';

export default function ExchangeOfferHistoryTable({ offerHistory, fetchTranslistLoading, handleChangePage, handleChangeRowsPerPage, filters }) {
  const createDataRow = (item, i) => {
    console.log(item);

    return {
      id: i,

      modifiedDate: formatWithoutTimezone(item.modified_date, 'dd MMM yyyy HH:mm a'),
      modifiedBy: (() => startCase(toLower(`${item?.modified_user?.first_name} ${item?.modified_user?.last_name}`)))(),
      newRate: item.rate,
      validFrom: formatWithoutTimezone(item.valid_from, 'dd MMM yyyy HH:mm a'),
      validTo: formatWithoutTimezone(item.valid_to, 'dd MMM yyyy HH:mm a'),
      details: item,
    };
  };

  const rows = offerHistory?.results?.length > 0 ? offerHistory.results.map((item, i) => createDataRow(item, i)) : [];

  const columns = [
    {
      field: 'modifiedDate',
      headerName: 'Modified On',
      flex: 1,
      minWidth: 200,
      // maxWidth: 200,
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Typography variant='body2' style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },

    {
      field: 'modifiedBy',
      headerName: 'Modified By',
      flex: 1.5,
      minWidth: 200,
      // maxWidth: 200,
      textOverflow: 'ellipsis',
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Stack direction={'row'} sx={{ minWidth: 0, alignItems: 'center' }}>
            <Avatar alt={params.formattedValue} sx={{ mr: 1, bgcolor: createAvatar(params.formattedValue).color }}>
              {createAvatar(params.formattedValue).name}
            </Avatar>
            <Typography
              variant='body2'
              sx={{
                color: 'black',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontWeight: '500',
              }}
            >
              {params.formattedValue}
            </Typography>
          </Stack>
        );
      },
    },

    {
      field: 'newRate',
      headerName: 'New Rate',
      flex: 1,
      minWidth: 150,
      // maxWidth: 200,
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Typography variant='body2' style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },

    {
      field: 'validFrom',
      headerName: 'Valid From',
      flex: 1.5,
      minWidth: 150,
      // maxWidth: 200,
      headerClassName: 'header',
      sortable: false,
      renderCell: (params) => {
        return (
          <Typography variant='body2' style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
    {
      field: 'validTo',
      headerName: 'Valid To',
      flex: 1.5,
      minWidth: 150,
      // maxWidth: 200,
      headerClassName: 'header',
      sortable: false,
      renderCell: (params) => {
        return (
          <Typography variant='body2' style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
  ];

  return (
    <>
      <Box
        sx={{
          // height: 825,
          boxShadow: 'rgba(99, 99, 99, 0.2) 0px 0px 8px 0px',
          borderRadius: '8px',
          width: '100%',
          '& .MuiDataGrid-virtualScrollerRenderZone': {
            '& .MuiDataGrid-row': {
              // backgroundColor: 'rgba(235, 235, 235, .7)',
              fontWeight: 500,
              color: '#667085',
              fontFamily: 'Inter, sans-serif',
              fontSize: '14px',
            },
          },
          '& .header': {
            backgroundColor: '#F4F4F4',
            '& div': {
              '& div': {
                '& div': {
                  '& div': {
                    fontSize: '14px',
                    fontWeight: 600,
                    fontFamily: 'Inter, sans-serif',
                  },
                },
              },
            },
          },
        }}
      >
        <DataGrid
          autoHeight
          sx={{
            borderRadius: '8px',
            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 0px 8px 0px',
            width: '100%',
            cursor: 'pointer',
          }}
          components={{
            LoadingOverlay: LinearProgress,
          }}
          rows={rows}
          columns={columns}
          rowHeight={50}
          disableColumnFilter={true}
          disableColumnMenu={true}
          pagination
          paginationMode='server'
          rowsPerPageOptions={rowsPerPageOptions}
          page={filters.currentPage}
          rowCount={offerHistory?.count}
          pageSize={filters.pageSize}
          onPageChange={(newPage) => handleChangePage(newPage)}
          onPageSizeChange={(newSize) => handleChangeRowsPerPage(newSize)}
          loading={fetchTranslistLoading}
        />
      </Box>
    </>
  );
}
