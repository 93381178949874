import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect, useHistory, useLocation } from "react-router-dom";
import { Routes } from "../routes";
import { useSelector } from "react-redux";

// pages
import DevDashboard from "./dashboard/DevDashboard";
import { Currencies } from "./configurations/currencies";
import Statement from "./merchantprofile/Statement";
import { History, ReportTransactions } from "./transaction";
import { Transactions } from "./reports";
import { MtoLedgerDetails } from "./reports/mtoLedgerDetails";
import { MtoLedgerSummary } from "./reports/mtoLedgerSummary";
import { DepositReport } from "./reports/depostiReport";
import {
  OutwardRemittanceReport,
  InwardRemittanceReport,
} from "./reports/remittanceReport";
import { AvailableRedemptionReport } from "./reports/availableRedemptionReport";
import { RedemptionReport } from "./reports/redemptionReport";
import { WalletMovement } from "./reports/walletMovement";
import { UserKyc } from "./userKyc";
import { UserChat } from "./userChat";
import {
  SystemUser,
  BusinessDetails,
  StoreMenu,
  Counter,
} from "./administration";
// Clients
import {
  SenderIndividualClients,
  SenderBusinessClients,
  Deposits,
  DepositsHistory,
  ClientsSenderDetails,
} from "./clients/senders";
import {
  ReceiverIndividualClients,
  ReceiverBusinessClients,
} from "./clients/receivers";

// Remittance
import {
  OutwardRemittance,
  CustomerCreatedRemittance,
} from "./remittance/outward";
import { InwardRemittance } from "./remittance/inward";
import { RemittanceDetails } from "./remittance/remittanceDetails";

import { AddRemittance } from "./remittance/outward/components";

// Accounts
import { Accounts } from "./Accounts/accounts";
import { Liquidity } from "./Accounts/liquidity";
import { LiquidityHistory } from "./Accounts/liquidity";
import { AccountBalance } from "./Accounts/accounts/components/accountBalance";

// Redeem
import { RedemptionRequestRaised } from "./redemptions/requestRaised";
import { CreateRedeemRequest } from "./redemptions/requestRaised/components/createRedeemRequest";
import { RedemptionRequestReceived } from "./redemptions/requestReceived";
import { RedemptionDetails } from "./redemptions/redemptionDetails";
import { AvailableRedemptions } from "./redemptions/availableRedemptions";

//Configurations
import { InwardRemittanceFees } from "./configurations/fees/inwardRemittanceFees";
import { OutwardRemittanceFees } from "./configurations/fees/outwardRemittanceFees";
import { ExchangeOffers } from "./configurations/exchangeOffers";
import { ExchangeOfferHistory } from "./configurations/exchangeOffers/ExchangeOfferHistory";
import { FinancialAccounts } from "./configurations/financialAccounts";
import { KycLevels } from "./configurations/kycLevels";

// import BusinessDetails from "./administration/BusinessDetails";
// import BusinessDetailsEdit from "./kycdone/BusinessDetailsEdit";
import Invoices from "./Invoices";
import Customer from "./Customer";
import CustomerDetails from "./CustomerDetails";
import Support from "./support/Support";
// import NewTicket from './support/component/NewTicket';
import TicketReply from "./support/component/TicketReply";
import Settings from "./Account/Settings";
import BootstrapTables from "./tables/BootstrapTables";
// import Signin from "./stb/Signin";
// import Signup from "./stb/Signup";
import ForgotPassword from "./stb/ForgotPassword";
import ChangePassword from "./stb/ChangePassword";
import ResetPassword from "./stb/ResetPassword";
import Thankyou from "./stb/Thankyou";
import SignupThanks from "./stb/SingupThanks";
import Lock from "./stb/Lock";
import NotFoundPage from "./stb/NotFound";

// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";

import Authentication from "./stb/Authentication";
import QRDialog from "../components/QRDialog";
import FullLoader from "../components/loaders/FullLoader";

const RouteWithLoader = ({ component: Component, checkAuth, ...rest }) => {
  const history = useHistory();

  if (checkAuth) {
    const token = localStorage.getItem("accessToken");
    if (token) {
      history.push(Routes.DashboardOverview.path);
    }
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <Component {...props} />{" "}
        </>
      )}
    />
  );
};

const RouteWithSidebar = ({
  component: Component,
  showSidebar,
  isAllowed = false,
  permissionLoading = true,
  ...rest
}) => {
  const [showQRDialog, setShowQRDialog] = useState(false);

  let token = localStorage.getItem("accessToken");
  if (!token) {
    return <Redirect to={Routes.Signin.path} />;
  }
  if (permissionLoading) {
    return <FullLoader />;
  }
  if (!isAllowed) {
    return <Redirect to={Routes.NotFound.path} />;
  }

  const handleQRDialog = () => {
    setShowQRDialog(!showQRDialog);
  };

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <Sidebar />
          <main className={`content ${!showSidebar ? "content-expand" : ""}`}>
            <Navbar handleQRDialog={handleQRDialog} />
            <Component {...props} />
            <QRDialog open={showQRDialog} handleClose={handleQRDialog} />
          </main>
        </>
      )}
    />
  );
};

// const RouteWithSidebar = ({
//   component: Component,
//   showSidebar,
//   ...rest
// }) => {
//   const [loaded, setLoaded] = useState(false);
//   const [showQRDialog, setShowQRDialog] = useState(false);

//   let token = localStorage.getItem('accessToken');
//   let user_info = useSelector((state) => state?.profileReducer?.user);
//   //  JSON.parse(localStorage.getItem('user-info'));

//   useEffect(() => {
//     const timer = setTimeout(() => setLoaded(true), 1000);
//     return () => clearTimeout(timer);
//   }, []);

//   if (!token) {
//     return <Redirect to="/signin" />;
//   }

//   const handleQRDialog = () => {
//     setShowQRDialog(!showQRDialog);
//   };

//   return (
//     <Route
//       {...rest}
//       render={(props) => (
//         <>
//           <Sidebar />
//           <main className={`content ${!showSidebar ? 'content-expand' : ''}`}>
//             <Navbar handleQRDialog={handleQRDialog} />
//             <Component {...props} />

//             <QRDialog open={showQRDialog} handleClose={handleQRDialog} />
//           </main>
//         </>
//       )}
//     />
//   );
// };

export default ({ isAuthenticate }) => {
  const { showSidebar } = useSelector((state) => state.configReducer);
  const user = useSelector((state) => state.profileReducer?.user);
  const {is_mediator:isMediator, role:{key:role} = {}} = user
  const { permission, permissionLoading } = useSelector(
    (state) => state.profileReducer
  );
  const location = useLocation();
  const history = useHistory()
  const { pathname } = location;
  // if mediator not allowed to view dashboard then route to outward remittance
  if(isMediator && pathname === '/' && !permission?.dashboard?.permission?.visible[role]){
    history.push(Routes.OutwardRemittance.path);
  }

  return (
    <Switch>
      <RouteWithLoader
        exact
        path={Routes.Signin.path}
        checkAuth
        component={Authentication}
      />
      <RouteWithLoader
        exact
        path={Routes.ForgotPassword.path}
        component={ForgotPassword}
      />
      <RouteWithLoader
        exact
        path={`${Routes.ChangePassword.path}/`}
        component={ChangePassword}
      />
      <RouteWithLoader
        exact
        path={Routes.ResetPassword.path}
        component={ResetPassword}
      />
      <RouteWithLoader exact path={Routes.Thankyou.path} component={Thankyou} />
      <RouteWithLoader
        exact
        path={Routes.Signupthanks.path}
        component={SignupThanks}
      />
      <RouteWithLoader exact path={Routes.Lock.path} component={Lock} />
      <RouteWithLoader
        exact
        path={Routes.NotFound.path}
        component={NotFoundPage}
      />

      {/* pages */}
      <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.DashboardOverview.path}
        isAllowed={permission?.dashboard?.permission?.visible[role]}
        permissionLoading={permissionLoading}
        component={DevDashboard}
      />
      {/* <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.DevDashboard.path}
        isAllowed={permission?.dashboard?.permission?.visible[role]}
        permissionLoading={permissionLoading}
        component={DevDashboard}
      /> */}
      {/* <RouteWithSidebar showSidebar={showSidebar}
      exact path={Routes.Balance.path} component={Balance} /> */}
      <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.Currencies.path}
        permissionLoading={permissionLoading}
        isAllowed={permission?.accounts?.currencies?.permission?.visible[role]}
        component={Currencies}
      />
      <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.ReportTransactions.path}
        isAllowed={permission?.transactions?.permission?.visible[role]}
        permissionLoading={permissionLoading}
        component={ReportTransactions}
      />
      <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.SenderBusinessClients.path}
        permissionLoading={permissionLoading}
        isAllowed={
          permission?.clients?.senders?.business?.permission?.visible[role]
        }
        component={SenderBusinessClients}
      />
      <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.SenderIndividualClients.path}
        permissionLoading={permissionLoading}
        isAllowed={
          permission?.clients?.senders?.individual?.permission?.visible[role]
        }
        component={SenderIndividualClients}
      />
      <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.ClientsSenderDetails.path}
        permissionLoading={permissionLoading}
        isAllowed={
          permission?.clients?.senders?.business?.permission?.visible[role]
        }
        component={ClientsSenderDetails}
      />

      <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.ReceiverBusinessClients.path}
        permissionLoading={permissionLoading}
        isAllowed={
          permission?.clients?.receivers?.business?.permission?.visible[role]
        }
        component={ReceiverBusinessClients}
      />

      <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.Deposits.path}
        permissionLoading={permissionLoading}
        isAllowed={
          permission?.clients?.senders?.deposits?.permission?.visible[role]
        }
        component={Deposits}
      />

      <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.DepositsHistory.path}
        permissionLoading={permissionLoading}
        isAllowed={
          permission?.clients?.senders?.deposits?.permission?.visible[role]
        }
        component={DepositsHistory}
      />

      <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.ReceiverIndividualClients.path}
        permissionLoading={permissionLoading}
        isAllowed={
          permission?.clients?.receivers?.individual?.permission?.visible[role]
        }
        component={ReceiverIndividualClients}
      />
      <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.UserKyc.path}
        permissionLoading={permissionLoading}
        isAllowed={true}
        component={UserKyc}
      />

      <RouteWithSidebar
        showSidebar={showSidebar}
        path={Routes.UserChat.path}
        permissionLoading={permissionLoading}
        isAllowed={true}
        component={UserChat}
      />

      <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.UserChatGeneral.path}
        permissionLoading={permissionLoading}
        isAllowed={true}
        component={UserChat}
      />

      {/* Remittance */}
      <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.OutwardRemittance.path}
        isAllowed={
          permission?.remittances?.outward?.allremittance?.permission?.visible[
            role
          ]
        }
        permissionLoading={permissionLoading}
        component={OutwardRemittance}
      />
      <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.ClientRemittance.path}
        isAllowed={
          permission?.remittances?.outward?.pendingremittance?.permission
            ?.visible[role]
        }
        permissionLoading={permissionLoading}
        component={CustomerCreatedRemittance}
      />

      <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.AddOutwardRemittance.path}
        isAllowed={permission?.remittances?.outward?.permission?.add[role]}
        permissionLoading={permissionLoading}
        component={AddRemittance}
      />

      <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.OutwardRemittanceDetails.path}
        isAllowed={permission?.remittances?.outward?.permission?.visible[role]}
        permissionLoading={permissionLoading}
        component={RemittanceDetails}
      />

      <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.EditDraftRemittance.path}
        isAllowed={permission?.remittances?.outward?.permission?.add[role]}
        permissionLoading={permissionLoading}
        component={AddRemittance}
      />
      <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.InwardRemittance.path}
        isAllowed={permission?.remittances?.inward?.permission?.visible[role]}
        permissionLoading={permissionLoading}
        component={InwardRemittance}
      />

      <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.InwardRemittanceDetails.path}
        isAllowed={permission?.remittances?.inward?.permission?.visible[role]}
        permissionLoading={permissionLoading}
        component={RemittanceDetails}
      />

      {/* REDEMPTIONS */}

      <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.AvailableRedemptions.path}
        permissionLoading={permissionLoading}
        isAllowed={
          permission?.redemptions?.availableRedemptions?.permission?.visible[
            role
          ]
        }
        component={AvailableRedemptions}
      />
      <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.RedemptionsRaised.path}
        permissionLoading={permissionLoading}
        isAllowed={
          permission?.redemptions?.requestsRaised?.permission?.visible[role]
        }
        component={RedemptionRequestRaised}
      />
      <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.CreateRedeemRequest.path}
        permissionLoading={permissionLoading}
        isAllowed={
          permission?.redemptions?.availableRedemptions?.permission?.visible[
            role
          ]
        }
        component={CreateRedeemRequest}
      />
      <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.RedemptionsReceived.path}
        permissionLoading={permissionLoading}
        isAllowed={
          permission?.redemptions?.requestsReceived?.permission?.visible[role]
        }
        component={RedemptionRequestReceived}
      />
      <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.RedemptionRaisedDetails.path}
        permissionLoading={permissionLoading}
        isAllowed={
          permission?.redemptions?.requestsRaised?.permission?.visible[role]
        }
        component={RedemptionDetails}
      />
      <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.RedemptionReceivedDetails.path}
        permissionLoading={permissionLoading}
        isAllowed={
          permission?.redemptions?.requestsReceived?.permission?.visible[role]
        }
        component={RedemptionDetails}
      />

      {/* Configurations */}
      <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.InwardRemittanceFees.path}
        permissionLoading={permissionLoading}
        isAllowed={
          permission?.configurations?.fees?.inwardRemittanceFees?.permission
            ?.visible[role]
        }
        component={InwardRemittanceFees}
      />
      <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.OutwardRemittanceFees.path}
        permissionLoading={permissionLoading}
        isAllowed={
          permission?.configurations?.fees?.outwardRemittanceFees?.permission
            ?.visible[role]
        }
        component={OutwardRemittanceFees}
      />
      <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.ExchangeOffers.path}
        permissionLoading={permissionLoading}
        isAllowed={
          permission?.configurations?.exchangeOffers?.permission?.visible[role]
        }
        component={ExchangeOffers}
      />
      <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.ExchangeOfferHistory.path}
        permissionLoading={permissionLoading}
        isAllowed={
          permission?.configurations?.exchangeOffers?.permission?.visible[role]
        }
        component={ExchangeOfferHistory}
      />
      <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.MtoFinancialAccounts.path}
        permissionLoading={permissionLoading}
        isAllowed={
          permission?.configurations?.financialAccounts?.permission?.visible[role]
        }
        component={FinancialAccounts}
      />
      <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.KycLevels.path}
        permissionLoading={permissionLoading}
        isAllowed={true}
        component={KycLevels}
      />

      <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.Accounts.path}
        isAllowed={permission?.accounts?.account?.permission?.visible[role]}
        permissionLoading={permissionLoading}
        component={Accounts}
      />

      <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.LiquidityHistory.path}
        isAllowed={permission?.accounts?.liquidity?.permission?.visible[role]}
        permissionLoading={permissionLoading}
        component={LiquidityHistory}
      />
      <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.Liquidity.path}
        isAllowed={permission?.accounts?.liquidity?.permission?.visible[role]}
        permissionLoading={permissionLoading}
        component={Liquidity}
      />

      <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.AccountBalance.path}
        permissionLoading={permissionLoading}
        isAllowed={permission?.accounts?.account?.permission?.viewBalance[role]}
        component={AccountBalance}
      />

      {/* <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.Statement.path}
        component={Statement}
      /> */}

      <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.Transaction.path}
        // isAllowed={permission?.reports?.permission?.visible[role]}
        isAllowed={true}
        permissionLoading={permissionLoading}
        component={Transactions}
      />

      {/* <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.MTOLedgerDetails.path}
        // isAllowed={permission?.reports?.permission?.visible[role]}
        isAllowed={true}
        permissionLoading={permissionLoading}
        component={MtoLedgerDetails}
      /> */}
      {/* <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.WalletMovement.path}
        // isAllowed={permission?.reports?.permission?.visible[role]}
        isAllowed={true}
        permissionLoading={permissionLoading}
        component={WalletMovement}
      /> */}
      {/* <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.MTOLedgerSummary.path}
        // isAllowed={permission?.reports?.permission?.visible[role]}
        isAllowed={true}
        permissionLoading={permissionLoading}
        component={MtoLedgerSummary}
      /> */}
      <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.DepositReport.path}
        // isAllowed={permission?.reports?.permission?.visible[role]}
        isAllowed={true}
        permissionLoading={permissionLoading}
        component={DepositReport}
      />
      <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.OutwardRemittanceReport.path}
        // isAllowed={permission?.reports?.permission?.visible[role]}
        isAllowed={true}
        permissionLoading={permissionLoading}
        component={OutwardRemittanceReport}
      />
      <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.InwardRemittanceReport.path}
        // isAllowed={permission?.reports?.permission?.visible[role]}
        isAllowed={true}
        permissionLoading={permissionLoading}
        component={InwardRemittanceReport}
      />
      <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.RedemptionReport.path}
        // isAllowed={permission?.reports?.permission?.visible[role]}
        isAllowed={true}
        permissionLoading={permissionLoading}
        component={RedemptionReport}
      />

      <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.AvailableRedemptionReport.path}
        // isAllowed={permission?.reports?.permission?.visible[role]}
        isAllowed={true}
        permissionLoading={permissionLoading}
        component={AvailableRedemptionReport}
      />
      {/* <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.History.path}
        component={History}
      /> */}
      <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.SystemUser.path}
        permissionLoading={permissionLoading}
        isAllowed={
          permission?.administration?.portalUsers?.permission?.visible[role]
        }
        component={SystemUser}
      />
      {/* <RouteWithSidebar showSidebar={showSidebar} */}
      {/* <RouteWithSidebar showSidebar={showSidebar}
      exact
      path={Routes.AddSystemUser.path}
      component={AddSystemUser}
    /> */}
      {/* <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.UserApproval.path}
        component={UserApproval}
      /> */}
      <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.BusinessDetails.path}
        component={BusinessDetails}
      />
      <RouteWithSidebar
        showSidebar={showSidebar}
        // <RouteWithSidebar showSidebar={showSidebar}
        exact
        path={Routes.CounterMenu.path}
        component={Counter}
      />
      <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.StoreMenu.path}
        component={StoreMenu}
      />
      {/* <RouteWithSidebar showSidebar={showSidebar} exact path={Routes.BusinessDetailsEdit.path} component={BusinessDetailsEdit} /> */}
      <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.Invoices.path}
        component={Invoices}
      />
      <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.Customer.path}
        component={Customer}
      />
      <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.CustomerDetails.path}
        component={CustomerDetails}
      />

      <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.Support.path}
        isAllowed={permission?.support?.permission?.visible[role]}
        permissionLoading={permissionLoading}
        component={Support}
      />
      {/* <RouteWithSidebar showSidebar={showSidebar}
      exact
      path={Routes.NewTicket.path}
      component={NewTicket}
    /> */}
      <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.TicketReply.path}
        isAllowed={
          permission?.support?.platformSupport?.permission?.replyToTicket[role]
        }
        permissionLoading={permissionLoading}
        component={TicketReply}
      />
      <RouteWithSidebar
        showSidebar={showSidebar}
        exact
        path={Routes.Profile.path}
        isAllowed={permission?.profile?.permission?.visible[role]}
        permissionLoading={permissionLoading}
        component={Settings}
      />

      <Redirect to={Routes.NotFound.path} />
    </Switch>
  );
};
