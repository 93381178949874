import React from 'react';
import { styled } from '@material-ui/core/styles';
import { Paper, Tooltip } from '@mui/material';
import Iconify from '../Iconify';

const MyPaper = styled(Paper)({
  borderRadius: 8,
  backgroundColor: '#F0F0F0',
  padding: '1rem',
  border: `2px dashed #cbcbcb`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  width: '200px',
  height: '150px',
  // boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
});

const AddNewCard = ({ onClick }) => {
  return (
    <Tooltip title='Add New Document'>
      <MyPaper onClick={onClick} elevation={0}>
        {/* <Box sx={{ borderRadius: '50%', border: `4px dashed #0b0523` }}> */}
        <Iconify
          icon={'ic:round-add-circle'}
          width={'50%'}
          height={'50%'}
          sx={{
            color: '#cbcbcb',
          }}
        />
        {/* </Box> */}
      </MyPaper>
    </Tooltip>
  );
};

export default AddNewCard;
