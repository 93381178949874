import React from 'react';
import { Stack, Typography, TextField, MenuItem } from '@mui/material';

const SplitInputField = ({
  formik,
  titleLeft = '',
  nameLeft = 'amount',
  typeLeft = 'text',
  basisLeft = '50%',
  placeholderLeft = '',
  titleRight = '',
  nameRight = 'amount',
  typeRight = 'text',
  basisRight = '50%',
  placeholderRight = '',

  amountChangeHandler = () => {},
}) => {
  return (
    <Stack direction={'row'} spacing={0}>
      <Stack sx={{ flexBasis: basisLeft }} direction={'column'}>
        <Typography>{titleLeft}</Typography>
        <TextField
          fullWidth
          variant='outlined'
          name={nameLeft}
          id={nameLeft}
          className='split-left'
          type={typeLeft}
          placeholder={placeholderLeft}
          value={formik.values[`${nameLeft}`]}
          onChange={(e) => {
            amountChangeHandler(e.target.value);
            formik.handleChange(e);
          }}
          onBlur={formik.handleBlur}
          error={formik.touched[`${nameLeft}`] && Boolean(formik.errors[`${nameLeft}`])}
          helperText={formik.touched[`${nameLeft}`] && formik.errors[`${nameLeft}`]}
        />
      </Stack>
      <Stack sx={{ flexBasis: basisRight }} direction={'column'}>
        <Typography>{titleRight}</Typography>
        <TextField
          fullWidth
          variant='outlined'
          name={nameRight}
          id={nameRight}
          className='split-right'
          type={typeRight}
          placeholder={placeholderRight}
          value={formik.values[`${nameRight}`]}
          onChange={(e) => {
            amountChangeHandler(e.target.value);
            formik.handleChange(e);
          }}
          onBlur={formik.handleBlur}
          error={formik.touched[`${nameRight}`] && Boolean(formik.errors[`${nameRight}`])}
          helperText={formik.touched[`${nameRight}`] && formik.errors[`${nameRight}`]}
        />
      </Stack>
    </Stack>
  );
};

export default SplitInputField;
