import { format, startOfWeek, startOfMonth, startOfQuarter, startOfYear } from 'date-fns';
import { formatDate } from '../../utils/common';
export const flow = [
  {
    name: 'Any',
    value: 'any',
  },
  {
    name: 'Inward',
    value: 'inward',
  },
  {
    name: 'Outward',
    value: 'outward',
  },
];
export const downloadFormats = [
  {
    name: 'Excel',
    value: 'excel',
  },
];
export const timePeriodOptions = [
  {
    name: 'Daily',
    value: 'day',
  },
  {
    name: 'Weekly',
    value: 'week',
  },
  {
    name: 'Monthly',
    value: 'month',
  },
  {
    name: 'Yearly',
    value: 'year',
  },
];
export const reportTimePeriods = [
  {
    name: 'This Week',
    from: format(startOfWeek(new Date()), 'yyyy/MM/dd'),
    to: format(new Date(), 'yyyy/MM/dd'),
  },
  {
    name: 'This Month',
    from: format(startOfMonth(new Date()), 'yyyy/MM/dd'),
    to: format(new Date(), 'yyyy/MM/dd'),
  },
  {
    name: 'This Quarter',
    from: format(startOfQuarter(new Date()), 'yyyy/MM/dd'),
    to: format(new Date(), 'yyyy/MM/dd'),
  },
  {
    name: 'This Year',
    from: format(startOfYear(new Date()), 'yyyy/MM/dd'),
    to: format(new Date(), 'yyyy/MM/dd'),
  },
];
