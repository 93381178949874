import { faGoogle, faTwitter, faYahoo, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faGlobeEurope } from '@fortawesome/free-solid-svg-icons';

import USAFlag from '../assets/img/flags/united-states-of-america.svg';
// import CanadaFlag from '../assets/img/flags/canada.svg';
import GermanyFlag from '../assets/img/flags/germany.svg';
import FranceFlag from '../assets/img/flags/france.svg';
import JapanFlag from '../assets/img/flags/japan.svg';
import ItalyFlag from '../assets/img/flags/italy.svg';

// const pageVisits = [
//     { id: 1, views: 4325, returnValue: 3444890146553, bounceRate: 3464890566553, pageName: "34449867321" },

// ];

const pageTraffic = [
  {
    id: 1,
    source: 'Direct',
    sourceType: 'Direct',
    trafficShare: 51,
    change: 2.45,
    sourceIcon: faGlobeEurope,
    sourceIconColor: 'gray',
  },
  {
    id: 2,
    source: 'Google Search',
    sourceType: 'Search / Organic',
    trafficShare: 18,
    change: 17.67,
    sourceIcon: faGoogle,
    sourceIconColor: 'info',
  },
  {
    id: 3,
    source: 'youtube.com',
    sourceType: 'Social',
    category: 'Arts and Entertainment',
    rank: 2,
    trafficShare: 27,
    sourceIcon: faYoutube,
    sourceIconColor: 'danger',
  },
  {
    id: 4,
    source: 'yahoo.com',
    sourceType: 'Referral',
    category: 'News and Media',
    rank: 11,
    trafficShare: 8,
    change: -9.3,
    sourceIcon: faYahoo,
    sourceIconColor: 'purple',
  },
  {
    id: 5,
    source: 'twitter.com',
    sourceType: 'Social',
    category: 'Social Networks',
    rank: 4,
    trafficShare: 4,
    sourceIcon: faTwitter,
    sourceIconColor: 'info',
  },
];

const pageRanking = [
  {
    id: 1,
    country: 'United States',
    countryImage: USAFlag,
    overallRank: 76,
    overallRankChange: -5,
    travelRank: 3,
    widgetsRank: 32,
    widgetsRankChange: 3,
  },
  // { id: 2, country: "Canada", countryImage: CanadaFlag, overallRank: 106, overallRankChange: 17, travelRank: 4, widgetsRank: 30, widgetsRankChange: 3 },
  {
    id: 4,
    country: 'France',
    countryImage: FranceFlag,
    overallRank: 112,
    overallRankChange: 10,
    travelRank: 5,
    widgetsRank: 34,
    widgetsRankChange: 7,
  },
  {
    id: 5,
    country: 'Japan',
    countryImage: JapanFlag,
    overallRank: 115,
    overallRankChange: 3,
    travelRank: 7,
    travelRankChange: 1,
    widgetsRank: 39,
    widgetsRankChange: -2,
  },
  {
    id: 3,
    country: 'Germany',
    countryImage: GermanyFlag,
    overallRank: 147,
    overallRankChange: -12,
    travelRank: 10,
    travelRankChange: -1,
    widgetsRank: 12,
    widgetsRankChange: -5,
  },
  {
    id: 6,
    country: 'Italy',
    countryImage: ItalyFlag,
    overallRank: 220,
    overallRankChange: -56,
    travelRank: 11,
    travelRankChange: -3,
    widgetsRank: 89,
    widgetsRankChange: 2,
  },
];

const invoiceList = [
  {
    id: 1,
    invoiceno: '#349924',
    name: 'Nicole Austin',
    date: 'July 12th, 2021 03:58:41',
    amount: '$456',
    statusKey: 'success',
  },
  {
    id: 2,
    invoiceno: '#178891',
    name: 'Nicole Austin',
    date: 'Jul 02th, 2021 4:56:41',
    amount: '$20.45',
    statusKey: 'pending',
  },
  {
    id: 3,
    invoiceno: '#426022',
    name: 'Nicole Austin',
    date: 'Jul 03th, 2021 10:56:41',
    amount: '$36',
    statusKey: 'success',
  },
  {
    id: 4,
    invoiceno: '#349924',
    name: 'Nicole Austin',
    date: 'July 12th, 2021 03:58:41',
    amount: '$456',
    statusKey: 'success',
  },
  {
    id: 5,
    invoiceno: '#178891',
    name: 'Nicole Austin',
    date: 'Jul 02th, 2021 4:56:41',
    amount: '$20.45',
    statusKey: 'pending',
  },
  {
    id: 6,
    invoiceno: '#426022',
    name: 'Nicole Austin',
    date: 'Jul 03th, 2021 10:56:41',
    amount: '$36',
    statusKey: 'cancel',
  },
];

const userList = [
  {
    id: 1,
    idnumber: '34449867321',
    name: 'darkfox09',
    email: 'newuser@mail.com',
    userlevel: 'Merchant Manager',
    datecreated: '24 July 2021',
    statusKey: 'active',
  },
  {
    id: 2,
    idnumber: '34449867321',
    name: 'millar2021',
    email: 'newuser@mail.com',
    userlevel: 'Merchant Manager',
    datecreated: '24 July 2021',
    statusKey: 'pending',
  },
  {
    id: 3,
    idnumber: '34449867321',
    name: 'millar2021',
    email: 'newuser@mail.com',
    userlevel: 'Merchant Manager',
    datecreated: '24 July 2021',
    statusKey: 'cancel',
  },
  {
    id: 4,
    idnumber: '34449867321',
    name: 'millar2021',
    email: 'newuser@mail.com',
    userlevel: 'Merchant Manager',
    datecreated: '24 July 2021',
    statusKey: 'active',
  },
  {
    id: 5,
    idnumber: '34449867321',
    name: 'darknighgt85',
    email: 'newuser@mail.com',
    userlevel: 'Merchant Manager',
    datecreated: '24 July 2021',
    statusKey: 'active',
  },
];

const reportList = [
  {
    id: 1,
    transid: '34449867321',
    merchantid: '4325',
    fromva: '3444890146553',
    tova: '3464890566553',
    amount: '2.00',
    curre: 'GBP',
    type: 'BSWP',
    date: '12/12/2020',
    statusKey: 'active',
  },
  {
    id: 2,
    transid: '34449867321',
    merchantid: '4325',
    fromva: '3444890146553',
    tova: '3464890566553',
    amount: '4.00',
    curre: 'GBP',
    type: 'BSWP',
    date: '12/12/2020',
    statusKey: 'pending',
  },
];

const recieptList = [
  {
    id: 1,
    transid: '34449867321',
    merchantstore: 'store1',
    transstatus: 'Pending',
    customerid: 'CJ123',
    date: '12/12/2020 - 21/12/2020',
    statusKey: 'success',
  },
  {
    id: 2,
    transid: '34449867321',
    merchantstore: 'store2',
    transstatus: 'Success',
    customerid: 'CJ321',
    date: '12/12/2020 - 21/12/2020',
    statusKey: 'pending',
  },
];

const refundList = [
  {
    id: 1,
    store: 'Store 1',
    date: '12/12/2020',
    amount: '100',
    notes: 'Refund the amount',
    customerid: 'CJ123',
    currency: 'GBP',
  },
  {
    id: 2,
    store: 'Store 2',
    date: '12/12/2020',
    amount: '1000',
    notes: 'Refund the amount',
    customerid: 'CJ321',
    currency: 'BTC',
  },
];

const fundingList = [
  {
    id: 1,
    from: '3444890146553',
    to: '3464890566553',
    amount: '2.00',
    date: '07/10/2021',
    statusKey: 'success',
  },
  {
    id: 2,
    from: '3444890146553',
    to: '3464890566553',
    amount: '4.00',
    date: '07/10/2021',
    statusKey: 'fail',
  },
];

const transactionList = [
  {
    id: 1,
    balvalue: '2.00',
    currency: 'GBP',
    lastupdate: 'Jul 18th, 2021 10:56:41',
    statusKey: 'active',
  },
  {
    id: 2,
    balvalue: '4.00',
    currency: 'GBP',
    lastupdate: 'Jul 18th, 2021 10:56:41',
    statusKey: 'active',
  },
  {
    id: 3,
    balvalue: '4.00',
    currency: 'GBP',
    lastupdate: 'Jul 18th, 2021 10:56:41',
    statusKey: 'pending',
  },
  {
    id: 4,
    balvalue: '2.00',
    currency: 'EUR',
    lastupdate: 'Jul 18th, 2021 10:56:41',
    statusKey: 'cancel',
  },
  {
    id: 5,
    balvalue: '4.00',
    currency: 'GBP',
    lastupdate: 'Jul 18th, 2021 10:56:41',
    statusKey: 'active',
  },
  {
    id: 6,
    balvalue: '984.00',
    currency: 'GBP',
    lastupdate: 'Jul 18th, 2021 10:56:41',
    statusKey: 'cancel',
  },
  {
    id: 7,
    balvalue: '250.00',
    currency: 'GBP',
    lastupdate: 'Jul 18th, 2021 10:56:41',
    statusKey: 'active',
  },
];

const withdrawlList = [
  {
    id: 1,
    numid: '43',
    date: 'Jul 17th, 2021 10:58:41',
    amount: '0.12340000 BTC',
    valueincad: '5,004 CAD',
    status: 'Submitted to the Network',
    address: 'Zja7BQo782QanKv4so',
    transid: 'JHAEIF6374NXY3484748949',
  },
  {
    id: 2,
    numid: '48',
    date: 'Jul 17th, 2021 10:58:41',
    amount: '0.12340000 BTC',
    valueincad: '5,004 CAD',
    status: 'Submitted to the Network',
    address: 'Zja7BQo782QanKv4so',
    transid: 'JHAEIF6374NXY3484748949',
  },
  {
    id: 3,
    numid: '52',
    date: 'Jul 17th, 2021 10:58:41',
    amount: '0.12340000 BTC',
    valueincad: '5,004 CAD',
    status: 'Submitted to the Network',
    address: 'Zja7BQo782QanKv4so',
    transid: 'JHAEIF6374NXY3484748949',
  },
];

const customersList = [
  {
    id: 1,
    custid: 'CJ-39002',
    name: 'Nicole Austin',
    email: 'nichola.aus@email.com',
    phone: '718-519-7291',
  },
  {
    id: 1,
    custid: 'LA-53992',
    name: 'Josephine J Reynolds',
    email: 'nichola.aus@email.com',
    phone: '913-993-9050',
  },
  {
    id: 1,
    custid: 'NY-87992',
    name: 'Irene S Wade',
    email: 'nichola.aus@email.com',
    phone: '316-214-9154',
  },
];

const demoData = [
  {
    label: 'Eugenia',
    value: 'Eugenia',
    role: 'Master',
    price: '200',
  },
  {
    label: 'Kariane',
    value: 'Kariane',
    role: 'Master',
    price: '300',
  },
  {
    label: 'Louisa',
    value: 'Louisa',
    role: 'Master',
    price: '0.00',
  },
  {
    label: 'Marty',
    value: 'Marty',
    role: 'Master',
    price: '0.00',
  },
  {
    label: 'Kenya',
    value: 'Kenya',
    role: 'Master',
    price: '0.00',
  },
  {
    label: 'Hal',
    value: 'Hal',
    role: 'Developer',
    price: '0.00',
  },
  {
    label: 'Julius',
    value: 'Julius',
    role: 'Developer',
    price: '0.00',
  },
  {
    label: 'Travon',
    value: 'Travon',
    role: 'Developer',
    price: '0.00',
  },
  {
    label: 'Vincenza',
    value: 'Vincenza',
    role: 'Developer',
    price: '0.00',
  },
  {
    label: 'Dominic',
    value: 'Dominic',
    role: 'Developer',
    price: '0.00',
  },
  {
    label: 'Pearlie',
    value: 'Pearlie',
    role: 'Guest',
    price: '0.00',
  },
  {
    label: 'Tyrel',
    value: 'Tyrel',
    role: 'Guest',
    price: '0.00',
  },
  {
    label: 'Jaylen',
    value: 'Jaylen',
    role: 'Guest',
    price: '0.00',
  },
  {
    label: 'Rogelio',
    value: 'Rogelio',
    role: 'Guest',
    price: '0.00',
  },
];

const supportTable = [
  {
    id: 1,
    subject: 'Bitcoin Exchange',
    subname: 'WLF79ANY',
    lastreply: 'Accounts Team(15d)',
    status: 'archived',
  },
];

export {
  pageTraffic,
  pageRanking,
  invoiceList,
  userList,
  reportList,
  recieptList,
  refundList,
  fundingList,
  transactionList,
  withdrawlList,
  customersList,
  demoData,
  supportTable,
};
