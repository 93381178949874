import React from 'react';
import { startCase, toLower } from 'lodash';
import { Box, Stack, Typography, LinearProgress, Avatar, Tooltip, IconButton } from '@mui/material';

import { DataGrid } from '@mui/x-data-grid';
import { formatCurrency, formatWithoutTimezone, rowsPerPageOptions, tableCellStyle, transactionStatusColors } from '../../../../../utils/common';
import createAvatar from '../../../../../utils/createAvatar';
import { Label, ButtonGhost } from '../../../../../components';
import LinkRoundedIcon from '@mui/icons-material/LinkRounded';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_REQUEST_RAISED } from '../../../../../redux/actionTypes';

export default function RedemptionTable({ translist, fetchTranslistLoading, viewDetails, updateStatus }) {
  const role = useSelector((state) => state.profileReducer?.user?.role?.key);
  const permission = useSelector((state) => state.profileReducer?.permission);

  const filters = useSelector((state) => state.filters.settlements.requestRaised);
  const dispatch = useDispatch();
  const handleChangePage = (newPage) => {
    dispatch({
      type: UPDATE_REQUEST_RAISED,
      payload: { currentPage: newPage },
    });
  };

  const handleChangeRowsPerPage = (newSize) => {
    dispatch({
      type: UPDATE_REQUEST_RAISED,
      payload: { currentPage: 0, pageSize: newSize },
    });
  };

  const createDataRow = (transaction) => {
    return {
      id: transaction?.id,
      // type: capital(transaction?.type) || 'P2P',
      submissionDate: formatWithoutTimezone(transaction?.created_date, 'dd MMM yyyy HH:mm a'),
      sender: startCase(toLower(transaction?.requester_mto?.name)),
      receivedValue: (() => {
        return formatCurrency(transaction?.amount, transaction.coin?.decimal_place, transaction.coin?.symbol);
      })(),
      // currency: transaction.currency.code,
      status: transaction?.current_status,
      updateStatus: transaction,
      details: transaction,
    };
  };

  const rows = translist?.results?.length > 0 ? translist.results.map((transaction) => createDataRow(transaction)) : [];

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
      minWidth: 80,
      // maxWidth: 200,
      headerClassName: 'header',
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <Tooltip placement='bottom' sx={{ cursor: 'default' }} title={params.formattedValue}>
            <Typography variant='body2' style={tableCellStyle}>
              {params.formattedValue}
            </Typography>
          </Tooltip>
        );
      },
    },

    {
      field: 'submissionDate',
      headerName: 'Date',
      flex: 1,
      minWidth: 200,
      // maxWidth: 200,
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Typography variant='body2' style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
    {
      field: 'sender',
      headerName: 'Sender MTO',
      flex: 1.5,
      minWidth: 200,
      // maxWidth: 200,
      textOverflow: 'ellipsis',
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Stack direction={'row'} sx={{ minWidth: 0, alignItems: 'center' }}>
            <Avatar alt={params.formattedValue} sx={{ mr: 1, bgcolor: createAvatar(params.formattedValue).color }}>
              {createAvatar(params.formattedValue).name}
            </Avatar>
            <Typography
              variant='body2'
              sx={{
                color: 'black',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontWeight: '500',
              }}
            >
              {params.formattedValue}
            </Typography>
          </Stack>
        );
      },
    },

    {
      field: 'receivedValue',
      headerName: 'Settlement Value',
      flex: 1.5,
      minWidth: 150,
      // maxWidth: 200,
      headerClassName: 'header',
      sortable: false,
      renderCell: (params) => {
        return (
          <Typography variant='body2' style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },

    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      flex: 1.5,
      minWidth: 250,
      headerClassName: 'header',
      renderCell: (params) => {
        let { value = '', receiver_name = '' } = params?.formattedValue || {};
        switch (value) {
          case 'pending':
            return (
              <Label variant={'ghost'} color={transactionStatusColors[value]} sx={{ textTransform: 'capitalize' }}>
                {receiver_name}
              </Label>
            );
          case 'processed':
            return (
              <Label variant={'ghost'} color={transactionStatusColors[value]} sx={{ textTransform: 'capitalize' }}>
                {receiver_name}
              </Label>
            );

          case 'redeem_failed':
            return (
              <Label variant={'ghost'} color={transactionStatusColors[value]} sx={{ textTransform: 'capitalize' }}>
                {receiver_name}
              </Label>
            );

          case 'redeem_complete':
            return (
              <Label variant={'ghost'} color={transactionStatusColors[value]} sx={{ textTransform: 'capitalize' }}>
                {receiver_name}
              </Label>
            );

          default:
            return (
              <Label variant={'ghost'} color={'application submitted'} sx={{ textTransform: 'capitalize' }}>
                {receiver_name || '--'}
              </Label>
            );
        }
      },
    },

    {
      field: 'updateStatus',
      headerName: 'Update Status',
      sortable: false,
      flex: 1.5,
      headerClassName: 'header',
      minWidth: 160,
      renderCell: (params) => {
        return (
          <ButtonGhost
            appearance='ghost'
            size='xs'
            disabled={
              params?.row?.details?.current_status?.receiver_can_change_to?.length === 0 ||
              params?.row?.details?.current_status === null ||
              !permission?.redemptions?.requestsRaised?.permission?.change[role]
            }
            onClick={(e) => {
              e.stopPropagation();
              updateStatus(params.formattedValue);
            }}
          >
            Update Status
          </ButtonGhost>
        );
      },
    },

    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      flex: 1,
      minWidth: 140,
      // maxWidth: 140,
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Stack direction='row' spacing={2}>
            {/* <Stack>
              <Tooltip placement="bottom" title="View Details">
                <span>
                  <IconButton
                    onClick={() => {
                      viewDetails(params.row.details);
                    }}
                  >
                    <InfoRoundedIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Stack> */}
            <Stack>
              <Tooltip placement='bottom' title='View in Blockchain'>
                <span onClick={(e) => e.stopPropagation()}>
                  <IconButton
                    disabled={!params?.row?.details?.transaction?.blockchain_url}
                    href={params?.row?.details?.transaction?.blockchain_url}
                    target={'_blank'}
                  >
                    <LinkRoundedIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Stack>
          </Stack>
        );
      },
    },
  ];

  return (
    <>
      <Box
        sx={{
          // height: 825,
          boxShadow: 'rgba(99, 99, 99, 0.2) 0px 0px 8px 0px',
          borderRadius: '8px',
          width: '100%',
          '& .MuiDataGrid-virtualScrollerRenderZone': {
            '& .MuiDataGrid-row': {
              // backgroundColor: 'rgba(235, 235, 235, .7)',
              fontWeight: 500,
              color: '#667085',
              fontFamily: 'Inter, sans-serif',
              fontSize: '14px',
            },
          },
          '& .header': {
            backgroundColor: '#F4F4F4',
            '& div': {
              '& div': {
                '& div': {
                  '& div': {
                    fontSize: '14px',
                    fontWeight: 600,
                    fontFamily: 'Inter, sans-serif',
                  },
                },
              },
            },
          },
        }}
      >
        <DataGrid
          autoHeight
          sx={{
            borderRadius: '8px',
            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 0px 8px 0px',
            width: '100%',
            cursor: 'pointer',
          }}
          components={{
            LoadingOverlay: LinearProgress,
          }}
          onRowClick={(row) => {
            console.log(row);
            viewDetails(row.row.details.id);
          }}
          // density={'compact'}
          rows={rows}
          columns={columns}
          rowHeight={50}
          disableColumnFilter={true}
          disableColumnMenu={true}
          pageSize={filters.pageSize}
          pagination
          paginationMode='server'
          rowsPerPageOptions={rowsPerPageOptions}
          rowCount={translist.count}
          onPageChange={(newPage) => handleChangePage(newPage)}
          onPageSizeChange={(newSize) => handleChangeRowsPerPage(newSize)}
          loading={fetchTranslistLoading}
        />
      </Box>
    </>
  );
}
