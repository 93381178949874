import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const PreviewItem = ({ title, content }) => {
  const styles = StyleSheet.create({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginRight: '20px',
      marginBottom: '20px',
    },
    titleContainer: {
      display: 'flex',
      marginBottom: '4px',
    },
    title: {
      color: '#4a4a4a',
      fontSize: '10px',
    },
    contentContainer: {
      display: 'flex',
      maxWidth: '100%',
    },
    content: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: '12px',
      wordBreak: 'initial',
      '@media max-width:400': {
        wordBreak: 'break-all',
      },
    },
  });
  return (
    <View style={styles.container}>
      <View style={styles.titleContainer}>
        <Text style={styles.title}>{title}</Text>
      </View>
      <View style={styles.contentContainer}>
        <Text style={styles.content}>{content ? content : '--'}</Text>
      </View>
    </View>
  );
};

export default PreviewItem;
