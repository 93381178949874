import React from "react";
import { useHistory } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { LinearProgress, Box, Paper } from "@mui/material";
import { capitalize } from "lodash";
import { Label } from "../../../components";
import {
  formatDate,
  rowsPerPageOptions,
  transactionStatusColors,
} from "../../../utils/common";
import { useSelector } from "react-redux";

const TicketList = ({ tickets, setPageChange, paginationdetails, loading }) => {
  const history = useHistory();
  const role = useSelector((state) => state.profileReducer?.user?.role?.key);
  const permission = useSelector((state) => state.profileReducer?.permission);

  const handlepagechange = (newPage) => {
    console.log(newPage);
    setPageChange({
      ...paginationdetails,
      currentPage: newPage,
    });
  };

  const handleChangeRowsPerPage = (newSize) => {
    setPageChange({
      ...paginationdetails,
      currentPage: 0,
      pageSize: newSize,
    });
  };

  const createDataRow = (ticket) => ({
    id: ticket.id,
    priority: ticket.priority.name,
    subject: ticket.subject,
    category: capitalize(ticket.category.name),
    status: ticket.status,
    created_date: formatDate(ticket?.created_date, "yyyy-MM-dd"),
    created_user: `${ticket?.user?.first_name} ${ticket?.user?.last_name}`,
    // action: 'View Details',
  });

  const rows =
    tickets?.results?.length > 0
      ? tickets.results.map((ticket) => createDataRow(ticket))
      : [];

  const columns = [
    {
      field: "created_date",
      headerName: "Opened Date",
      flex: 1,
      minWidth: 120,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <p
            style={{
              color: "black",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {params.formattedValue}
          </p>
        );
      },
    },
    {
      field: "created_user",
      headerName: "Opened By",
      flex: 2,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <p
            style={{
              color: "black",
              // overflow: "hidden",
              // textOverflow: "ellipsis",
            }}
          >
            {params.formattedValue}
          </p>
        );
      },
    },
    {
      field: "subject",
      headerName: "Subject",
      flex: 4,
      minWidth: 120,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <p
            style={{
              color: "black",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {params.formattedValue}
          </p>
        );
      },
    },
    {
      field: "priority",
      headerName: "Priority",
      flex: 1,
      minWidth: 120,
      headerClassName: "header",
      renderCell: (params) => {
        switch (params.formattedValue) {
          case "P1":
            return (
              <Label
                variant={"ghost"}
                color={transactionStatusColors[params.formattedValue]}
                sx={{ textTransform: "capitalize" }}
              >
                {params.formattedValue}
              </Label>
            );
          case "P2":
            return (
              <Label
                variant={"ghost"}
                color={transactionStatusColors[params.formattedValue]}
                sx={{ textTransform: "capitalize" }}
              >
                {params.formattedValue}
              </Label>
            );

          case "P3":
            return (
              <Label
                variant={"ghost"}
                color={transactionStatusColors[params.formattedValue]}
                sx={{ textTransform: "capitalize" }}
              >
                {params.formattedValue}
              </Label>
            );
          default:
            return (
              <Label
                variant={"ghost"}
                color={"application submitted"}
                sx={{ textTransform: "capitalize" }}
              >
                {params.formattedValue || "--"}
              </Label>
            );
        }
      },
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1,
      minWidth: 120,
      headerClassName: "header",
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      flex: 1,
      minWidth: 150,
      headerClassName: "header",
      renderCell: (params) => {
        console.log(params.formattedValue);
        return params.formattedValue === "open" ? (
          <Label
            variant={"ghost"}
            color={transactionStatusColors[params.formattedValue]}
            sx={{ textTransform: "capitalize" }}
          >
            Open
          </Label>
        ) : (
          <Label
            variant={"ghost"}
            color={transactionStatusColors[params.formattedValue]}
            sx={{ textTransform: "capitalize" }}
          >
            Closed
          </Label>
        );
      },
    },
    // {
    //   field: 'action',
    //   headerName: 'Action',
    //   sortable: false,
    //   minWidth: 80,
    //   // maxWidth: 150,
    //   headerClassName: 'header',
    //   renderCell: (params) => {
    //     const onActivateClicked = (e) => {
    //       e.stopPropagation(); // don't select this row after clicking
    //       console.log(params);
    //       history.push('/support/ticketreply/' + params.id);
    //     };

    //     return (
    //       <Stack direction="row" spacing={2}>
    //         <Stack>
    //           <Tooltip title="Edit" arrow>
    //             <IconButton onClick={onActivateClicked}>
    //               <EditIcon />
    //             </IconButton>
    //           </Tooltip>
    //         </Stack>
    //       </Stack>
    //     );
    //   },
    // },
  ];

  return (
    <>
      <Paper className="mb-6" sx={{ width: "100%" }}>
        <Box
          sx={{
            height: 615,
            width: 1,
            "& .MuiDataGrid-virtualScrollerRenderZone": {
              "& .MuiDataGrid-row": {
                // backgroundColor: 'rgba(235, 235, 235, .7)',
                fontWeight: 500,
                color: "#667085",
                fontFamily: "Inter, sans-serif",
                fontSize: "14px",
              },
            },
            "& .header": {
              backgroundColor: "#F4F4F4",
              "& div": {
                "& div": {
                  "& div": {
                    "& div": {
                      fontSize: "14px",
                      fontWeight: 600,
                      fontFamily: "Inter, sans-serif",
                    },
                  },
                },
              },
            },
          }}
        >
          <DataGrid
            autoHeight
            sx={{
              borderRadius: "8px",
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 0px 8px 0px",
              cursor: "pointer",
            }}
            components={{
              LoadingOverlay: LinearProgress,
            }}
            rows={rows}
            onRowClick={(row) =>
              permission?.support?.platformSupport?.permission?.replyToTicket[
                role
              ]
                ? history.push("/support/ticketreply/" + row.id)
                : ""
            }
            columns={columns}
            loading={loading}
            rowHeight={50}
            disableColumnFilter={true}
            disableColumnMenu={true}
            pagination
            paginationMode="server"
            rowsPerPageOptions={rowsPerPageOptions}
            page={paginationdetails.currentPage}
            rowCount={tickets.count}
            pageSize={paginationdetails.pageSize}
            onPageChange={(newPage) => handlepagechange(newPage)}
            onPageSizeChange={(newSize) => handleChangeRowsPerPage(newSize)}
          />
        </Box>
      </Paper>
    </>
  );
};

export default TicketList;
