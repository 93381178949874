import React, { useState, useEffect, useCallback } from "react";
import { merchantApi } from "../../../redux/api/apiConfig";
import { BusinessTable, AddBusinessSenderClient } from "./components";
import "rsuite/dist/styles/rsuite-default.css";
import { Button } from "@themesberg/react-bootstrap";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as API from "../../../ApiUrl";
import searchIcon from "../../../assets/img/icons/searchIcon.svg";
import { Form, InputGroup } from "@themesberg/react-bootstrap";
import usePageTitle from "../../../hooks/usePageTitle";
import { Box, Stack } from "@mui/material";
import { ButtonPrimary } from "../../../components";

const BusinessClients = () => {
  const [users, setUsers] = useState({ count: 0, results: [] });
  const [fetchUsersLoading, setFetchUsersLoading] = useState(true);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [clientToEdit, setClientToEdit] = useState({});
  const [paginateTable, setPaginateTable] = useState({
    currentPage: 0,
    pageSize: 7,
    search: "",
  });

  const role = useSelector((state) => state.profileReducer?.user?.role?.key);
  const permission = useSelector((state) => state.profileReducer?.permission);
  usePageTitle("Business Senders", "View your business sender clients");

  const toggleAddUserModal = () => {
    if (showAddUserModal) {
      getUsers();
      setIsEdit(false);
      setClientToEdit({});
    }
    setShowAddUserModal((prevState) => !prevState);
  };
  const deleteUser = async (id) => {
    setFetchUsersLoading(true);
    try {
      const response = await merchantApi.delete(`${API.ADD_SENDER_BUS}${id}/`);
      console.log(response);

      getUsers();
      setFetchUsersLoading(false);
    } catch (error) {
      toast.error(
        error?.data?.data?.message || "Could not delete user, try again later."
      );
      setFetchUsersLoading(false);
    }
  };

  const getUsers = useCallback(async () => {
    setFetchUsersLoading(true);
    try {
      const response = await merchantApi.get(`${API.ADD_SENDER_BUS}`, {
        params: {
          offset: paginateTable.currentPage * paginateTable.pageSize,
          limit: paginateTable.pageSize,
          search: paginateTable.search,
        },
      });
      console.log(response);
      setUsers(response?.data);
      setFetchUsersLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error?.data?.data?.message || "Something went wrong");
      setFetchUsersLoading(false);
    }
  }, [paginateTable]);

  useEffect(() => {
    const timer = setTimeout(() => {
      getUsers();
    }, 500);

    return () => clearTimeout(timer);
  }, [
    paginateTable.currentPage,
    paginateTable.pageSize,
    paginateTable.search,
    getUsers,
  ]);

  const handleSearch = (value) => {
    setPaginateTable({
      ...paginateTable,
      currentPage: 0,
      search: value,
    });
  };

  console.log("sender business", users);

  return (
    <Box sx={{ padding: "0 24px 24px" }}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        width={"100%"}
        mb={2}
      >
        <Stack sx={{ maxWidth: "25%", flex: 1 }}>
          <Form onSubmit={(e) => e.preventDefault()} className="w-100 search">
            <Form.Group>
              <InputGroup>
                <InputGroup.Text>
                  <img src={searchIcon} alt="search" />
                </InputGroup.Text>
                <Form.Control
                  onChange={(e) => {
                    handleSearch(e.target.value);
                  }}
                  type="text"
                  placeholder="Search"
                />
              </InputGroup>
            </Form.Group>
          </Form>
        </Stack>
        {/* {permission?.clients?.senders?.business?.permission?.add[role] && (
          <ButtonPrimary onClick={toggleAddUserModal}>New Sender</ButtonPrimary>
        )} */}
      </Stack>
      <BusinessTable
        fetchUsersLoading={fetchUsersLoading}
        users={users}
        paginateTable={paginateTable}
        setPaginateTable={setPaginateTable}
        setClientToEdit={setClientToEdit}
        setIsEdit={setIsEdit}
        deleteUser={deleteUser}
        toggleAddUserModal={toggleAddUserModal}
      />
      {showAddUserModal && (
        <AddBusinessSenderClient
          onClose={toggleAddUserModal}
          isOpen={showAddUserModal}
          isEdit={isEdit}
          clientToEdit={clientToEdit}
          // This is to trigger re-render after adding user to fetch new list
          onUserAdd={() => {
            getUsers();
          }}
        />
      )}
    </Box>
  );
};

export default BusinessClients;
