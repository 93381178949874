import { merchantApi } from "../redux/api/apiConfig";

//Set or remove refresh and access token
export const setSession = (accessToken, refreshToken) => {
    // console.log('Access token', accessToken);
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);
      // authApi.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      delete merchantApi.defaults.headers.common.Authorization;
    }
  };