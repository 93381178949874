import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import { merchantApi } from '../../../../../redux/api/apiConfig';
import { ADD_RECEIVER_BUS, ADD_FINANCIAL_DATA } from '../../../../../ApiUrl';
import { Dialog, DialogTitle } from '@mui/material';
import { Stepper, Step, StepLabel } from '@material-ui/core';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BusinessInfo from './BusinessInfo';
import FinancialDetails from '../../../../../components/FinancialDetails';
// import { useSelector } from 'react-redux';

const AddBusinessClient = ({
  onClose,
  isOpen,
  isEdit,
  clientToEdit,
  onUserAdd,
  onAddFinAc,
  addFinancialOnly = false,
  fromRemittance = false,
  sender = null,
}) => {
  const [isDraft, setIsDraft] = useState(true);
  const [userId, setUserId] = useState(null);
  const [provinces, setProvinces] = useState([]);
  const [financialDetails, setFinancialDetails] = useState({});
  const [businessInfo, setBusinessInfo] = useState({
    business_name: '',
    primary_contact_name: '',
    primary_designation: '',
    primary_contact_no: '',
    phone_number2: '',
    address: '',
    city: '',
    country: '',
    province: '',
    sender: sender || null,
  });

  const [activeStep, setActiveStep] = useState(addFinancialOnly ? 1 : 0);
  const [financialLoading, setFinancialLoading] = useState(false);
  const [businessInfoLoading, setBusinessInfoLoading] = useState(false);

  // const id = useSelector(
  //   (state) => state?.profileReducer?.user?.mto?.country?.id
  // );

  useEffect(() => {
    if (isEdit) {
      const {
        business_name = '',
        primary_contact_name = '',
        primary_designation = '',
        primary_contact_no = '',
        secondary_contact_no = '',

        address = '',
        city = {},
        country = {},
        province = {},
        finance_account,
        id,
        sender,
      } = clientToEdit;

      setBusinessInfo({
        business_name,
        primary_contact_name,
        primary_designation,
        primary_contact_no,
        secondary_contact_no,

        address,
        city: city?.id,
        country: country?.id,
        province: province?.id,
        sender,
      });
      setUserId(id);
      setFinancialDetails(finance_account[0]);
    }

    // setBusinessInfo((prev) => ({ ...prev, country: id }));

    return () => {
      setUserId(null);
      setBusinessInfo({});
      setFinancialDetails({});
    };
  }, []);

  const changeDraftState = () => {
    setIsDraft((prevState) => !prevState);
  };
  const getcompleted = (r) => {
    return r < activeStep;
  };
  const addSender = async (senderInfo) => {
    setBusinessInfoLoading(true);
    if (!isEdit) {
      try {
        // let id = userId;
        if (!userId) {
          const response = await merchantApi.post(ADD_RECEIVER_BUS, {
            ...senderInfo,
            sender: senderInfo?.sender?.id,
          });
          let id = response.data.data.id;
          setUserId(id);
          toast.success('User info added successfully');
        } else {
          const response = await merchantApi.patch(`${ADD_RECEIVER_BUS}${userId}/`, {
            ...senderInfo,
            sender: senderInfo?.sender?.id,
          });
        }
        setActiveStep(activeStep + 1);
        setBusinessInfo(senderInfo);
      } catch (err) {
        console.log(err);
        setBusinessInfoLoading(false);
        toast.error(err?.data?.data?.message || 'Something went wrong');
      }
    } else {
      try {
        let id = clientToEdit.id;
        const response = await merchantApi.patch(`${ADD_RECEIVER_BUS}${id}/`, {
          ...senderInfo,
          status: clientToEdit.status,
          sender: senderInfo?.sender?.id,
        });
        setActiveStep(activeStep + 1);
        setBusinessInfo(senderInfo);
      } catch (err) {
        console.log(err);
        setBusinessInfoLoading(false);
        toast.error(err?.data?.data?.message || 'Something went wrong');
      }
    }
    setBusinessInfoLoading(false);
  };
  const addFinancialData = async (data) => {
    const { status, type } = data;
    console.log(type);
    setFinancialLoading(true);

    if (!isEdit) {
      try {
        if (type) {
          const patchResponse = await merchantApi.post(`${ADD_FINANCIAL_DATA}?receiver=${userId}`, data);
        }
        const response = await merchantApi.patch(`${ADD_RECEIVER_BUS}${userId}/`, {
          status,
        });
        toast.success('User added successfully.');
        onUserAdd();
        onClose();
      } catch (err) {
        console.log(err);
        setFinancialLoading(false);
        toast.error(err?.data?.data?.message || 'Something went wrong');
      }
    }
    if (isEdit && !addFinancialOnly) {
      try {
        console.log(clientToEdit);
        if (!clientToEdit?.finance_account[0]?.id) {
          console.log('no finance account');
          if (type) {
            const patchResponse = await merchantApi.post(`${ADD_FINANCIAL_DATA}?receiver=${userId}`, data);
          }
        } else {
          let id = clientToEdit.finance_account[0].id;
          const patchResponse = await merchantApi.patch(`${ADD_FINANCIAL_DATA}${id}/`, data);
        }
        const response = await merchantApi.patch(`${ADD_RECEIVER_BUS}${userId}/`, {
          status,
        });
        toast.success('User update successful');

        onClose();
      } catch (err) {
        console.log(err);
        setFinancialLoading(false);
        toast.error(err?.data?.data?.message || 'Something went wrong');
      }
    }
    if (isEdit && addFinancialOnly) {
      try {
        if (type) {
          const patchResponse = await merchantApi.post(`${ADD_FINANCIAL_DATA}?receiver=${userId}`, data);
        }
        const response = await merchantApi.patch(`${ADD_RECEIVER_BUS}${userId}/`, {
          status,
        });
        toast.success('User added successfully.');
        onAddFinAc();
        // onClose();
      } catch (err) {
        console.log(err);

        setFinancialLoading(false);
        toast.error(err?.data?.data?.message || 'Something went wrong');
      }
    }

    setFinancialLoading(false);
  };

  const handleNext = (data) => {
    if (activeStep === 0) {
      addSender(data);
    }
    if (activeStep === 1) {
      addFinancialData(data);
    }
  };

  const handleBack = () => {
    // seteditable(true);
    setActiveStep(activeStep - 1);
  };

  const getContent = (step) => {
    if (step === 0) {
      return (
        <BusinessInfo
          onClose={onClose}
          handleNext={handleNext}
          businessInfo={businessInfo}
          isEdit={isEdit}
          loading={businessInfoLoading}
          fromRemittance={fromRemittance}
        />
      );
    } else if (step === 1) {
      return (
        <FinancialDetails
          onClose={onClose}
          loading={financialLoading}
          handleNext={handleNext}
          handleBack={handleBack}
          isDraft={isDraft}
          isEdit={isEdit}
          changeDraftState={changeDraftState}
          financialDetails={financialDetails}
          setFinancialDetails={setFinancialDetails}
        />
      );
    }
  };

  const handleCloseModal = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth={'md'}
      onClose={handleCloseModal}
      scroll={'paper'}
      BackdropProps={{ style: { backgroundColor: 'rgba(11, 5, 35, 0.8)' } }}
      PaperProps={{
        style: {
          boxShadow: 'none',
          borderRadius: '8px',
          // width: '628px',
          // minHeight: '550px',
          // maxHeight: '90vh',
        },
      }}
    >
      <DialogTitle>
        <Stepper activeStep={activeStep}>
          <Step completed={getcompleted(0)}>
            <StepLabel>
              <span className='d-none d-sm-block'>Business Info</span>
            </StepLabel>
          </Step>

          <Step completed={getcompleted(1)}>
            <StepLabel>
              <span className='d-none d-sm-block'>Financial Details(Optional)</span>
            </StepLabel>
          </Step>
        </Stepper>
      </DialogTitle>
      {getContent(activeStep)}
    </Dialog>
  );
};

export default AddBusinessClient;
