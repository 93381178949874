export const Routes = {
  // pages
  Signin: { path: "/signin" },
  DashboardOverview: { path: "/" },
  DevDashboard: { path: "/dev" },
  // DashboardOverview: { path: "/dashboard/dashboardoverview" },
  Profile: { path: "/profile" },
  Statement: { path: "/merchantprofile/statement" },
  // Reports
  Reports: { path: "/reports" },
  Transaction: { path: "/reports/transaction" },
  MTOLedgerDetails: { path: "/reports/mto-ledger-details" },
  WalletMovement: { path: "/reports/wallet-movement" },
  MTOLedgerSummary: { path: "/reports/mto-ledger-summary" },
  DepositReport: { path: "/reports/deposit-report" },
  OutwardRemittanceReport: { path: "/reports/remittance-report/outward" },
  InwardRemittanceReport: { path: "/reports/remittance-report/inward" },
  RedemptionReport: { path: "/reports/redemption-report" },
  AvailableRedemptionReport: { path: "/reports/pending-redemption-report" },

  // Clients
  SenderIndividualClients: { path: "/clients/senders/individual" },
  SenderBusinessClients: { path: "/clients/senders/business" },
  ReceiverIndividualClients: { path: "/clients/receivers/individual" },
  ReceiverBusinessClients: { path: "/clients/receivers/business" },
  Deposits: { path: "/clients/senders/deposits" },
  DepositsHistory: { path: "/clients/senders/deposits/:id" },
  ClientsSenderDetails: { path: "/clients/senders/details" },

  // KYC
  UserKyc: { path: "/clients/senders/kyc" },
  UserChatGeneral: { path: "/support/customer/" },
  UserChat: { path: "/support/customer/ticket/:conversationKey" },

  // Remittance
  EditDraftRemittance: { path: "/remittance/outward/add/:id" },
  OutwardRemittanceBase: { path: '/remittance/outward' },
  OutwardRemittance: { path: '/remittance/outward' },
  ClientRemittance: { path: '/remittance/outward/pending' },
  OutwardRemittanceDetails: {
    path: '/remittance/outward/:id',
  },
  AddOutwardRemittance: { path: '/remittance/outward/add' },
  InwardRemittance: { path: '/remittance/inward' },
  InwardRemittanceDetails: { path: '/remittance/inward/:id' },

  // Redeem
  RedemptionsRaised: { path: "/redemptions/raised" },
  CreateRedeemRequest: { path: "/redemptions/create" },
  RedemptionsReceived: { path: "/redemptions/received" },
  RedemptionRaisedDetails: { path: "/redemptions/raised/details/:id" },
  RedemptionReceivedDetails: { path: "/redemptions/received/details/:id" },
  AvailableRedemptions: { path: "/redemptions/available" },

  // Configurations
  InwardRemittanceFees: { path: "/configurations/fees/inward" },
  OutwardRemittanceFees: { path: "/configurations/fees/outward" },
  ExchangeOffers: { path: "/configurations/exchange-offers" },
  ExchangeOfferHistory: {
    path: "/configurations/exchange-offers/:id",
  },
  MtoFinancialAccounts: { path: "/configurations/mto-financial-accounts" },
  KycLevels: { path: "/configurations/kyc-levels" },

  // Account
  Accounts: { path: "/accounts/accounts" },
  Liquidity: { path: "/accounts/liquidity" },
  LiquidityHistory: { path: "/accounts/liquidity/:id" },
  AccountBalance: { path: "/accounts/balance/:id" },
  Currencies: { path: "/accounts/currencies" },

  // Transactions
  Transactions: { path: "/transactions" },
  Balance: { path: "/transactions/balance" },
  ReportTransactions: { path: "/transactions/reporttransactions" },

  History: { path: "/transaction/history" },
  SystemUser: { path: "/administration/systemUser" },
  StoreMenu: { path: "/administration/store" },
  CounterMenu: { path: "/administration/counter/:id" },
  UserApproval: { path: "/administration/userApproval/:id" },
  AddSystemUser: { path: "/administration/addSystemUser" },
  BusinessDetails: { path: "/administration/updatebusinessdetails" },
  BusinessDetailsEdit: { path: "/kycdone" },
  Invoices: { path: "/invoices" },
  Support: { path: "/support/platform" },
  Customer: { path: "/customer" },
  CustomerDetails: { path: "/customerdetails" },
  NewTicket: { path: "/support/newticket" },
  TicketReply: { path: "/support/ticketreply/:id" },
  Upgrade: { path: "/upgrade" },
  BootstrapTables: { path: "/tables/bootstrap-tables" },
  // Billing: { path: "/examples/billing" },
  // Invoice: { path: "/examples/invoice" },
  Signup: { path: "/stb/sign-up" },
  Signupthanks: { path: "/signupthanks" },
  ForgotPassword: { path: "/stb/forgot-password" },
  ChangePassword: { path: "/stb/change-password" },
  ResetPassword: { path: "/stb/reset-password" },
  Thankyou: { path: "/stb/thankyou" },
  Lock: { path: "/stb/lock" },
  NotFound: { path: "/stb/404" },
  ServerError: { path: "/stb/500" },

  // components
  Accordions: { path: "/components/accordions" },
  Alerts: { path: "/components/alerts" },
  Badges: { path: "/components/badges" },
  Widgets: { path: "/widgets" },
  Breadcrumbs: { path: "/components/breadcrumbs" },
  Buttons: { path: "/components/buttons" },
  Forms: { path: "/components/forms" },
  Modals: { path: "/components/modals" },
  Navs: { path: "/components/navs" },
  Navbars: { path: "/components/navbars" },
  Pagination: { path: "/components/pagination" },
  Popovers: { path: "/components/popovers" },
  Progress: { path: "/components/progress" },
  Tables: { path: "/components/tables" },
  Tabs: { path: "/components/tabs" },
  Tooltips: { path: "/components/tooltips" },
  Toasts: { path: "/components/toasts" },
  WidgetsComponent: { path: "/components/widgets" },
};
