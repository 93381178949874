import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@themesberg/react-bootstrap';
import { toast } from 'react-toastify';
import 'rsuite/dist/styles/rsuite-default.css';
import * as API from '../../../ApiUrl';
import { AvailableRedemptionsTable } from './components/availableRedemptionsTable';
import { merchantApi } from '../../../redux/api/apiConfig';
import reload from '../../../assets/img/icons/reload.svg';
import { Box, Stack, Autocomplete, TextField } from '@mui/material';
import usePageTitle from '../../../hooks/usePageTitle';
import { CLEAR_AVAILABLE_SETTLEMENT, UPDATE_AVAILABLE_SETTLEMENT } from '../../../redux/actionTypes';

function AvailableRedemptions() {
  const [translist, setTranslist] = useState([]);
  const [fetchTranslistLoading, setFetchTranslistLoading] = useState(false);
  const [mtoOptions, setMtoOptions] = useState([]);
  // const [selectedMto, setSelectedMto] = useState(null);
  const [selectedCoin, setSelectedCoin] = useState(null);

  usePageTitle('Available Settlements', 'View available redemptions');
  const { walletsReducer } = useSelector((state) => state);
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.filters.settlements.availableSettlements);
  const { wallets = [] } = walletsReducer;
  console.log(wallets);

  const handleClearFilters = () => {
    dispatch({ type: CLEAR_AVAILABLE_SETTLEMENT });
  };

  const getMtoOptions = useCallback(async () => {
    try {
      const response = await merchantApi.get(`${API.MTO}`);
      setMtoOptions(response.data);
    } catch (error) {
      toast.error(error?.data?.data?.message || 'Something went wrong');
      // toast.error('Could not fetch MTO list.');
    }
  }, []);

  const getTrans = useCallback(async () => {
    setFetchTranslistLoading(true);
    merchantApi
      .get(`${API.PENDING_REDEEM}`)
      .then((response) => {
        setFetchTranslistLoading(false);
        console.log(response);
        if (response.data) {
          setTranslist(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.data?.data?.message || 'Something went wrong');
        setFetchTranslistLoading(false);
      });
  }, []);

  useEffect(() => {
    getMtoOptions();
    getTrans();
  }, [getMtoOptions, getTrans]);

  const mtoFilter = (data) => {
    if (filters.mto) {
      return data.filter((trans) => trans.mto.id == filters?.mto?.id);
    } else {
      return data;
    }
  };

  const coinFilter = (data) => {
    if (filters.coin) {
      return data.filter((trans) => trans.coin.id == filters?.coin?.coin?.id);
    } else {
      return data;
    }
  };

  const filterCurry =
    (...filters) =>
    (data) => {
      const transactionToDisplay = filters.reduce((acc, cur) => cur(acc), data);
      return transactionToDisplay;
    };

  const applyFilters = filterCurry(mtoFilter, coinFilter);

  let data = [];
  if (translist.length > 0) {
    data = applyFilters(translist);
  }

  console.log(data);

  return (
    <>
      <Box sx={{ padding: '0 24px 24px' }}>
        <Stack direction={'row'} width={'100%'} gap={2} mb={2}>
          <Stack sx={{ maxWidth: '25%', flex: 1 }}>
            <Autocomplete
              id='mto'
              value={filters.mto}
              onChange={(e, newValue) => {
                dispatch({
                  type: UPDATE_AVAILABLE_SETTLEMENT,
                  payload: {
                    mto: newValue,
                  },
                });
                // setpaginateTable((prev) => ({ ...prev, mto: newValue.id }));
                // setSelectedMto(newValue);
              }}
              options={mtoOptions}
              getOptionLabel={(option) => `${option?.name} (${option?.country?.name})`}
              sx={{ flexGrow: 1, width: '100%' }}
              renderInput={(params) => {
                return <TextField placeholder='Select MTO' {...params} size='small' />;
              }}
            />
          </Stack>
          <Stack sx={{ maxWidth: '25%', flex: 1 }}>
            <Autocomplete
              id='coin'
              value={filters.coin}
              onChange={(e, newValue) => {
                dispatch({
                  type: UPDATE_AVAILABLE_SETTLEMENT,
                  payload: {
                    coin: newValue,
                  },
                });
              }}
              options={wallets}
              getOptionLabel={(option) => option?.coin?.symbol}
              sx={{ flexGrow: 1, width: '100%' }}
              // renderInput={(params) => <Dropdown.Item as="button" />}
              renderInput={(params) => {
                return <TextField placeholder='Select Coin' {...params} size='small' />;
              }}
            />
          </Stack>
          <Stack sx={{ maxWidth: '25%', width: 'fit-content' }}>
            <Button
              className='text-dark'
              style={{
                background: 'none',
                border: 'none',
                boxShadow: 'none',
              }}
              onClick={handleClearFilters}
            >
              Reset Filters
              <img src={reload} style={{ marginLeft: '7px' }} alt='reset filter' />
            </Button>
          </Stack>
        </Stack>
        <AvailableRedemptionsTable translist={data} fetchTranslistLoading={fetchTranslistLoading} />
      </Box>
    </>
  );
}

export default AvailableRedemptions;
