import React from 'react';
import { styled } from '@material-ui/core/styles';
import { Typography, Stack, Paper, IconButton, Tooltip } from '@mui/material';
import { Refresh } from '../refresh';
import Iconify from '../Iconify';
import ButtonGhost from '../ButtonGhost';

const MyPaper = styled(Paper)({
  borderRadius: 8,
  backgroundColor: '#F0F0F0',
  padding: '1rem',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const BalanceCard = ({
  insufficientBalance,
  balance,
  onRefreshClick,
  loading,
  add = false,
  addClickHandler = () => {},
  refresh = true,
  title = 'Balance',
  sx,
}) => {
  return (
    <Stack direction={'column'} sx={{ flex: '1', width: '100%' }}>
      <MyPaper
        sx={{
          flex: '1',
          width: '100%',
          border: insufficientBalance ? '1px solid #f44336' : 'none',
          ...sx,
        }}
        elevation={0}
      >
        <Stack>
          <Stack>
            <Typography sx={{ color: '#4a4a4a' }} variant='caption'>
              {title}
            </Typography>
          </Stack>
          <Stack sx={{ maxWidth: '100%' }}>
            <Typography
              variant='subtitle2'
              sx={{
                wordBreak: { sm: 'break-all', md: 'initial' },
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                color: insufficientBalance ? '#f44336' : '',
              }}
            >
              {balance ? balance : '--'}
            </Typography>
          </Stack>
        </Stack>
        <Stack direction={'row'} alignItems={'center'}>
          {add ? (
            <Tooltip title={'Add Balance'}>
              <IconButton aria-label='add' onClick={addClickHandler}>
                <Iconify
                  icon={'eva:plus-outline'}
                  sx={{
                    width: '28px',
                    height: '28px',
                    color: '#6d7073',
                  }}
                />
              </IconButton>
            </Tooltip>
          ) : null}
          {refresh ? (
            <Tooltip title={'Refresh Balance'}>
              <IconButton aria-label='refresh' onClick={onRefreshClick}>
                <Iconify
                  icon={'ic:round-refresh'}
                  sx={{
                    width: '28px',
                    height: '28px',
                    color: '#6d7073',
                    animation: `${loading ? 'spin 1.5s linear 0s infinite running forwards' : ''}`,
                    '@keyframes spin': {
                      '100%': {
                        transform: 'rotate(360deg)',
                      },
                    },
                  }}
                />
              </IconButton>
            </Tooltip>
          ) : null}
        </Stack>
      </MyPaper>
      {insufficientBalance ? (
        <Typography variant='caption' sx={{ color: '#f44336' }}>
          Insufficient Balance
        </Typography>
      ) : (
        ''
      )}
    </Stack>
  );
};

export default BalanceCard;
