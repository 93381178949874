import React from "react";
import PropTypes from "prop-types";
// @mui
import { Box, Card, Typography } from "@mui/material";

HeaderCard.propTypes = {
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  sx: PropTypes.object,
};

export default function HeaderCard({ title, total, sx, ...other }) {
  return (
    <Card
      sx={{
        display: "inline-flex",
        alignItems: "center",
        p: 3,
        backgroundColor: "#ffbe0069",
        height: "100%",
        borderRadius: '10px',
        ...sx,
      }}
      {...other}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="subtitle2">{title}</Typography>
        <Typography variant="h3">${total}</Typography>
      </Box>
    </Card>
  );
}
