import React, { useState, useEffect, useContext } from 'react';
import { ReactReduxContext } from 'react-redux';
import { Form, InputGroup, Button } from '@themesberg/react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { AddStore, StoreTable, UpdateStore } from './component';
import { STORE_LIST, CONFIG, READINESS } from '../../../ApiUrl';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { merchantApi } from '../../../redux/api/apiConfig';
import MerchantWalletKey from '../../dashboard/MerchantWalletKeyModel';
import MerchantWalletAddress from '../../../components/model/MerchantWalletAddress';
// import WalletTrustLineSet from '../../../components/model/WalletTrustLineSet';
import { create_account } from '../../../utils/xrpl_functions';
import * as API from '../../../ApiUrl';
import searchIcon from '../../../assets/img/icons/searchIcon.svg';
import dropDown from '../../../assets/img/icons/dropDown.svg';

import * as actionTypes from '../../../redux/actionTypes';
import { setUserPayString } from '../../../redux/actions/auth';
import { SET_USER_PAYSTRING } from '../../../redux/actionTypes';

const statusOptions = [
  { name: 'Select Status', value: '' },
  { name: 'Active', value: 'trust_line_set' },
  { name: 'Inactive', value: 'trust_line_not_set' },
];

const Store = () => {
  const [modal, setmodal] = useState(false);
  const [storeData, setstoreData] = useState({ count: 0, results: [] });
  // const [storeFetchLoading, setStoreFetchLoading] = useState(true)
  const [loading, setloading] = useState(false);
  const [addStoreError, setaddStoreError] = useState({});
  const [showUpdateStoreModal, setShowUpdateStoreModal] = useState(false);
  const [paginateTable, setpaginateTable] = useState({
    search: '',
    status: '',
  });
  const [configData, setConfigData] = useState(null);
  const [selectedStoreData, setSelectedStoreData] = useState(null);
  const [isStoreKeyModelVisible, setisStoreKeyModelVisible] = useState(false);
  const [statusTitle, setStatusTitle] = useState('');
  const [isMerchantWalletAddressModel, setisMerchantWalletAddressModel] = useState(false);
  // const [isWalletTrustlineModel, setisWalletTrustlineModel] = useState(false);
  const [isReadyToCreateStore, setisReadyToStoreCreate] = useState(false);
  const [readinessMessage, setReadinessMessage] = useState('');
  // const [accountFormXrp, setAccountFromXrp] = useState('');
  const [storeId, setStoreId] = useState();

  const history = useHistory();
  const dispatch = useDispatch();

  const curRole = useSelector((store) => store.authReducer.userRole);

  const handleUpdateStore = () => {
    getStoreList();
    toggleUpdateStoreModal();
  };

  const toggleUpdateStoreModal = () => {
    setShowUpdateStoreModal((prevState) => !prevState);
  };

  const editClickHandler = (storeData) => {
    setSelectedStoreData(storeData);
    toggleUpdateStoreModal();
  };

  const handlemodal = () => {
    setmodal(!modal);
  };

  useEffect(() => {
    getReadinessData();
    getConfigData();
    getStoreList();
  }, []);

  // useEffect(() => {
  //   getStoreList();
  // }, [paginateTable.currentPage, paginateTable.pageSize, paginateTable.search]);

  const handlefilter = (name, status) => {
    setStatusTitle(name);
    setpaginateTable((prevState) => ({ ...prevState, status }));
  };

  const handleSearchChange = (search) => {
    setpaginateTable((prevState) => ({ ...prevState, search }));
  };

  const getReadinessData = () => {
    merchantApi
      .get(READINESS)
      .then((response) => {
        const { data } = response;
        console.log(data);
        setisReadyToStoreCreate(data.data.is_valid);
        setReadinessMessage(data.message);
      })
      .catch((error) => {
        toast.error(error.response.data.message || 'Something went wrong');
      });
  };

  const handleWalletCreation = async (res, store) => {
    await merchantApi
      .post(API.GET_WALLETS, {
        address: res.address,
        store_id: store,
      })
      .then((res) => {
        toast.success('Store and Wallet Created Sucessfully.');
        getStoreList();
        setisStoreKeyModelVisible(true);
        setUserPayString();
      })
      .catch((error) => {
        toast.error(error.response.data.message || 'Something went wrong');
      });
  };

  let token = localStorage.getItem('accessToken');

  const updatePaystring = async () => {
    merchantApi
      .get(API.PAY_STRING)
      .then((response) => {
        const { data } = response;
        console.log(data);
        if (data?.data?.paystring) {
          dispatch({
            type: SET_USER_PAYSTRING,
            payload: data.data.paystring,
          });
          return;
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message || 'Something went wrong');
      });
  };

  const handleaddStore = async (data) => {
    setloading(true);
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await merchantApi.post(`${STORE_LIST}`, data, config);
      if (response) {
        toast.success('Store has been created successfully.');
        await getStoreList();
        await updatePaystring();
        handlemodal();
        // setStoreId(response.data.data.store_id);
        // console.log('trans list show ', response.data.data);
        // if (configData.store_wise_wallet) {
        //   create_account((res) =>
        //     handleCallBack(res, response.data.data.store_id)
        //   );
        // } else {
        //   await updatePaystring();
        //   toast.success('Store has been created successfully.');
        //   await getStoreList();
        //   handlemodal();
        //   setloading(false);
        // }
      } else {
        toast.error('Something Went Wrong');
      }
    } catch (error) {
      setaddStoreError(error.response.data.message);
      toast.error(error.response.data.message);
      setloading(false);
    }
  };

  const getConfigData = () => {
    merchantApi
      .get(CONFIG)
      .then((response) => {
        const { data } = response;
        setConfigData(data.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message || 'Something went wrong');
      });
  };

  // const gotoWalletWithStoreId = () => {
  //   history.push({
  //     pathname: '/merchantprofile/wallets',
  //     state: { store_id: storeId },
  //   });
  // };

  const getStoreList = async () => {
    setloading(true);
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };
      // const query = `&limit=1000`;
      console.log('Here');
      const response = await merchantApi.get(`${STORE_LIST}?limit=1000`, config);
      console.log('trans list show ', response.data);

      // Update store in redux after new store added
      console.log(response.data.results);
      if (response.data.results.length > storeData.results.length) {
        dispatch({
          type: actionTypes.SET_STORE_LIST,
          payload: response?.data?.results,
        });
      }

      setstoreData(response.data);
    } catch (error) {
      console.log('trans list error ', error);
    }
    setloading(false);
  };
  const handleCallBack = async (res, store) => {
    setStoreId(store);
    localStorage.removeItem('accountDetail');
    localStorage.setItem('accountDetail', JSON.stringify(res));
    console.log('wallet creation');
    await handleWalletCreation(res, store);
    handlemodal();
  };

  const checkMerchantReadiness = () => {
    if (isReadyToCreateStore) {
      handlemodal();
    } else {
      toast.error(readinessMessage);
      history.push('/merchantprofile/wallets');
    }
  };

  return (
    <>
      <div className='page-wrapper stb-page-wrapper stb-ph-8'>
        <div className='tabing stb-h-100'>
          <div className='reports bg-white p-xl-4 p-3 radi stb-h-100'>
            <div className='page-header  pb-4 space-between'>
              <div className='col-lg-2'>
                <h3 className='m-0 page-title'>Store List</h3>
                <p>View your stores</p>
              </div>
              <div className='gap-4 tools'>
                <div className='filter'>
                  <Form onSubmit={(e) => e.preventDefault()} className='search'>
                    <Form.Group>
                      <InputGroup>
                        <InputGroup.Text>
                          {' '}
                          <img src={searchIcon} alt='search' />
                        </InputGroup.Text>
                        <Form.Control
                          onChange={(e) => {
                            handleSearchChange(e.target.value);
                          }}
                          type='text'
                          placeholder='Search'
                        />
                      </InputGroup>
                    </Form.Group>
                  </Form>
                </div>
                <div className='filter'>
                  <Dropdown as={ButtonGroup} className='w-100'>
                    <Dropdown.Toggle id='dropdown-basic'>
                      {statusTitle || 'Select Status'}
                      <img src={dropDown} style={{ marginLeft: '4px' }} alt='drop-down' />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className='super-colors'>
                      {statusOptions.map((a, i) => (
                        <Dropdown.Item
                          as='button'
                          value={a.name}
                          onClick={() => {
                            handlefilter(a.name, a.value);
                          }}
                          key={i + 'res'}
                        >
                          {a.name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>{' '}
                </div>
                {curRole === 'administrator' ? (
                  <div className='filter'>
                    <Button
                      onClick={() => checkMerchantReadiness()}
                      style={{ minHeight: '40px' }}
                      appearance='default'
                      className='btn load text-white d-flex flex-row gap-2'
                    >
                      <span>Add New Store</span>
                      {/* <img src={add} alt="add" /> */}
                    </Button>
                  </div>
                ) : null}
              </div>
            </div>

            <StoreTable
              loading={loading}
              configData={configData}
              // handleShowWalletStatusSelect={handleShowWalletStatusSelect}
              setpaginateTable={setpaginateTable}
              paginateTable={paginateTable}
              editClickHandler={editClickHandler}
              storeData={storeData?.results || []}
            />
          </div>
        </div>
      </div>
      {modal && <AddStore loading={loading} open={modal} onClose={handlemodal} handleaddStore={handleaddStore} error={addStoreError} />}
      {isStoreKeyModelVisible && (
        <MerchantWalletKey
          open={isStoreKeyModelVisible}
          handleClose={() => {
            setisStoreKeyModelVisible(!isStoreKeyModelVisible);
            setisMerchantWalletAddressModel(!isMerchantWalletAddressModel);
          }}
        />
      )}
      {isMerchantWalletAddressModel && (
        <MerchantWalletAddress
          open={isMerchantWalletAddressModel}
          handleClose={() => {
            setisMerchantWalletAddressModel(!isMerchantWalletAddressModel);
          }}
        />
      )}

      {showUpdateStoreModal && (
        <UpdateStore
          open={showUpdateStoreModal}
          handleUpdateStore={handleUpdateStore}
          onClose={toggleUpdateStoreModal}
          loading={loading}
          storeData={selectedStoreData}
        />
      )}
    </>
  );
};

export default Store;
