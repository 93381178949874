import React from 'react';
// import { CircularProgress } from '@material-ui/core';
import { Box, CircularProgress } from '@mui/material';

const FullLoader = () => {
  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        // backgroundColor: 'rgba(11, 5, 35, 0)',
        zIndex: '100',
      }}
    >
      <CircularProgress
        sx={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          color: '#FCBD00',
          transform: 'translate(-50%, -50%)',
        }}
      />
    </Box>
  );
};

export default FullLoader;
