import React, { useState, useEffect, useCallback } from 'react';
import { merchantApi } from '../../../redux/api/apiConfig';
import 'rsuite/dist/styles/rsuite-default.css';
import { toast } from 'react-toastify';
import * as API from '../../../ApiUrl';
import searchIcon from '../../../assets/img/icons/searchIcon.svg';
import { Form, InputGroup } from '@themesberg/react-bootstrap';
import usePageTitle from '../../../hooks/usePageTitle';
import { Autocomplete, Box, Divider, InputAdornment, Stack, TextField } from '@mui/material';
import { ButtonPrimary, Iconify } from '../../../components';
import { useDispatch, useSelector } from 'react-redux';
import DepositsTable from './components/DepositsTable';
import StatusModal from '../../remittance/inward/components/statusModal/StatusModal';
import { UPDATE_DEPOSIT } from '../../../redux/actionTypes';

const Deposits = () => {
  const [deposits, setDeposits] = useState({});
  const [allStatuses, setAllStatuses] = useState([]);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [fetchUsersLoading, setFetchUsersLoading] = useState(false);
  const [depositData, setDepositData] = useState('');
  const [mtoPatchLoading, setMtoPatchLoading] = useState(false);
  const [clientOptions, setClientOptions] = useState([]);

  usePageTitle('Client Deposits', 'View your deposits made by clients');

  const dispatch = useDispatch();
  const filters = useSelector((state) => state.filters.clients.sender.deposit);

  const updateStatusClickHandler = (transaction) => {
    setDepositData(transaction);
    toggleStatusModal();
  };

  const toggleStatusModal = () => {
    setShowStatusModal((prev) => !prev);
  };

  const getStatusOptions = useCallback(async () => {
    try {
      const response = await merchantApi.get(API.DEPOSIT_STATUS);
      const inProcess = response.data.find((status) => status.value === 'submitted');
      console.log(inProcess, filters.status, !filters.status);
      if (!filters.status) {
        dispatch({ type: UPDATE_DEPOSIT, payload: { status: inProcess } });
      }
      // setPaginateTable((prev) => ({ ...prev, status: inProcess }));
      setAllStatuses([{ value: '', actionable_name: 'All' }, ...response.data]);
    } catch (error) {
      console.log(error);
      toast.error(error?.data?.data?.message || 'Something went wrong');
    }
  }, [dispatch, filters]);

  const getClientOptions = useCallback(async () => {
    try {
      const response = await merchantApi.get(`${API.SENDER}/`);
      setClientOptions(response.data);
      console.log(response);
    } catch (error) {
      console.log(error);
      toast.error(error?.data?.data?.message || 'Something went wrong');
    }
  }, []);

  const getDeposits = useCallback(async () => {
    console.log(filters.status);
    if (filters.status !== null) {
      setFetchUsersLoading(true);
      try {
        const response = await merchantApi.get(`${API.DEPOSITS}`, {
          params: {
            offset: filters.currentPage * filters.pageSize,
            limit: filters?.pageSize,
            search: filters?.search,
            status: filters?.status?.id || '',
            sender: filters?.sender?.id,
          },
        });
        setDeposits(response.data);
        setFetchUsersLoading(false);
      } catch (error) {
        console.log(error);
        toast.error(error?.data?.data?.message || 'Something went wrong');
        setFetchUsersLoading(false);
      }
    }
  }, [filters]);

  useEffect(() => {
    const timer = setTimeout(() => {
      getDeposits();
    }, 500);
    return () => clearTimeout(timer);
  }, [filters.currentPage, filters.pageSize, filters.search, getDeposits]);

  useEffect(() => {
    getStatusOptions();
    getClientOptions();
  }, [getClientOptions, getStatusOptions]);

  const handleSearch = (e) => {
    dispatch({
      type: UPDATE_DEPOSIT,
      payload: { currentPage: 0, search: e.target.value },
    });

    // setPaginateTable({
    //   ...filters,
    //   currentPage: 0,
    //   search: e.target.value,
    // });
  };

  const updateClickHandler = async (status, remarks) => {
    let value = allStatuses.find((statusItem) => statusItem.id === status).value;

    updateStatus(status, remarks);
  };
  const updateStatus = async (status, remarks) => {
    try {
      setMtoPatchLoading(true);
      const response = await merchantApi.patch(`${API.DEPOSITS}${depositData.id}/`, {
        status,
        remarks,
      });
      setMtoPatchLoading(false);
      toggleStatusModal();
      getDeposits();
    } catch (error) {
      setMtoPatchLoading(false);
      toast.error(error?.data?.data?.message || 'Something went wrong');
    }
  };

  return (
    <Box sx={{ padding: '0 24px 24px' }}>
      <Stack
        sx={{
          overflowX: { xs: 'scroll', md: 'visible' },
          overflowY: { xs: 'visible', md: 'unset' },
          padding: '5px 0',
        }}
        direction={'row'}
        justifyContent={'space-between'}
        gap={1}
        mt={2}
        mb={2}
        width={'100%'}
      >
        <Stack sx={{ flexDirection: 'row', gap: 1 }}>
          <Stack sx={{ minWidth: '200px', maxWidth: '25%', flex: 1 }}>
            <TextField
              fullWidth
              size='small'
              value={filters.search}
              onChange={handleSearch}
              placeholder='Search...'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Iconify icon='eva:search-fill' sx={{ color: 'text.disabled' }} />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <Stack sx={{ minWidth: '200px', maxWidth: '25%', flex: 1 }}>
            <Autocomplete
              id='status'
              fullWidth
              value={filters.status}
              onChange={(e, newValue) => {
                dispatch({
                  type: UPDATE_DEPOSIT,
                  payload: { status: newValue },
                });
                // setPaginateTable((prevState) => ({
                //   ...prevState,
                //   status: newValue ?? null,
                // }));
              }}
              options={allStatuses}
              getOptionLabel={(option) => option?.actionable_name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              // sx={{ flexGrow: 1, width: '100%' }}
              renderInput={(params) => {
                return <TextField placeholder='Select Status' {...params} size='small' />;
              }}
            />
          </Stack>
          <Stack sx={{ minWidth: '200px', maxWidth: '25%', flex: 1 }}>
            <Autocomplete
              id='client'
              fullWidth
              // value={filters.status}
              onChange={(e, newValue) => {
                dispatch({
                  type: UPDATE_DEPOSIT,
                  payload: { sender: newValue ?? null },
                });
                // setPaginateTable((prevState) => ({
                //   ...prevState,
                //   sender: newValue ?? null,
                // }));
              }}
              options={clientOptions}
              getOptionLabel={(option) =>
                option.sender_type === 'individual'
                  ? `${option?.first_name} ${option?.last_name} (${option?.xrpl_tag})`
                  : `${option?.business_name} (${option?.xrpl_tag})`
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => {
                return <TextField placeholder='Select Client' {...params} size='small' />;
              }}
            />
          </Stack>
        </Stack>
      </Stack>

      <DepositsTable fetchUsersLoading={fetchUsersLoading} deposits={deposits} updateStatus={updateStatusClickHandler} />
      {showStatusModal && (
        <StatusModal
          isOpen={showStatusModal}
          onClose={toggleStatusModal}
          updateClickHandler={updateClickHandler}
          mtoPatchLoading={mtoPatchLoading}
          status={depositData?.status}
          canChangeTo={depositData?.status?.receiver_can_change_to}
        />
      )}
    </Box>
  );
};

export default Deposits;
