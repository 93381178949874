import React, { useEffect, useState } from 'react';
import { Stack, Box } from '@mui/material';
import { GeneralInfo } from '../GeneralInfo';
import ProfileCard from './ProfileCard';
import { merchantApi } from '../../redux/api/apiConfig';
import { MERCHANT_PROFILE } from '../../ApiUrl';
import { toast } from 'react-toastify';

export default () => {
  const [userInfo] = useState(JSON.parse(localStorage.getItem('user-info')));
  const [userProfileData, setUserProfileData] = useState({});

  useEffect(() => {
    // getProfileDetails();
  }, []);

  let user = JSON.parse(localStorage.getItem('user-info'));

  const getProfileDetails = () => {
    merchantApi
      .get(`${MERCHANT_PROFILE}${userInfo.user_id}/`)
      .then((response) => {
        const { data } = response;
        setUserProfileData(data.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message || 'Something went wrong');
      });
  };

  return (
    <>
      {user && Object.keys(user).length > 0 && (
        <Box sx={{ padding: '24px' }}>
          <Stack>
            <ProfileCard userProfileData={user} />
            {/* <GeneralInfo userProfileData={user} /> */}
          </Stack>
        </Box>
      )}
    </>
  );
};
