import React from 'react';
import { alpha, styled } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers';

const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  maxHeight: '100%',
  '& .MuiInputBase-input': {
    padding: '9px 0 9px 14px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    height: '45px',
  },
}));

export default StyledDatePicker;
