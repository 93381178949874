import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Tabs, Tab, Stack, Divider, MenuItem, Autocomplete, TextField, Card } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { toast } from 'react-toastify';
import 'rsuite/dist/styles/rsuite-default.css';

import * as API from '../../ApiUrl';
import Transactiontable from './ReportTransactions/Transactiontable';
import { merchantApi } from '../../redux/api/apiConfig';

import { format as Format } from 'date-fns';
import { formatDate } from '../../utils/common';
import { Box } from '@mui/material';
import usePageTitle from '../../hooks/usePageTitle';
import { reportTimePeriods } from '../reports/common';
import { FilterDropdown, Highlight, HighlightSkeleton } from '../reports/components';
import { StyledDatePicker } from '../../components/datePicker';
import { ButtonResetFilter } from '../../components';
import {  UPDATE_TRANSACTION } from '../../redux/actionTypes';

function ReportTransactions() {

  const [translist, setTranslist] = useState([]);
  const [fetchTranslistLoading, setFetchTranslistLoading] = useState(false);
  const [highlights, setHighlights] = useState([]);
  const [highlightLoading, setHighlightLoading] = useState(false);
  const [resetData, setResetData] = useState(0);

  usePageTitle('Overview');

  const filters = useSelector((state) => state.filters.transactions);
  const dispatch = useDispatch();

  const handleClearFilters = () => {
    dispatch({
      type: UPDATE_TRANSACTION,
      payload: {
        calenderValue: [new Date(reportTimePeriods[1].from), null],
        period: reportTimePeriods[1],
        currentPage: 0,
        pageSize: 7,
        search: '',
        type: '',
      },
    });
  };

  const getTrans = useCallback(
    async (signal) => {
      setFetchTranslistLoading(true);
      const { currentPage, pageSize, search, calenderValue, type } = filters;
      const params = {
        created_date_after: formatDate(calenderValue[0], 'yyyy-MM-dd'),
        created_date_before: formatDate(calenderValue[1], 'yyyy-MM-dd'),
        limit: pageSize,
        offset: currentPage * pageSize,
        search: search,
        transaction_type: type,
      };
      try {
        const response = await merchantApi.get(`${API.TRANSFER_TRANSACTION}`, {
          params,
          signal,
        });
        setFetchTranslistLoading(false);
        if (response.data) {
          setTranslist(response.data);
        }
      } catch (error) {
        toast.error(error?.data?.data?.message);
        setFetchTranslistLoading(false);
      }
    },
    [filters]
  );
  const getHighlights = useCallback(
    async (signal) => {
      setHighlightLoading(true);
      try {
        const {calenderValue, pageSize, currentPage, amount_max, amount_min, type } = filters;

        const created_date_before = formatDate(calenderValue[1], 'yyyy-MM-dd');
        const created_date_after = formatDate(calenderValue[0], 'yyyy-MM-dd');

        const params = {
          created_date_after,
          created_date_before,
          limit: pageSize,
          offset: pageSize * currentPage,
          amount_max: amount_max > 0 ? amount_max : '',
          amount_min: amount_min > 0 ? amount_min : '',
          transaction_type: type,
        };
        const response = await merchantApi.get(`${API.TRANSACTION_REPORT}highlight/`, {
          params,
          signal,
        });
        setHighlights(response.data.data);
        setHighlightLoading(false);
      } catch (error) {
        console.log(error);
        toast.error(error?.data?.data?.message || 'Something went wrong');
        setHighlightLoading(false);
      }
    },
    [filters]
  );





  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
     const timer = setTimeout(() => {
        getTrans(signal);
        getHighlights(signal);
      }, 500);
    return () => {
      controller.abort();
      clearTimeout(timer);
    };
  }, [
    filters.currentPage,
    filters.pageSize,
    filters.search,
    filters.calenderValue,
    resetData,
    getTrans,
    getHighlights,
  ]);

  return (
    <>
      <Box sx={{ padding: '0 24px 24px' }}>
        <Card sx={{ width: '100%' }}>
          <Stack direction='row' divider={<Divider orientation='vertical' flexItem sx={{ borderStyle: 'dashed' }} />} sx={{ py: 2 }}>
            {highlightLoading
              ? [...Array(1)].map((a) => <HighlightSkeleton />)
              : highlights.map((highlight, i) => {
                  return (
                    <Highlight
                      key={highlight.heading}
                      title={highlight.heading}
                      subTitle={highlight.sub_heading}
                      // total={translist.count}
                      percent={100}
                      value={highlight.data}
                      icon={highlight?.meta?.icon}
                      color={highlight?.meta?.color}
                    />
                  );
                })}
          </Stack>
        </Card>
        <Stack
          sx={{
            overflowX: { xs: 'auto' },
            overflowY: { xs: 'visible', md: 'unset' },
            padding: '5px 0',
          }}
          direction={'row'}
          justifyContent={'space-between'}
          gap={1}
          mt={2}
          mb={2}
          mr={2}
          width={'100%'}
        >
          <Stack
            sx={{
              flexDirection: 'row',
              gap: 1,
            }}
            divider={<Divider orientation='vertical' flexItem sx={{ borderStyle: 'dashed' }} />}
          >
            <Stack sx={{ maxWidth: '150px', flex: 1 }}>
              <FilterDropdown
                name='timeperiod'
                type='text'
                label='Timeperiod'
                options={reportTimePeriods}
                onChange={(e) => {
                  const name = e.target.value;
                  const period = reportTimePeriods.find((report) => report.name === name);

                  let toValue;
                  if (filters.calenderValue[1]) {
                    toValue = new Date(period.from) > new Date(filters.calenderValue[1]) ? null : filters.calenderValue[1];
                  } else {
                    toValue = null;
                  }

                  dispatch({
                    type: UPDATE_TRANSACTION,
                    payload: {
                      calenderValue: [new Date(period.from), toValue],
                      period,
                    },
                  });
                  // setTransactionReport((prevState) => ({
                  //   ...prevState,
                  //   calenderValue: [new Date(period.from), toValue],
                  //   period,
                  // }));
                }}
                value={filters?.period?.name}
                size={'small'}
                render={(period, i) => (
                  <MenuItem key={period.name} value={period.name}>
                    {period.name}
                  </MenuItem>
                )}
              />
            </Stack>
            <Stack sx={{ flexDirection: 'row', gap: 1 }}>
              <Stack sx={{ width: '165px', flex: 1 }}>
                <StyledDatePicker
                  value={filters.calenderValue[0]}
                  disableFuture
                  format={'dd/MM/yyyy'}
                  onChange={(newValue) => {
                    dispatch({
                      type: UPDATE_TRANSACTION,
                      payload: {
                        calenderValue: [
                          new Date(Format(newValue, 'yyyy/MM/dd')),
                          newValue > filters.calenderValue[1] ? undefined : filters.calenderValue[1],
                        ],
                      },
                    });
                    // setTransactionReport((prevState) => ({
                    //   ...prevState,
                    //   calenderValue: [
                    //     new Date(Format(newValue, 'yyyy/MM/dd')),
                    //     newValue > filters.calenderValue[1]
                    //       ? undefined
                    //       : filters.calenderValue[1],
                    //   ],
                    // }));
                  }}
                />
              </Stack>

              <Stack sx={{ width: '165px', flex: 1 }}>
                <StyledDatePicker
                  value={filters.calenderValue[1]}
                  disableFuture
                  // minDate={filters.calenderValue[0] ?? undefined}
                  format={'dd/MM/yyyy'}
                  onChange={(newValue) => {
                    dispatch({
                      type: UPDATE_TRANSACTION,
                      payload: {
                        calenderValue: [
                          newValue < filters.calenderValue[0] ? undefined : filters.calenderValue[0],
                          new Date(Format(newValue, 'yyyy/MM/dd')),
                        ],
                      },
                    });
                   
                  }}
                />
              </Stack>
            </Stack>
           
              <Stack
            sx={{
              flexDirection: 'row',
              gap: 1,
            }}
          >
            <ButtonResetFilter onClick={handleClearFilters} />
          </Stack>
          </Stack>
        
        </Stack>

        <Transactiontable
          translist={translist}
          fetchTranslistLoading={fetchTranslistLoading}
          resetTransactions={setResetData}
        />
      </Box>
    </>
  );
}

export default ReportTransactions;
