import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import Faq from '../../data/Faq';
import TicketList from './component/TicketList';
import * as API from '../../ApiUrl';
import NewTicketModal from './component/NewTicketModal';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import { Form, InputGroup, Button } from '@themesberg/react-bootstrap';
import searchIcon from '../../assets/img/icons/searchIcon.svg';
import dropDown from '../../assets/img/icons/dropDown.svg';
import { capitalize } from 'lodash';
import { merchantApi } from '../../redux/api/apiConfig';
import { Box, Stack } from '@mui/material';
import usePageTitle from '../../hooks/usePageTitle';
import { ButtonPrimary } from '../../components';
import reload from '../../assets/img/icons/reload.svg';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const Support = () => {
  const [tickets, setTickets] = useState({});
  const [loading, setLoading] = useState(false);
  const [ticketCategory, setTicketCategory] = useState('');
  const [showNewTicketModal, setShowNewTicketModal] = useState(false);
  const [cateOption, setCateOption] = useState([{ name: 'Select Category', id: '' }]);

  const [statusOptions, setStatusOptions] = React.useState([{ name: 'Select Status', value: '' }]);
  const [statusTitle, setStatusTitle] = React.useState('');

  const [pageChange, setPageChange] = useState({
    currentPage: 0,
    pageSize: 7,
    search: '',
    category: '',
    status: '',
    loading: false,
  });
  const role = useSelector((state) => state.profileReducer?.user?.role?.key);
  const permission = useSelector((state) => state.profileReducer?.permission);
  const location = useLocation();
  usePageTitle('Support', 'View your tickets');
  const toggleNewTicketModal = () => {
    setShowNewTicketModal((prevState) => !prevState);
  };

  const getTickets = useCallback(async () => {
    try {
      setLoading(true);
      const { pageSize, currentPage, search, category, status } = pageChange;
      const response = await merchantApi.get(API.TICKET_LIST, {
        params: {
          limit: pageSize,
          offset: currentPage * pageSize,
          search,
          category,
          status,
        },
      });
      console.log(response.data);
      setTickets(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error?.data?.data?.message || 'Something went wrong');
      console.log('my error is ', error);
    }
  }, [pageChange]);

  useEffect(() => {
    console.log(location.state);
    if (location?.state?.openTicketModal) {
      toggleNewTicketModal();
    }
    getTicketCategory();
    getStatusOption();
  }, []);

  const getTicketCategory = async () => {
    try {
      const response = await merchantApi.get(API.SUPPORT_CATEGORY);
      console.log(response);
      setCateOption([...cateOption, ...response.data]);
    } catch (error) {
      toast.error(error?.data?.data?.message || 'Something went wrong');
      console.log('ticket status list error ', error);
    }
  };

  const getStatusOption = async () => {
    try {
      const response = await merchantApi.get(API.SUPPORT_STATUS);
      setStatusOptions([...statusOptions, ...response.data.data]);
    } catch (error) {
      toast.error(error?.data?.data?.message || 'Something went wrong');
      console.log('status list error ', error);
    }
  };

  useEffect(() => {
    getTickets();
  }, [pageChange.search, pageChange.offset, pageChange.pageSize, pageChange.category, pageChange.status, pageChange.currentPage, getTickets]);

  const handlePageChange = (p) => {
    setPageChange({
      ...p,
      search: pageChange.search,
    });
  };
  const handlefilter = (id, name) => {
    setTicketCategory(name);
    setPageChange({
      ...pageChange,
      currentPage: 0,
      category: id,
    });
  };

  const handleStatusChange = (name, value) => {
    setStatusTitle(name);
    setPageChange({
      ...pageChange,
      currentPage: 0,
      status: value,
    });
  };

  const handleSearch = (e) => {
    // console.log("search", e);
    setPageChange({
      ...pageChange,
      search: e,
      currentPage: 0,
    });
  };

  const handleClearFilters = () => {
    setStatusTitle('');
    setTicketCategory('');
    setPageChange({
      currentPage: 0,
      pageSize: 7,
      search: '',
      category: '',
      status: '',
      loading: false,
    });
  };

  return (
    <Box sx={{ padding: '0 24px 24px' }}>
      <Stack direction={'row'} justifyContent={'space-between'} width={'100%'} mb={2}>
        <Stack gap={2} direction={'row'} width={'100%'}>
          <Stack sx={{ maxWidth: '25%', flex: 1 }}>
            <Form onSubmit={(e) => e.preventDefault()} className='w-100 search'>
              <Form.Group>
                <InputGroup>
                  <InputGroup.Text>
                    <img src={searchIcon} alt='search' />
                  </InputGroup.Text>
                  <Form.Control
                    onChange={(e) => {
                      handleSearch(e.target.value);
                    }}
                    type='text'
                    placeholder='Search'
                    value={pageChange.search}
                  />
                </InputGroup>
              </Form.Group>
            </Form>
          </Stack>

          <Stack sx={{ maxWidth: '25%', flex: 1 }}>
            <Dropdown as={ButtonGroup} className='w-100'>
              <Dropdown.Toggle id='dropdown-basic'>
                {ticketCategory || 'Select Category'}
                <img src={dropDown} className='dropdown-icon' style={{ marginLeft: '4px' }} alt='drop-down' />
              </Dropdown.Toggle>
              <Dropdown.Menu className='super-colors'>
                {cateOption.map((a, i) => (
                  <Dropdown.Item
                    as='button'
                    value={a.name}
                    onClick={() => {
                      handlefilter(a.id, a.name);
                    }}
                    key={i + 'res'}
                  >
                    {a.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>{' '}
          </Stack>
          <Stack sx={{ maxWidth: '25%', flex: 1 }}>
            <Dropdown as={ButtonGroup} className='w-100'>
              <Dropdown.Toggle id='dropdown-basic'>
                {capitalize(statusTitle) || 'Select Status'}
                <img src={dropDown} className='dropdown-icon' style={{ marginLeft: '4px' }} alt='drop-down' />
              </Dropdown.Toggle>
              <Dropdown.Menu className='super-colors'>
                {statusOptions.map((a, i) => (
                  <Dropdown.Item
                    as='button'
                    value={a.name}
                    onClick={() => {
                      handleStatusChange(a.name, a.value);
                    }}
                    key={i + 'res'}
                  >
                    {capitalize(a.name)}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>{' '}
          </Stack>
          <Stack sx={{ maxWidth: '25%', flex: 1 }}>
            <Button
              className=' w-xs-100  text-dark w-100'
              style={{
                background: 'none',
                border: 'none',
                width: '100%',
                boxShadow: 'none',
              }}
              onClick={handleClearFilters}
            >
              Reset Filters
              <img src={reload} style={{ marginLeft: '7px' }} alt='reset filter' />
            </Button>
          </Stack>
        </Stack>
        {permission?.support?.platformSupport?.permission?.newTicket[role] && (
          <ButtonPrimary onClick={toggleNewTicketModal}>New Ticket</ButtonPrimary>
        )}{' '}
      </Stack>

      <TicketList
        tickets={tickets}
        loading={loading}
        // setpageChange={handlePageChange}
        paginationdetails={pageChange}
        setPageChange={setPageChange}
      />

      {showNewTicketModal && <NewTicketModal isOpen={showNewTicketModal} onClose={toggleNewTicketModal} getTickets={getTickets} />}
      <Faq />
    </Box>
  );
};

export default Support;
