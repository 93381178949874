import React from 'react';
import { format } from 'date-fns';
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Stack } from '@mui/material';
import { PreviewItem, PreviewContainer } from '../../../../../components';
import { useRemittance } from '../../../../../context/remittance/remittanceContext';
import logo from '../../../../../assets/img/logo-stb.png'

const Preview = React.forwardRef((props, ref) => {
  const { clientInfo, recipientInfo, transferDetails } = useRemittance();
  const { configReducer } = useSelector((state) => state);

  const { depositTypes, purposeTypes } = configReducer;

  const userInfo = JSON.parse(localStorage.getItem('user-info'));

  const { sender_document, sender } = clientInfo;

  console.log(sender, sender_document);
  const documents = sender?.sender_document
    ?.filter((doc) => sender_document.includes(doc.id))
    .map((doc) => `${doc?.name ?? ''} (${doc?.document_type?.name ?? ''})`);

  console.log(documents);

  const docsPreview = documents.reduce((prev, curr) => {
    return prev + ', ' + curr;
  });
  console.log(docsPreview);
  let remittanceContent;
  if (clientInfo) {
    let { purpose, other_purpose } = clientInfo;
    let { deposit_type, other_deposit } = transferDetails;

    console.log(deposit_type, depositTypes)

      deposit_type = depositTypes.filter((depo) => depo.id === deposit_type)[0].name;
 
    if (purpose !== purposeTypes.length) {
      purpose = purposeTypes.filter((pur) => pur.id === purpose)[0].name;
    } else {
      purpose = other_purpose;
    }

    remittanceContent = (
      <>
        <PreviewItem title={'Remittance Amount'} content={`${transferDetails.send_amount} ${transferDetails.currency.receive}`} />
        <PreviewItem title={'Sender MTO Fee'} content={`${transferDetails.fee.outward_fee} ${transferDetails.currency.receive}`} />
        <PreviewItem title={'Total Fee'} content={`${Number(transferDetails.fee.total_fee)} ${transferDetails.currency.receive}`} />
        <PreviewItem title={'Total Deposit Amount'} content={`${transferDetails.fee.user_pay} ${transferDetails.currency.send}`} />
        <PreviewItem title={'Amount to Payout'} content={`${transferDetails.receive_amount} ${transferDetails.currency.receive}`} />
        <PreviewItem title={'Deposit Type'} content={deposit_type} />

        <PreviewItem title={'Purpose of Remittance'} content={purpose} />
        {/* <PreviewItem
            title={'Fee'}
            content={`${transferDetails.fee.user_pay} ${transferDetails.currency.send}`}
          /> */}
        <PreviewItem
          title={'Conversion Rate'}
          content={`1 ${transferDetails.currency.send} = ${transferDetails.fee.offer} ${transferDetails.currency.receive}`}
        />
        <PreviewItem title={'Type'} content={(clientInfo?.type).toUpperCase()} />
        <PreviewItem title={'Date of Remittance'} content={format(new Date(), 'dd MMM yyyy HH:mm a')} />
        <PreviewItem title={'Created By'} content={`${userInfo?.first_name} ${userInfo?.last_name}`} />
        {/* <PreviewItem title={'Sender MTO'} content={userInfo?.mto?.name} /> */}
      </>
    );
  }

  const senderType = clientInfo.type.split('2')[0];
  let senderContent;

  if (clientInfo.sender) {
    const { sender } = clientInfo;
    if (senderType === 'p') {
      senderContent = (
        <>
          <PreviewItem title={'First Name'} content={sender?.first_name} />
          <PreviewItem title={'Last Name'} content={sender?.last_name} />
        </>
      );
    } else if (senderType === 'b') {
      senderContent = (
        <>
          <PreviewItem title={'Business Name'} content={sender?.business_name} />
          <PreviewItem title={'Primary Contact Name'} content={sender?.primary_contact_name} />
        </>
      );
    }
  }

  const recipientType = clientInfo.type.split('2')[1];
  let recipientContent;
  if (recipientInfo.recipient) {
    const { recipient } = recipientInfo;
    if (recipientType === 'p') {
      recipientContent = (
        <>
          <PreviewItem title={'First Name'} content={recipient?.first_name} />
          <PreviewItem title={'Last Name'} content={recipient?.last_name} />
        </>
      );
    } else if (recipientType === 'b') {
      recipientContent = (
        <>
          <PreviewItem title={'Business Name'} content={recipient?.business_name} />
          <PreviewItem title={'Primary Contact Name'} content={recipient?.primary_contact_name} />
        </>
      );
    }
  }
  let financialDetails = '';
  if (recipientInfo.financeAccount) {
    const type = recipientInfo.financeAccount.type;
    if (type === 'venmo') {
      financialDetails = (
        <>
          <PreviewItem title={'Venmo ID'} content={recipientInfo?.financeAccount?.venmo_id} />
        </>
      );
    } else if (type === 'paypal') {
      financialDetails = (
        <>
          <PreviewItem title={'PayPal Email'} content={recipientInfo?.financeAccount?.email} />
        </>
      );
    } else if (type === 'bank') {
      const { name, account_no, lei_code, branch, iban, swift_code, transit_number, aba_number, ifsc_code, address } =
        recipientInfo.financeAccount.bank;
      financialDetails = (
        <>
          <PreviewItem title={'Bank Name'} content={name} />
          <PreviewItem title={'Branch'} content={branch} /> <PreviewItem title={'Account Number'} content={account_no} />{' '}
          <PreviewItem title={'LEI Code'} content={lei_code} /> <PreviewItem title={'IBAN'} content={iban} />{' '}
          <PreviewItem title={'SWIFT BIC Code'} content={swift_code} />
          <PreviewItem title={'Transit Number'} content={transit_number} />
          <PreviewItem title={'ABA Routing Number'} content={aba_number} />
          <PreviewItem title={'IFSC Code'} content={ifsc_code} />
          <PreviewItem title={'Address'} content={address} />
        </>
      );
    }
  }

  return (
    <>
      <Box ref={ref} sx={{ padding: '24px ' }}>
        <Stack spacing={3} direction={'column'}>
        <Stack sx={{backgroundColor:'#0b0523', padding:"1rem", borderRadius:'8px', display:'flex', justifyContent:'center', alignItems:'center'}}>
          <img src={logo} alt='stb' style={{width: '25%', height:'auto'}}/>
          </Stack>
          <PreviewContainer title={'Remittance Details'}>{remittanceContent}</PreviewContainer>
          {/* <Divider
              orientation={matches ? '' : 'vertical'}
              variant="middle"
              flexItem={true}
            /> */}
          <PreviewContainer title={'Sender Details'}>
            {senderContent}
            <PreviewItem title={'Phone'} content={clientInfo?.sender?.primary_contact_no} />
            <PreviewItem title={'City'} content={clientInfo?.sender?.city?.name} />

            <PreviewItem title={'Province'} content={clientInfo?.sender?.province?.name} />
            <PreviewItem title={'Country'} content={clientInfo?.sender?.country?.name} />
            <PreviewItem title={'Address'} content={clientInfo?.sender?.address} />
            <PreviewItem title={'Documents'} content={docsPreview} />
          </PreviewContainer>
          {/* <Divider
              orientation={matches ? '' : 'vertical'}
              variant="middle"
              flexItem={true}
            /> */}
          {/* <Stack
              spacing={!matches ? 2 : 0}
              direction={{
                xs: 'column',
                sm: 'row',
              }}
              sx={{ flex: 1 }}
            > */}
          <PreviewContainer title={'Recipient Details'}>
            {recipientContent}
            <PreviewItem title={'Phone'} content={recipientInfo.recipient?.primary_contact_no} />
            <PreviewItem title={'City'} content={recipientInfo.recipient?.city?.name} />

            <PreviewItem title={'Province'} content={recipientInfo.recipient?.province?.name} />
            <PreviewItem title={'Country'} content={recipientInfo.recipient?.country?.name} />
            <PreviewItem title={'Address'} content={recipientInfo.recipient?.address} />
          </PreviewContainer>
          {/* {!matches && (
                <Divider
                  orientation={matches ? '' : 'vertical'}
                  variant="middle"
                  flexItem={true}
                />
              )} */}
          <PreviewContainer title={'Financial Details'}>{financialDetails}</PreviewContainer>
          {/* </Stack> */}
        </Stack>
      </Box>
    </>
  );
});

export default Preview;
