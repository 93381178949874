import React from 'react';
import { TextField, Stack, Typography } from '@mui/material';

const TextFieldComponent = ({ formik, name, type, label, inputProps = {}, onChange = () => {}, placeholder = '', required = true, ...others }) => {
  return (
    <Stack spacing={0} width={'100%'}>
      <Typography>
        {label} {required ? '*' : ''}
      </Typography>
      <TextField
        fullWidth
        variant='outlined'
        placeholder={placeholder}
        name={name}
        id={name}
        type={type}
        value={formik?.values[`${name}`]}
        onChange={(e) => {
          onChange(e);
          formik.handleChange(e);
        }}
        onBlur={formik?.handleBlur}
        error={formik?.touched[`${name}`] && Boolean(formik?.errors[`${name}`])}
        helperText={formik?.touched[`${name}`] && formik?.errors[`${name}`]}
        InputProps={inputProps}
        {...others}
      />
    </Stack>
  );
};

export default TextFieldComponent;
