import React, { useState, useEffect, useCallback } from 'react';
import { UserTables as UserData } from './components';
import 'rsuite/dist/styles/rsuite-default.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import * as API from '../../../ApiUrl';
import AddSystemUserModal from '../AddSystemUserModal';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import searchIcon from '../../../assets/img/icons/searchIcon.svg';
import { Form, InputGroup, Button } from '@themesberg/react-bootstrap';
import dropDown from '../../../assets/img/icons/dropDown.svg';
import { merchantApi } from '../../../redux/api/apiConfig';
import usePageTitle from '../../../hooks/usePageTitle';
import { Box, Stack } from '@mui/material';
import { ButtonPrimary } from '../../../components';
import { useSelector } from 'react-redux';
import reload from '../../../assets/img/icons/reload.svg';

const SystemUser = () => {
  const [users, setUsers] = useState([]);
  const [fetchUsersLoading, setFetchUsersLoading] = useState(true);
  const [userStores, setUserStores] = useState([]);
  const [userroletitle, setuserroletitle] = useState('');
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [userRoleOption, setUserRoleOption] = useState([{ id: 0, name: 'Select User Role', key: 'select' }]);
  const [pageChange, setPageChange] = useState({
    search: '',
    userrole: '',
  });
  const role = useSelector((state) => state.profileReducer?.user?.role?.key);
  const permission = useSelector((state) => state.profileReducer?.permission);
  usePageTitle('System Users', 'View your users');
  const toggleAddUserModal = () => {
    setShowAddUserModal((prevState) => !prevState);
  };

  const getUsers = useCallback(async () => {
    setFetchUsersLoading(true);
    try {
      let token = localStorage.getItem('accessToken');
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await merchantApi.get(`${API.SYSTEM_USER}`, config);

      setUsers(response.data);
      setFetchUsersLoading(false);
    } catch (error) {
      toast.error(error?.data?.data?.message || 'Something went wrong');
      // toast.error(error.response.data.message);
      setFetchUsersLoading(false);
    }
  }, []);

  // const getAllStores = async () => {
  //   try {
  //     let token = localStorage.getItem('accessToken');
  //     const config = {
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: `Bearer ${token}`,
  //       },
  //     };
  //     const response = await merchantApi.get(`${API.STORE_LIST}?limit=100`, config);
  //     if (response?.data?.results) {
  //       setUserStores(response.data.results);
  //     }
  //   } catch (error) {}
  // };

  const getUserRolesOptions = useCallback(async () => {
    try {
      const response = await merchantApi.get(`${API.USER_ROLES}`);
      console.log(response);
      setUserRoleOption((prev) => [...prev, ...response.data.data]);
    } catch (error) {
      toast.error(error?.data?.data?.message || 'Something went wrong');
      console.log(error);
    }
  }, []);

  const handlefilter = (e) => {
    let l = userRoleOption.filter((a) => a.name === e);
    setuserroletitle(l[0].name);
    setPageChange({
      ...pageChange,
      currentPage: 0,
      userrole: e === 'Select User Role' ? '' : e,
    });
  };

  const handleSearch = (e) => {
    setPageChange({
      ...pageChange,
      search: e,
      currentPage: 0,
    });
  };

  const handleClearFilters = () => {
    setPageChange({
      ...pageChange,
      search: '',
      currentPage: 0,
      userrole: '',
    });
  };

  useEffect(() => {
    getUserRolesOptions();
    // getAllStores();
    getUsers();
  }, [getUserRolesOptions, getUsers]);

  return (
    <Box sx={{ padding: '0 24px 24px' }}>
      <Stack direction={'row'} justifyContent={'space-between'} width={'100%'} mb={2}>
        <Stack gap={2} direction={'row'}>
          <Stack sx={{ maxWidth: '25%', flex: 1 }}>
            <Form onSubmit={(e) => e.preventDefault()} className='w-100 search'>
              <Form.Group>
                <InputGroup>
                  <InputGroup.Text>
                    <img src={searchIcon} alt='search' />
                  </InputGroup.Text>
                  <Form.Control
                    onChange={(e) => {
                      handleSearch(e.target.value);
                    }}
                    type='text'
                    placeholder='Search'
                  />
                </InputGroup>
              </Form.Group>
            </Form>
          </Stack>

          <Stack sx={{ maxWidth: '25%', flex: 1 }}>
            <Dropdown as={ButtonGroup} className='w-100'>
              <Dropdown.Toggle id='dropdown-basic'>
                {userroletitle || 'Select User Role'}
                <img src={dropDown} className='dropdown-icon' style={{ marginLeft: '4px' }} alt='drop-down' />
              </Dropdown.Toggle>
              <Dropdown.Menu className='super-colors'>
                {userRoleOption.map((a, i) => (
                  <Dropdown.Item
                    as='button'
                    value={a.name}
                    onClick={() => {
                      handlefilter(a.name);
                    }}
                    key={i + 'res'}
                  >
                    {a.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>{' '}
          </Stack>
          <Stack sx={{ maxWidth: '35%', flex: 1 }}>
            <Button
              className=' w-xs-100  text-dark w-100'
              style={{
                background: 'none',
                border: 'none',
                width: '100%',
                boxShadow: 'none',
              }}
              onClick={handleClearFilters}
            >
              Reset Filters
              <img src={reload} style={{ marginLeft: '7px' }} alt='reset filter' />
            </Button>
          </Stack>
        </Stack>
        {permission?.administration?.portalUsers?.permission?.add[role] && <ButtonPrimary onClick={toggleAddUserModal}>Add New User</ButtonPrimary>}
      </Stack>

      <UserData fetchUsersLoading={fetchUsersLoading} users={users} userStores={userStores} pageChange={pageChange} />
      {showAddUserModal && (
        <AddSystemUserModal
          onClose={toggleAddUserModal}
          isOpen={showAddUserModal}
          onUserAdd={() => {
            toggleAddUserModal();
            getUsers();
          }}
        />
      )}
    </Box>
  );
};

export default SystemUser;
