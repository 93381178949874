import React, { useState, useEffect, useCallback } from 'react';
import { merchantApi } from '../../../redux/api/apiConfig';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import { ExchangeOffersTable, AddNewOfferModal, BulkEditModal } from './components';
import 'rsuite/dist/styles/rsuite-default.css';
import { Button } from '@themesberg/react-bootstrap';
import { toast } from 'react-toastify';
import dropDown from '../../../assets/img/icons/dropDown.svg';
import reload from '../../../assets/img/icons/reload.svg';
import { useDispatch } from 'react-redux';
import * as API from '../../../ApiUrl';
import { getWallets } from '../../../redux/actions/wallet';
import usePageTitle from '../../../hooks/usePageTitle';
import { Box, Stack } from '@mui/material';
import { ButtonPrimary, ButtonResetFilter } from '../../../components';
import { useSelector } from 'react-redux';

const statuses = [
  { name: 'Active', value: 'active' },
  { name: 'Expired', value: 'expired' },
];

const ExchangeOffers = () => {
  const [statusTitle, setStatusTitle] = React.useState('Select Status');
  const [offers, setOffers] = useState([]);
  const [fetchOffersLoading, setFetchOffersLoading] = useState(true);
  const [showAddOfferModal, setShowAddOfferModal] = useState(false);
  const [showBulkEditModal, setShowBulkEditModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [offerToEdit, setOfferToEdit] = useState({});
  const [paginateTable, setPaginateTable] = useState({
    currentPage: 0,
    pageSize: 7,
    search: '',
    status: '',
  });

  const dispatch = useDispatch();
  const role = useSelector((state) => state.profileReducer?.user?.role?.key);
  const permission = useSelector((state) => state.profileReducer?.permission);
  usePageTitle('Exchange Offers', 'View your current exchange offers');

  useEffect(() => {
    console.log(offerToEdit);
  }, [offerToEdit]);

  const handleStatusChange = (status) => {
    setPaginateTable({
      ...paginateTable,
      currentPage: 0,
      status,
    });
  };

  const toggleAddNewOfferModal = () => {
    setShowAddOfferModal((prevState) => !prevState);
  };

  const deleteOffer = async (id) => {
    setFetchOffersLoading(true);
    try {
      const response = await merchantApi.delete(`${API.OFFER}${id}/`);
      console.log(response);

      getOffers();
      setFetchOffersLoading(false);
    } catch (error) {
      toast.error(error?.data?.data?.message || 'Something went wrong');
      // toast.error(error?.data?.data?.message);
      setFetchOffersLoading(false);
    }
  };

  const getOffers = useCallback(async () => {
    setFetchOffersLoading(true);
    try {
      const response = await merchantApi.get(`${API.OFFER}`, {
        params: {
          search: paginateTable.search,
          status: paginateTable.status,
        },
      });
      console.log(response);
      setOffers(response.data);
      setFetchOffersLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error?.data?.data?.message || 'Something went wrong');
      setFetchOffersLoading(false);
    }
  }, [paginateTable]);

  useEffect(() => {
    const timer = setTimeout(() => {
      getOffers();
    }, 500);
    return () => clearTimeout(timer);
  }, [paginateTable.currentPage, paginateTable.pageSize, paginateTable.search, getOffers]);

  const toggleBulkEditModal = () => {
    setShowBulkEditModal((prevState) => !prevState);
  };

  const handleClearFilters = () => {
    setStatusTitle('Select Status');
    setPaginateTable({
      pageSize: 7,
      status: '',
    });
  };

  return (
    <Box sx={{ padding: '0 24px 24px' }}>
      <Stack direction={'row'} justifyContent={'space-between'} width={'100%'} mb={2}>
        <Stack gap={2} direction={'row'} width={'100%'}>
          <Stack sx={{ maxWidth: '25%', flex: 1 }}>
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle id='dropdown-basic'>
                {statusTitle || 'Select Status'}
                <img src={dropDown} style={{ marginLeft: '4px' }} alt='drop-down' className='dropdown-icon' />
              </Dropdown.Toggle>
              <Dropdown.Menu className='super-colors'>
                {statuses?.map((a, i) => (
                  <Dropdown.Item
                    as='button'
                    value={a.value}
                    onClick={() => {
                      handleStatusChange(a.value);
                      setStatusTitle(a.name);
                    }}
                    key={i + 'res'}
                  >
                    {a.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>{' '}
          </Stack>
          <Stack sx={{ maxWidth: '25%', flex: 1 }}>
            <ButtonResetFilter onClick={handleClearFilters} />
          </Stack>
        </Stack>
        <Stack gap={2} direction={'row'} width={'100%'} justifyContent={'flex-end'}>
          {permission?.configurations?.exchangeOffers?.permission?.add?.[role] && (
            <ButtonPrimary onClick={toggleAddNewOfferModal}>New Currency</ButtonPrimary>
          )}
          {permission?.configurations?.exchangeOffers?.permission?.bulkEdit?.[role] && (
            <ButtonPrimary onClick={toggleBulkEditModal}>Bulk Edit</ButtonPrimary>
          )}
        </Stack>
      </Stack>

      <ExchangeOffersTable
        fetchOffersLoading={fetchOffersLoading}
        offers={offers}
        paginateTable={paginateTable}
        setPaginateTable={setPaginateTable}
        setOfferToEdit={setOfferToEdit}
        setIsEdit={setIsEdit}
        toggleAddNewOfferModal={toggleAddNewOfferModal}
        deleteOffer={deleteOffer}
      />
      {showAddOfferModal && (
        <AddNewOfferModal
          onClose={toggleAddNewOfferModal}
          isOpen={showAddOfferModal}
          isEdit={isEdit}
          offerToEdit={offerToEdit}
          offers={offers}
          // This is to trigger re-render after adding user to fetch new list
          onSubmit={() => {
            getOffers();
            setIsEdit(false);
            setOfferToEdit({});
            toggleAddNewOfferModal();
            dispatch(getWallets());
          }}
          side={'inward'}
        />
      )}
      {showBulkEditModal && (
        <BulkEditModal
          onClose={toggleBulkEditModal}
          isOpen={showBulkEditModal}
          isEdit={isEdit}
          offers={offers}
          // This is to trigger re-render after adding user to fetch new list
          onSubmit={() => {
            toggleBulkEditModal();
            getOffers();
          }}
          side={'inward'}
        />
      )}
    </Box>
  );
};

export default ExchangeOffers;
