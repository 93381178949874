import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import * as API from '../../../ApiUrl';
import { ButtonPrimary } from '../../../components';
import { LiquidityModal } from '../../../components/liquidityModal';
import { merchantApi } from '../../../redux/api/apiConfig';
import { Box, Stack } from '@mui/material';
import { LiquidityTable } from './components';
import usePageTitle from '../../../hooks/usePageTitle';

function Liquidity() {
  const [liquidityList, setLiquidityList] = useState([]);
  const [fetchLiquidityLoading, setFetchLiquidityLoading] = useState(false);
  const [paginateTable, setpaginateTable] = useState({
    currentPage: 0,
    pageSize: 7,
    search: '',
    type: '',
    coin: '',
    store: '',
    status: 'processed',
    fromDate: '',
    toDate: '',
  });
  const [showLiquidityModal, setShowLiquidityModal] = useState(false);
  const role = useSelector((state) => state.profileReducer?.user?.role?.key);
  const permission = useSelector((state) => state.profileReducer?.permission);

  usePageTitle('Liquidity', 'Manage your liquidity');

  const getLiquidity = useCallback(async () => {
    setFetchLiquidityLoading(true);
    try {
      const response = await merchantApi.get(`${API.LIQUIDITY}`, {
        params: {
          offset: paginateTable.currentPage * paginateTable.pageSize,
          limit: paginateTable.pageSize,
          // search: paginateTable.search,
          // type: paginateTable.type,
          // coin: paginateTable.coin,

          // store: paginateTable.store,
          status: paginateTable.status,
          // modified_date_after: paginateTable.fromDate,
          // modified_date_before: paginateTable.toDate,
        },
      });

      setFetchLiquidityLoading(false);
      console.log(response);
      if (response.data) {
        setLiquidityList(response.data);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.data?.data?.message || 'Something went wrong');
      setFetchLiquidityLoading(false);
    }
  }, [paginateTable]);

  useEffect(() => {
    getLiquidity();
  }, [
    paginateTable.currentPage,
    paginateTable.pageSize,
    paginateTable.search,
    paginateTable.type,
    paginateTable.coin,
    paginateTable.store,
    paginateTable.status,
    paginateTable.fromDate,
    paginateTable.toDate,
    getLiquidity,
  ]);

  const toggleAddLiquidity = () => {
    setShowLiquidityModal((state) => !state);
    if (showLiquidityModal) {
      getLiquidity();
    }
  };

  return (
    <>
      {showLiquidityModal && <LiquidityModal isOpen={showLiquidityModal} onClose={toggleAddLiquidity} />}

      <Box sx={{ padding: '0 24px 24px' }}>
        {permission?.accounts?.liquidity?.permission?.addLiquidity[role] && (
          <Stack direction={'row'} gap={2} sx={{ justifyContent: 'flex-end', alignItems: 'center' }} mb={2}>
            <ButtonPrimary onClick={toggleAddLiquidity}>Add Liquidity</ButtonPrimary>
          </Stack>
        )}
        <LiquidityTable
          liquidityList={liquidityList}
          fetchLiquidityLoading={fetchLiquidityLoading}
          setpaginateTable={setpaginateTable}
          paginateTable={paginateTable}
        />
      </Box>
    </>
  );
}

export default Liquidity;
