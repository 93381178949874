import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { merchantApi } from '../redux/api/apiConfig';
import { FINANCIAL_OPTIONS } from '../ApiUrl';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Button } from 'rsuite';
import { DialogActions, DialogContent, Stack, MenuItem } from '@mui/material';
import { useEffect } from 'react';
import TextFieldComponent from './TextFieldComponent';
import TextFieldSelect from './TextFieldSelect';
import SplitInputField from './SplitInputField';
import { toast } from 'react-toastify';

const FinancialDetails = ({ onClose, handleBack, financialDetails, handleNext, isEdit, setFinancialDetails, loading = false }) => {
  const [financialInstruments, setFinancialInstruments] = useState([]);
  const [yupObject, setYupObject] = useState({
    type: yup.string('Select finanicial instrument').required('Please select financial instrument.'),
    name: yup.string('Enter name').min(2, 'Please enter valid name'),
    // .required('Name is required!'),
    venmo_id: yup.string('Enter Venmo ID').min(3, 'Please enter valid Venmo ID'),

    email: yup.string('Enter your email').email('Enter a valid email'),
    account_no: yup.string('Enter account number').min(2, 'Please enter valid account number'),
    // .required('Account Number is required!'),

    iban: yup.string('Enter IBAN'),
    // .min(10, 'Enter Valid IBAN'),
    // .required('Phone Number is required'),

    swift_code: yup.string('Enter SWIFT BIC code'),
    // .min(10, 'Enter Valid IBAN'),
    // .required('Country is required'),
    branch: yup
      .string()
      .length(5, 'Must be exactly 5 digits')
      .matches(/^[0-9]+$/, 'Must be only digits'),

    transit_number: yup
      .string()
      .length(3, 'Must be exactly 3 digits')
      .matches(/^[0-9]+$/, 'Must be only digits'),

    lei_code: yup.string('Enter LEI code'),
    aba_number: yup.string('Enter ABA routing number'),

    ifsc_code: yup.string('Enter IFSC code'),

    address: yup.string('Enter address'),
    // .required('City is required'),
    // status: yup.string('Enter address'),
    // .required('Address is required'),
  });
  const [validationSchema, setValidationSchema] = useState(yup.object(yupObject));
  const formik = useFormik({
    initialValues: { ...financialDetails, ...financialDetails?.bank },
    validateOnBlur: true,
    validationSchema: validationSchema,
  });
  console.log(financialDetails);

  const getFinancialInstruments = async () => {
    try {
      const response = await merchantApi.get(FINANCIAL_OPTIONS);
      setFinancialInstruments(response.data.data);
    } catch (err) {
      toast.error(err?.data?.data?.message || 'Something went wrong');
      console.log(err);
    }
  };

  useEffect(() => {
    getFinancialInstruments();
    console.log(isEdit);
    console.log(financialDetails);
    if (financialDetails) {
      let type = financialDetails.type;
      let newYupObject = { ...yupObject };
      if (type === 'venmo') {
        newYupObject = {
          type: yup.string('Select finanicial instrument').required('Please select financial instrument.'),
          venmo_id: yup.string('Enter Venmo ID').min(3, 'Please enter valid Venmo ID'),
        };
      } else if (type === 'paypal') {
        newYupObject = {
          type: yup.string('Select finanicial instrument').required('Please select financial instrument.'),

          email: yup.string('Enter your email').email('Enter a valid email'),
        };
      } else if (type === 'bank') {
        newYupObject = {
          type: yup.string('Select finanicial instrument').required('Please select financial instrument.'),
          name: yup.string('Enter name').min(2, 'Please enter valid name'),

          account_no: yup.string('Enter account number').min(2, 'Please enter valid account number'),

          iban: yup.string('Enter IBAN'),

          swift_code: yup.string('Enter SWIFT BIC code'),
          branch: yup
            .string()
            .matches(/^[0-9]+$/, 'Must be only digits')
            .min(5, 'Must be exactly 5 digits')
            .max(5, 'Must be exactly 5 digits'),

          transit_number: yup
            .string()
            .matches(/^[0-9]+$/, 'Must be only digits')
            .min(3, 'Must be exactly 3 digits')
            .max(3, 'Must be exactly 3 digits'),
          lei_code: yup.string('Enter LEI code'),
          aba_number: yup.string('Enter ABA routing number'),

          ifsc_code: yup.string('Enter IFSC code'),

          address: yup.string('Enter address'),
        };
      }
      setYupObject(newYupObject);
      setValidationSchema(yup.object(newYupObject));
    }
  }, []);

  const onFinancialInstrumentChange = (event) => {
    // formik.handleChange(event);
    const type = event.target.value;
    let newYupObject = { ...yupObject };
    if (type === 'venmo') {
      newYupObject = {
        type: yup.string('Select finanicial instrument').required('Please select financial instrument.'),
        venmo_id: yup.string('Enter Venmo ID').min(3, 'Please enter valid Venmo ID'),
      };
    } else if (type === 'paypal') {
      newYupObject = {
        type: yup.string('Select finanicial instrument').required('Please select financial instrument.'),

        email: yup.string('Enter your email').email('Enter a valid email'),
      };
    } else if (type === 'bank') {
      newYupObject = {
        type: yup.string('Select finanicial instrument').required('Please select financial instrument.'),
        name: yup.string('Enter name').min(2, 'Please enter valid name'),

        account_no: yup.string('Enter account number').min(2, 'Please enter valid account number'),

        iban: yup.string('Enter IBAN'),

        swift_code: yup.string('Enter SWIFT BIC code'),
        branch: yup
          .string()
          .matches(/^[0-9]+$/, 'Must be only digits')
          .min(5, 'Must be exactly 5 digits')
          .max(5, 'Must be exactly 5 digits'),

        transit_number: yup
          .string()
          .matches(/^[0-9]+$/, 'Must be only digits')
          .min(3, 'Must be exactly 3 digits')
          .max(3, 'Must be exactly 3 digits'),
        lei_code: yup.string('Enter LEI code'),
        aba_number: yup.string('Enter ABA routing number'),

        ifsc_code: yup.string('Enter IFSC code'),

        address: yup.string('Enter address'),
      };
    }
    setYupObject(newYupObject);
    setValidationSchema(yup.object(newYupObject));
  };

  const nextClickHandler = (status) => {
    const { type, email, venmo_id, name, account_no, iban, swift_code, branch, transit_number, lei_code, aba_number, ifsc_code, address } =
      formik.values;
    let data = {
      type,
      status,
      email,
      venmo_id,
      bank: {
        name,
        account_no,
        branch,
        lei_code,
        swift_code,
        iban,
        aba_number,
        transit_number,
        ifsc_code,
        address,
      },
    };
    if (type !== 'bank') {
      delete data.bank;
    }
    if (type !== 'venmo') {
      delete data.venmo_id;
    }
    if (type !== 'paypal') {
      delete data.email;
    }
    handleNext(data);
  };

  const backClickHandler = () => {
    saveCurrentInfo();
    handleBack();
  };

  const saveCurrentInfo = () => {
    console.log(formik.values);
    setFinancialDetails({ ...formik.values });
    // handleNext();
  };

  console.log(formik);
  console.log(formik.values);
  return (
    <>
      <DialogContent sx={{ paddingTop: '20px !important' }}>
        <Box
          sx={{
            display: 'grid',
            columnGap: '16px',
            rowGap: '12px',
            marginBottom: '12px',
            gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
          }}
        >
          <TextFieldSelect
            formik={formik}
            name={'type'}
            label={'Type'}
            options={financialInstruments}
            onChange={(event) => {
              onFinancialInstrumentChange(event);
            }}
            render={(financialInstrument) => <MenuItem value={financialInstrument.value}>{financialInstrument.name}</MenuItem>}
          />

          {formik.values.type === 'bank' && (
            <>
              <TextFieldComponent formik={formik} name={'name'} type={'text'} label={'Bank Name'} />

              <TextFieldComponent formik={formik} name={'account_no'} type={'text'} label={'Account No.'} />

              <TextFieldComponent formik={formik} name={'iban'} type={'text'} label={'IBAN'} />

              <TextFieldComponent formik={formik} name={'swift_code'} type={'text'} label={'SWIFT BIC Code'} />

              {/* <TextFieldComponent
                formik={formik}
                name={'branch'}
                type={'text'}
                label={'Branch'}
              /> */}
              <SplitInputField
                formik={formik}
                titleLeft='Branch No.'
                nameLeft='branch'
                typeLeft='text'
                basisLeft={'60%'}
                titleRight='Transit No.'
                nameRight='transit_number'
                typeRight='text'
                basisRight={'40%'}
              />

              <TextFieldComponent formik={formik} name={'aba_number'} type={'text'} label={'ABA Routing No.'} />
              <TextFieldComponent formik={formik} name={'ifsc_code'} type={'text'} label={'IFSC Code'} />
              <TextFieldComponent formik={formik} name={'lei_code'} type={'text'} label={'LEI code'} />
            </>
          )}

          {formik.values.type === 'venmo' && <TextFieldComponent formik={formik} name={'venmo_id'} type={'text'} label={'Venmo ID'} />}

          {formik.values.type === 'paypal' && <TextFieldComponent formik={formik} name={'email'} type={'email'} label={'PayPal Email'} />}
        </Box>
        {formik.values.type === 'bank' && (
          <TextFieldComponent formik={formik} name={'address'} type={'text'} label={'Bank Address'} multiline minRows={4} maxRows={4} />
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Stack>
          <Button className='btn white-btn action-button ' onClick={onClose} appearance='subtle'>
            Cancel
          </Button>
        </Stack>
        <Stack direction={'row'}>
          <Button className='btn white-btn action-button basis-30' onClick={backClickHandler} appearance='subtle'>
            Back
          </Button>
          <Button
            className={`btn action-button primary_color text-white  ${!formik.isValid ? 'disabled' : null}`}
            appearance='primary'
            loading={loading}
            onClick={() => nextClickHandler('draft')}
            // disabled={!formik.isValid}
          >
            Save as Draft
          </Button>
          <Button
            className={`btn action-button primary_color text-white  ${!formik.isValid ? 'disabled' : null}`}
            appearance='primary'
            loading={loading}
            onClick={() => nextClickHandler('active')}
            disabled={!formik.isValid}
          >
            Save and Activate
          </Button>
        </Stack>
      </DialogActions>
    </>
  );
};

export default FinancialDetails;
