import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { RemittanceProvider } from './context/remittance/remittanceContext';
import store from './redux/store';
// core styles
import './scss/volt.scss';

// vendor styles

import HomePage from './pages/HomePage';
import ScrollToTop from './components/ScrollToTop';
import App from './App';

if (process.env.NODE_ENV === 'production') {
//   console.log = () => {};
//   console.error = () => {};
//   console.debug = () => {};
}

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <RemittanceProvider>
        <App />
      </RemittanceProvider>
      {/* <ScrollToTop /> */}
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
);
serviceWorker.unregister();
