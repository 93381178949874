import * as actionTypes from '../actionTypes';
import { updateObject } from './utility';

const initialState = {
  loading: false,
  status: {},
  data: {},
  error: null,
  type: null,
};

const updateProfileFail = (action, state) => {
  return updateObject(state, {
    error: 'error',
  });
};
const kybformdata = (action, state) => {
  return updateObject(state, {
    data: action.payload,
  });
};
const updateStart = (action, state) => {
  return updateObject(state, {
    loading: true,
  });
};

const setUpdateprofile = (action, state) => {
  return updateObject(state, {
    status: action.payload,
    loading: false,
  });
};

const kybReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_KYB_STATUS:
      return updateStart(action, state);
    case actionTypes.SET_KYB_STATUS:
      // console.log(action);
      // localStorage.setItem("user", JSON.stringify(action.payload));
      return setUpdateprofile(action, state);
    case actionTypes.GET_KYB_STATUS_FAIL:
      return updateProfileFail(action, state);
    case actionTypes.SET_KYB_FORM_DATA:
      return kybformdata(action, state);

    default:
      return state;
  }
};

export default kybReducer;
