import PropTypes from 'prop-types';
import React from 'react';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { CardActionArea, Radio, FormControlLabel, Stack, Box } from '@mui/material';

// ----------------------------------------------------------------------

export const StyledWrap = styled(Box)(({ theme }) => ({
  gap: 15,
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: 'repeat(1, 1fr)',
  },
}));

// ----------------------------------------------------------------------

export const StyledCard = styled(CardActionArea, {
  shouldForwardProp: (prop) => prop !== 'selected',
})(({ selected, theme }) => ({
  height: 72,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: '0px 12px',
  backgroundColor: theme.palette.background.default,
  //   flexDirection: "column",
  color: theme.palette.text.disabled,
  borderRadius: theme.shape.borderRadius,
  border: `solid 1px #BABABA`,
  '& .svg-color': {
    width: 30,
    height: 30,
  },
  ...(selected && {
    color: '#231F3A',
    // boxShadow: theme.customShadows.z12,
    border: `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`,
    borderColor: '#231F3A',
  }),
}));

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

// MaskControl.propTypes = {
//   value: PropTypes.string,
// };

export function MaskControl({ value }) {
  return (
    <FormControlLabel
      label=''
      value={value}
      // onClick={(e) => console.log(e.target.value)}
      control={<Radio sx={{ display: 'none' }} />}
      sx={{
        m: 0,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        position: 'absolute',
      }}
    />
  );
}

// ----------------------------------------------------------------------
