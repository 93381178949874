import React, { useMemo, useState } from 'react';
import { startCase } from 'lodash';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import { DataGrid } from '@mui/x-data-grid';
import { ButtonGhost, Label } from '../../../../../components';
import { formatCurrency, rowsPerPageOptions, tableCellStyle, tableStyle } from '../../../../../utils/common';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Stack, Typography, LinearProgress, Tooltip, Avatar } from '@mui/material';

import createAvatar from '../../../../../utils/createAvatar';
import { useCallback } from 'react';
import { format } from 'date-fns';
import { Routes } from '../../../../../routes';
import { useHistory } from 'react-router-dom';
import { UPDATE_DEPOSIT } from '../../../../../redux/actionTypes';

const DepositsTable = ({ deposits, fetchUsersLoading, updateStatus }) => {
  const role = useSelector((state) => state?.profileReducer?.user?.role?.key);
  const permission = useSelector((state) => state?.profileReducer?.permission);
  const filters = useSelector((state) => state.filters.clients.sender.deposit);

  const dispatch = useDispatch();
  const history = useHistory();
  const currency = useSelector((state) => state?.profileReducer?.user?.mto?.currency);
  const NoRowsOverlay = () => {
    return (
      <Stack height='100%' alignItems='center' justifyContent='center'>
        {filters.search === '' ? 'No Deposits Created' : 'No Deposits Found'}
      </Stack>
    );
  };

  const createDataRow = useCallback(
    (deposit) => {
      return {
        id: deposit.id,
        date: deposit.created_date,
        name: startCase(deposit?.sender?.name || '--'),
        xrpl_tag: deposit?.xrpl_tag,
        amount: formatCurrency(deposit?.amount, currency?.decimal_place, currency?.symbol, currency?.symbol_prefix),
        method: startCase(deposit?.deposit_mode || '--'),
        status: deposit?.status,
        actions: deposit,
      };
    },
    [currency]
  );

  const rows = useMemo(
    () => (deposits?.results?.length > 0 ? deposits?.results?.map((deposit) => createDataRow(deposit)) : []),
    [deposits.results, createDataRow]
  );

  const columns = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        // flex: 1,
        minWidth: 80,
        // maxWidth: 80,
        headerClassName: 'header',
        align: 'center',
        headerAlign: 'center',
        renderCell: (params) => {
          return (
            <Typography variant='body2' style={tableCellStyle}>
              {params.formattedValue}
            </Typography>
          );
        },
      },
      {
        field: 'date',
        headerName: 'Requested Date',
        minWidth: 200,
        headerClassName: 'header',
        align: 'center',
        headerAlign: 'center',
        renderCell: (params) => {
          return (
            <Typography variant='body2' style={tableCellStyle}>
              {format(new Date(params.formattedValue), 'dd/MM/yyyy HH:mm a')}
            </Typography>
          );
        },
      },
      {
        field: 'name',
        headerName: 'Name',
        sortable: false,
        flex: 1,
        minWidth: 300,
        // maxWidth: 250,
        headerClassName: 'header',
        renderCell: (params) => {
          return (
            <Stack direction={'row'} sx={{ minWidth: 0, alignItems: 'center' }}>
              <Avatar
                alt={params.formattedValue}
                sx={{
                  mr: 2,
                  bgcolor: createAvatar(params.formattedValue).color,
                }}
              >
                {createAvatar(params.formattedValue).name}
              </Avatar>
              <Typography
                variant='body2'
                sx={{
                  color: 'black',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontWeight: '500',
                }}
              >
                {params.formattedValue}
              </Typography>
            </Stack>
          );
        },
        // align: 'center',
        // headerAlign: 'center',
      },
      {
        field: 'xrpl_tag',
        headerName: 'Xrpl Tag',
        sortable: false,
        flex: 1,
        minWidth: 140,
        headerClassName: 'header',
        renderCell: (params) => {
          return (
            <Typography variant='body2' sx={tableCellStyle}>
              {params.formattedValue}
            </Typography>
          );
        },
      },
      {
        field: 'amount',
        headerName: 'Amount',
        sortable: false,
        flex: 1,
        minWidth: 140,
        headerClassName: 'header',
        renderCell: (params) => {
          return (
            <Typography variant='body2' sx={tableCellStyle}>
              {params.formattedValue}
            </Typography>
          );
        },
      },
      {
        field: 'method',
        headerName: 'Deposit Method',
        sortable: false,
        flex: 1,
        minWidth: 140,
        headerClassName: 'header',
        renderCell: (params) => {
          return (
            <Typography variant='body2' sx={tableCellStyle}>
              {params.formattedValue}
            </Typography>
          );
        },
      },

      {
        field: 'status',
        headerName: 'Status',
        sortable: false,
        // flex: 1,
        minWidth: 150,
        alignItems: 'center',
        headerClassName: 'header',
        renderCell: (params) => {
          const { value, receiver_name } = params.formattedValue;
          switch (value) {
            case 'query':
              return (
                <Label variant={'ghost'} color={'application submitted'} sx={{ textTransform: 'capitalize' }}>
                  {receiver_name}
                </Label>
              );
            case 'submitted':
              return (
                <Label variant={'ghost'} color={'pending'} sx={{ textTransform: 'capitalize' }}>
                  {receiver_name}
                </Label>
              );

            case 'confirmed':
              return (
                <Label variant={'ghost'} color={'complete'} sx={{ textTransform: 'capitalize' }}>
                  {receiver_name}
                </Label>
              );
            case 're_evalution':
              return (
                <Label variant={'ghost'} color={'application submitted'} sx={{ textTransform: 'capitalize' }}>
                  {receiver_name}
                </Label>
              );

            default:
              return (
                <Label variant={'ghost'} color={'pending'} sx={{ textTransform: 'capitalize' }}>
                  {receiver_name || '--'}
                </Label>
              );
          }
        },
        // align: 'center',
      },

      {
        field: 'actions',
        headerName: 'Update Status',
        sortable: false,
        flex: 1,
        minWidth: 150,
        // maxWidth: 140,
        headerClassName: 'header',
        renderCell: (params) => {
          return (
            <ButtonGhost
              appearance='ghost'
              size='xs'
              disabled={
                params?.row?.actions?.status?.receiver_can_change_to?.length === 0 ||
                params?.row?.actions?.status === null ||
                !permission?.clients?.senders?.deposits?.permission?.change[role]
              }
              onClick={(e) => {
                e.stopPropagation();
                updateStatus(params.formattedValue);
              }}
            >
              Update Status
            </ButtonGhost>
          );
        },
      },
    ],
    []
  );

  const handleChangePage = useCallback(
    (newPage) => {
      dispatch({ type: UPDATE_DEPOSIT, payload: { currentPage: newPage } });
    },
    [dispatch]
  );

  const handleChangeRowsPerPage = useCallback(
    (newSize) => {
      dispatch({
        type: UPDATE_DEPOSIT,
        payload: { currentPage: 0, pageSize: newSize },
      });
    },
    [dispatch]
  );

  return (
    <>
      <Box
        sx={{
          height: 615,
          width: 1,
          '& .MuiDataGrid-virtualScrollerRenderZone': {
            '& .MuiDataGrid-row': {
              // backgroundColor: 'rgba(235, 235, 235, .7)',
              fontWeight: 500,
              color: '#667085',
              fontFamily: 'Inter, sans-serif',
              fontSize: '14px',
            },
          },
          '& .header': {
            backgroundColor: '#F4F4F4',
            '& div': {
              '& div': {
                '& div': {
                  '& div': {
                    fontSize: '14px',
                    fontWeight: 600,
                    fontFamily: 'Inter, sans-serif',
                  },
                },
              },
            },
          },
        }}
      >
        <DataGrid
          autoHeight
          sx={{ ...tableStyle, cursor: 'pointer' }}
          onRowClick={(row) => {
            history.push(Routes.Deposits.path + `/${row?.id}`);
          }}
          rows={rows}
          columns={columns}
          rowHeight={72}
          disableColumnFilter={true}
          disableColumnMenu={true}
          pagination
          paginationMode='server'
          rowsPerPageOptions={rowsPerPageOptions}
          loading={fetchUsersLoading}
          pageSize={filters.pageSize}
          page={filters.currentPage}
          rowCount={deposits.count}
          onPageChange={handleChangePage}
          onPageSizeChange={handleChangeRowsPerPage}
          components={{ NoRowsOverlay, LoadingOverlay: LinearProgress }}
        />
      </Box>
    </>
  );
};

export default DepositsTable;
