import axios from 'axios';
import * as API from '../../ApiUrl';
import { GET_PROFILE, GET_KYB_STATUS, SET_KYB_STATUS, SET_KYB_FORM_DATA } from '../actionTypes';
import { merchantApi } from '../api/apiConfig';

const getStatus = () => async (dispatch) => {
  dispatch({ type: GET_KYB_STATUS });

  const token = localStorage.getItem('accessToken');
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const res = await merchantApi.get(`${API.KYB_STATUS}`, config);
    console.log(res);
    dispatch({
      type: SET_KYB_STATUS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err, 'errr');
    // dispatch({
    //   type: GET_PROFILE_FAIL,
    // });
  }
};

const kybStart = (d) => async (dispatch) => {
  // dispatch({ type: GET_PROFILE });
  const body = { attempt_id: d.attempt_id, KybStatuses: 'not done' };
  const token = localStorage.getItem('accessToken');
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const res = await merchantApi.get(`${API.KYB_START}`, body, config);
    //   dispatch({
    //     type: SET_PROFILE,
    //     payload: res.data,
    //   });
  } catch (err) {
    console.log(err, 'errr');
    //   dispatch({
    //     type: GET_PROFILE_FAIL,
    //   });
  }
};

const uploadDocument = (d) => async (dispatch) => {
  const token = localStorage.getItem('accessToken');
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const res = await merchantApi.patch(`${API.UPLOAD_KYB_DOC}`, config);
    //   dispatch({
    //     type: SET_PROFILE,
    //     payload: res.data,
    //   });
  } catch (err) {
    console.log(err, 'errr');
    //   dispatch({
    //     type: GET_PROFILE_FAIL,
    //   });
  }
};

const setkybformdata = (d) => (dispatch) => {
  dispatch({ type: SET_KYB_FORM_DATA, payload: d });
};

export { getStatus, kybStart, setkybformdata, uploadDocument };
