import React from 'react';
import ReactFlagsSelect from 'react-flags-select';
import _ from 'lodash';
import 'react-phone-input-2/lib/style.css';
import { Stack, Typography } from '@mui/material';

const CountrySelect = ({ formik, label, name, options, countryList, onChange, disabled = false }) => {
  return (
    <Stack>
      <Typography>{label}</Typography>
      <ReactFlagsSelect
        searchable
        disabled={disabled}
        countries={options}
        className='bg-white material-inp'
        style={{ lineHeight: '2rem' }}
        selected={formik.values[`${name}`]}
        onSelect={(code) => {
          let countryId = _.find(countryList, {
            code2: code,
          }).id;
          onChange(countryId);

          let e = {
            target: {
              name: name,
              value: code,
            },
          };
          formik.handleChange(e);
        }}
      />
    </Stack>
  );
};

export default CountrySelect;
