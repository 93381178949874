import React, { useState } from 'react';
import { TextField, Stack, Typography } from '@mui/material';

import { InputAdornment, IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
const TextFieldPassword = ({ formik, name, label, required = true }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Stack spacing={0} width={'100%'}>
      <Typography>
        {label} {required ? '*' : ''}
      </Typography>
      <TextField
        fullWidth
        variant='outlined'
        name={name}
        id={name}
        type={showPassword ? 'text' : 'password'}
        value={formik?.values[`${name}`]}
        onChange={formik?.handleChange}
        onBlur={formik?.handleBlur}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle passphrase visibility'
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
                edge='end'
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        error={formik?.touched[`${name}`] && Boolean(formik?.errors[`${name}`])}
        helperText={formik?.touched[`${name}`] && formik?.errors[`${name}`]}
      />
    </Stack>
  );
};

export default TextFieldPassword;
