import React from 'react';

const Divider = ({ orientation }) => {
  const styles =
    orientation === 'vertical'
      ? {
          width: '0.0625rem',
        }
      : { height: '0.0625rem' };
  return <hr style={styles} />;
};

export default Divider;
