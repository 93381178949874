import React from 'react';
import { format } from 'date-fns';
import { startCase, toLower } from 'lodash';
import { Box, Stack, Typography, LinearProgress, Avatar, Tooltip } from '@mui/material';
import { formatWithoutTimezone, rowsPerPageOptions, transactionStatusColors } from '../../../../utils/common';

import { DataGrid } from '@mui/x-data-grid';
import { formatCurrency, tableCellStyle } from '../../../../utils/common';
import createAvatar from '../../../../utils/createAvatar';
import { Label } from '../../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_OUT_REM_REPORT } from '../../../../redux/actionTypes';

export default function OutwardRemittanceTable({ translist, fetchTranslistLoading }) {
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.filters.reports.outRemReport);

  const handleChangePage = (newPage) => {
    dispatch({
      type: UPDATE_OUT_REM_REPORT,
      payload: {
        currentPage: newPage,
      },
    });
  };

  const handleChangeRowsPerPage = (newSize) => {
    dispatch({
      type: UPDATE_OUT_REM_REPORT,
      payload: {
        currentPage: 0,
        pageSize: newSize,
      },
    });
  };

  const handleSortModelChange = (sort) => {
    dispatch({
      type: UPDATE_OUT_REM_REPORT,
      payload: {
        sortModel: sort,
      },
    });
  };

  const createDataRow = (transaction) => {
    const type = transaction.type;
    return {
      id: transaction.ref_number,
      // type: capital(transaction?.type) || 'P2P',
      created_date: formatWithoutTimezone(transaction.created_date, 'dd MMM yyyy HH:mm a'),

      senderMto: (() => {
        return startCase(toLower(transaction.sender_mto));
      })(),

      sender: (() => {
        const senderType = type.split('2')[0];
        if (senderType === 'p') {
          const [first_name, last_name] = transaction.sender.split(' ');
          return startCase(toLower(`${first_name} ${last_name}`));
        } else {
          return startCase(toLower(transaction.sender));
        }
      })(),

      receiverMto: (() => {
        return startCase(toLower(transaction.receipient_mto));
      })(),

      receiver: (() => {
        const receiverType = type.split('2')[1];
        if (receiverType === 'p') {
          const [first_name, last_name] = transaction.receiver.split(' ');
          return startCase(toLower(`${first_name} ${last_name}`));
        } else {
          return startCase(toLower(transaction.receiver));
        }
      })(),
      customer_paid_amount: (() => {
        return formatCurrency(
          Number(transaction.customer_paid_amount),
          transaction?.send_currency?.decimal_place,
          transaction?.send_currency?.symbol,
          transaction?.send_currency?.symbol_prefix
        );
      })(),
      sender_commission: (() => {
        return formatCurrency(
          transaction.sender_commission,
          transaction?.send_currency?.decimal_place,
          transaction?.send_currency?.symbol,
          transaction?.send_currency?.symbol_prefix
        );
      })(),
      receipient_mto_amount: (() => {
        return formatCurrency(
          transaction.receipient_mto_amount,
          transaction?.send_currency?.decimal_place,
          transaction?.send_currency?.symbol,
          transaction?.send_currency?.symbol_prefix
        );
      })(),
      send_amount: (() => {
        return formatCurrency(
          transaction.send_amount,
          transaction?.send_currency?.decimal_place,
          transaction?.send_currency?.symbol,
          transaction?.send_currency?.symbol_prefix
        );
      })(),
      receive_amount: (() => {
        return formatCurrency(
          transaction.receive_amount,
          transaction?.receive_currency?.decimal_place,
          transaction?.receive_currency?.symbol,
          transaction?.receive_currency?.symbol_prefix
        );
      })(),
      rate: (() => {
        return formatCurrency(
          transaction.exchange_rate,
          transaction?.receive_currency?.decimal_place,
          transaction?.receive_currency?.symbol,
          transaction?.receive_currency?.symbol_prefix
        );
      })(),
      status: transaction?.status,
      remarks: transaction?.remarks,
      details: transaction,
    };
  };

  const rows = translist?.results?.length > 0 ? translist.results.map((transaction) => createDataRow(transaction)) : [];

  const columns = [
    {
      field: 'id',
      headerName: 'Reference Number',
      flex: 1,
      minWidth: 150,
      // maxWidth: 200,
      headerClassName: 'header',
      align: 'left',
      headerAlign: 'center',
      sortable: false,
      renderCell: (params) => {
        return (
          <Tooltip placement='bottom' sx={{ cursor: 'default' }} title={params.formattedValue}>
            <Typography variant='body2' style={tableCellStyle}>
              {params.formattedValue}
            </Typography>
          </Tooltip>
        );
      },
    },

    {
      field: 'created_date',
      headerName: 'Date',
      flex: 1,
      minWidth: 150,
      // maxWidth: 200,
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Typography variant='body2' style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },

    {
      field: 'senderMto',
      headerName: 'Sender MTO',
      flex: 1.5,
      minWidth: 200,
      // maxWidth: 200,
      headerClassName: 'header',
      sortable: false,
      renderCell: (params) => {
        return (
          <Stack direction={'row'} sx={{ minWidth: 0, alignItems: 'center' }}>
            <Avatar alt={params.formattedValue} sx={{ mr: 1, bgcolor: createAvatar(params.formattedValue).color }}>
              {createAvatar(params.formattedValue).name}
            </Avatar>
            <Typography
              variant='body2'
              sx={{
                color: 'black',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontWeight: '500',
              }}
            >
              {params.formattedValue}
            </Typography>
          </Stack>
        );
      },
    },

    {
      field: 'sender',
      headerName: 'Sender',
      flex: 1.5,
      minWidth: 200,
      // maxWidth: 200,
      textOverflow: 'ellipsis',
      headerClassName: 'header',
      sortable: false,
      renderCell: (params) => {
        return (
          <Stack direction={'row'} sx={{ minWidth: 0, alignItems: 'center' }}>
            <Avatar alt={params.formattedValue} sx={{ mr: 1, bgcolor: createAvatar(params.formattedValue).color }}>
              {createAvatar(params.formattedValue).name}
            </Avatar>
            <Typography
              variant='body2'
              sx={{
                color: 'black',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontWeight: '500',
              }}
            >
              {params.formattedValue}
            </Typography>
          </Stack>
        );
      },
    },

    {
      field: 'receiverMto',
      headerName: 'Recipient MTO',
      flex: 1.5,
      minWidth: 200,
      // maxWidth: 200,
      headerClassName: 'header',
      sortable: false,
      renderCell: (params) => {
        return (
          <Stack direction={'row'} sx={{ minWidth: 0, alignItems: 'center' }}>
            <Avatar alt={params.formattedValue} sx={{ mr: 1, bgcolor: createAvatar(params.formattedValue).color }}>
              {createAvatar(params.formattedValue).name}
            </Avatar>
            <Typography
              variant='body2'
              sx={{
                color: 'black',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontWeight: '500',
              }}
            >
              {params.formattedValue}
            </Typography>
          </Stack>
        );
      },
    },

    {
      field: 'receiver',
      headerName: 'End Beneficiary',
      flex: 1.5,
      minWidth: 200,
      // maxWidth: 200,
      headerClassName: 'header',
      sortable: false,
      renderCell: (params) => {
        return (
          <Stack direction={'row'} sx={{ minWidth: 0, alignItems: 'center' }}>
            <Avatar alt={params.formattedValue} sx={{ mr: 1, bgcolor: createAvatar(params.formattedValue).color }}>
              {createAvatar(params.formattedValue).name}
            </Avatar>
            <Typography
              variant='body2'
              sx={{
                color: 'black',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontWeight: '500',
              }}
            >
              {params.formattedValue}
            </Typography>
          </Stack>
        );
      },
    },

    {
      field: 'customer_paid_amount',
      headerName: 'Deposit Amount',
      flex: 1,
      minWidth: 150,
      // maxWidth: 200,
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Typography variant='body2' style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
    {
      field: 'sender_commission',
      headerName: 'Fees Earned',
      flex: 1,
      minWidth: 150,
      // maxWidth: 200,
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Typography variant='body2' style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },

    {
      field: 'receipient_mto_amount',
      headerName: 'Amount Sent to Recipient',
      flex: 1.5,
      minWidth: 200,
      // maxWidth: 200,
      headerClassName: 'header',
      sortable: false,
      renderCell: (params) => {
        return (
          <Typography variant='body2' style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },

    {
      field: 'send_amount',
      headerName: 'Send Amount',
      flex: 1,
      minWidth: 150,
      // maxWidth: 200,
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Typography variant='body2' style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
    {
      field: 'receive_amount',
      headerName: 'Amount to Remit',
      flex: 1,
      minWidth: 150,
      // maxWidth: 200,
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Typography variant='body2' style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
    {
      field: 'rate',
      headerName: 'Exchange Rate',
      flex: 1,
      minWidth: 150,
      // maxWidth: 200,
      headerClassName: 'header',
      sortable: false,
      renderCell: (params) => {
        return (
          <Typography variant='body2' style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1.5,
      minWidth: 250,
      headerClassName: 'header',
      sortable: false,
      renderCell: (params) => {
        let { display, text_colour, background_colour } = params.formattedValue;
        return (
          <Label variant={'ghost'} textColor={text_colour} backgroundColor={background_colour}  sx={{ textTransform: 'capitalize' }}>
            {display}
          </Label>
        );
      },
    },
    {
      field: 'remarks',
      headerName: 'Description',
      flex: 1,
      minWidth: 150,
      // maxWidth: 200,
      headerClassName: 'header',
      headerAlign: 'right',
      sortable: false,
      renderCell: (params) => {
        return (
          <Typography variant='body2' style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
  ];

  return (
    <>
      <Box
        sx={{
          // height: 825,
          boxShadow: 'rgba(99, 99, 99, 0.2) 0px 0px 8px 0px',
          borderRadius: '8px',
          width: '100%',
          '& .MuiDataGrid-virtualScrollerRenderZone': {
            '& .MuiDataGrid-row': {
              // backgroundColor: 'rgba(235, 235, 235, .7)',
              fontWeight: 500,
              color: '#667085',
              fontFamily: 'Inter, sans-serif',
              fontSize: '14px',
            },
          },
          '& .header': {
            backgroundColor: '#F4F4F4',
            '& div': {
              '& div': {
                '& div': {
                  '& div': {
                    fontSize: '14px',
                    fontWeight: 600,
                    fontFamily: 'Inter, sans-serif',
                  },
                },
              },
            },
          },
        }}
      >
        <DataGrid
          autoHeight
          sx={{
            borderRadius: '8px',
            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 0px 8px 0px',
            width: '100%',
          }}
          components={{
            LoadingOverlay: LinearProgress,
          }}
          // density={'compact'}
          rows={rows}
          columns={columns}
          rowHeight={50}
          disableColumnFilter={true}
          disableColumnMenu={true}
          pagination
          paginationMode='server'
          rowsPerPageOptions={rowsPerPageOptions}
          page={filters.currentPage}
          rowCount={translist.count}
          pageSize={filters.pageSize}
          onPageChange={(newPage) => handleChangePage(newPage)}
          onPageSizeChange={(newSize) => handleChangeRowsPerPage(newSize)}
          loading={fetchTranslistLoading}
          sortingMode='server'
          sortModel={filters.sortModel}
          onSortModelChange={handleSortModelChange}
        />
      </Box>
    </>
  );
}
