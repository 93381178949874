import React, { useState } from 'react';
import { InputAdornment, Typography } from '@mui/material';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Button } from 'rsuite';

import { Stack, Dialog, DialogTitle, DialogActions, DialogContent } from '@mui/material';
import { TextFieldComponent } from '../../../../../components';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { merchantApi } from '../../../../../redux/api/apiConfig';
import { FEE } from '../../../../../ApiUrl';

const validationSchema = yup.object({
  from: yup.number('Enter  range start').min(0, 'Please enter valid range start').required('Range start is required!'),

  to: yup.number('Enter range end').min(0, 'Please enter valid range end').required('Range end is required!'),

  fixed_cost: yup.number('Enter fixed cost').min(0, 'Please enter fixed cost').required('Fixed cost is required!'),

  percentage_charge: yup.number('Enter percentage charge').min(0, 'Please enter valid percentage charge').required('Percentage Charge is required!'),
});

const AddNewSlabModal = ({ onClose, isEdit, isOpen, onSubmit, side, slabToEdit }) => {
  const [loading, setLoading] = useState(false);

  const { user } = useSelector((state) => state.profileReducer) || {};
  const currency = user?.mto?.currency?.code;

  const addClickHandler = async (values) => {
    const { from, to, fixed_cost, percentage_charge } = values;
    if (!isEdit) {
      try {
        setLoading(true);
        const response = await merchantApi.post(FEE, {
          from_amount: from,
          to_amount: to,
          fixed_cost_fee: fixed_cost,
          percentage_fee: percentage_charge,
          mto: user.mto.id,
          side,
        });
        console.log(response);
        onSubmit();

        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
        toast.error(err?.data?.data?.message || 'Something went wrong');
      }
    } else {
      try {
        setLoading(true);
        const response = await merchantApi.patch(`${FEE}${slabToEdit.id}/`, {
          from_amount: from,
          to_amount: to,
          fixed_cost_fee: fixed_cost,
          percentage_fee: percentage_charge,
          mto: user.mto.id,
          side,
        });
        console.log(response);
        onSubmit();

        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
        toast.error(err?.data?.data?.message || 'Something went wrong');
      }
    }
  };

  console.log(slabToEdit);

  const formik = useFormik({
    initialValues: isEdit
      ? {
          from: Number(slabToEdit.from_amount).toFixed(user.mto.currency.decimal_place),
          to: Number(slabToEdit.to_amount).toFixed(user.mto.currency.decimal_place),
          fixed_cost: Number(slabToEdit.fixed_cost_fee).toFixed(user.mto.currency.decimal_place),
          percentage_charge: Number(slabToEdit.percentage_fee).toFixed(2),
        }
      : {},
    validateOnBlur: true,
    validationSchema: validationSchema,
    validateOnMount: true,
    onSubmit: addClickHandler,
  });

  const handleCloseModal = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    onClose();
  };
  return (
    <>
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth={'xs'}
        onClose={handleCloseModal}
        scroll={'body'}
        BackdropProps={{ style: { backgroundColor: 'rgba(11, 5, 35, 0.8)' } }}
        PaperProps={{
          style: {
            boxShadow: 'none',
            borderRadius: '8px',
            // width: '628px',
            // minHeight: '550px',
            // maxHeight: '90vh',
          },
        }}
      >
        <DialogTitle sx={{ textAlign: 'center' }}> {isEdit ? 'Edit Slab' : 'Add New Slab'}</DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent sx={{ paddingTop: '5px !important', paddingBottom: '0 !important' }}>
            <Stack spacing={1} direction={'column'} alignItems={'center'} mb={1}>
              <TextFieldComponent
                formik={formik}
                name={'from'}
                type={'number'}
                label={'From (Excluding)'}
                inputProps={{
                  endAdornment: <InputAdornment position='end'>{currency}</InputAdornment>,
                }}
              />

              <TextFieldComponent
                formik={formik}
                name={'to'}
                type={'number'}
                label={'To (Including)'}
                inputProps={{
                  endAdornment: <InputAdornment position='end'>{currency}</InputAdornment>,
                }}
              />
              <TextFieldComponent
                formik={formik}
                name={'fixed_cost'}
                type={'number'}
                label={'Fixed Cost'}
                inputProps={{
                  endAdornment: <InputAdornment position='end'>{currency}</InputAdornment>,
                }}
              />
              <TextFieldComponent formik={formik} name={'percentage_charge'} type={'number'} label={'Percentage Charge'} />
            </Stack>

            <Typography variant='caption'>*Final fee charged to customer will be sum of fixed charge and percentage fee</Typography>

            <DialogActions sx={{ justifyContent: 'space-between' }}>
              <Button className='btn white-btn action-button ' onClick={onClose} appearance='subtle'>
                Cancel
              </Button>
              <Button
                className={`btn action-button primary_color text-white`}
                appearance='primary'
                type={'submit'}
                disabled={!formik.isValid}
                loading={loading}
              >
                {isEdit ? 'Update' : 'Add'}
              </Button>
            </DialogActions>
          </DialogContent>
        </form>
      </Dialog>
    </>
  );
};

export default AddNewSlabModal;
