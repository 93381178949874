import React, { useState, useRef, useEffect, useCallback } from 'react';
import { ButtonPrimary, ButtonSecondary, Iconify } from '../../../../../components';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Skeleton, Stack, Stepper, Step, StepLabel } from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClientInfo from './ClientInfo';
import RecipientInfo from './RecipientInfo';
import TransferDetails from './TransferDetails';
import Preview from './Preview';
import PassphraseModal from './PassphraseModal';
import { useSelector } from 'react-redux';
import { merchantApi } from '../../../../../redux/api/apiConfig';
import { REMITTANCE_OUTGOING, TRANSACTION, MEDIATOR } from '../../../../../ApiUrl';
import { send } from '../../../../../utils/xrpl_functions';
import { Routes } from '../../../../../routes';
import { Button } from 'rsuite';
import ReactToPrint from 'react-to-print';
import { format } from 'date-fns';
import { useRemittance } from '../../../../../context/remittance/remittanceContext';
import { Prompt } from 'react-router-dom';
import ConfirmModal from '../../../../../components/confirmModal/ConfirmModal';
import usePageTitle from '../../../../../hooks/usePageTitle';
import RemittanceQrDialog from '../../../../../components/RemittanceQrDialog';

const AddRemittance = () => {
  const { clientInfo, recipientInfo, transferDetails, formState, remittanceDispatch } = useRemittance();
  const [openPassphraseModal, setOpenPassphraseModal] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [createRemittanceRes, setCreateRemittanceRes] = useState(null);
  const [passphraseLoading, setPassphraseLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [discardLoading, setDiscardLoading] = useState(false);
  const [draftFetchLoading, setDraftFetchLoading] = useState(true);
  const [showQRDialog, setShowQRDialog] = useState(false);
  // Use below state to check if a remittance opened in draft state has been updated or not
  const [isRemittanceUpdated, setIsRemittanceUpdated] = useState(false);

  usePageTitle('Create Remittance');

  const { configReducer, walletsReducer, profileReducer } = useSelector((state) => state);
  const { depositTypes, purposeTypes } = configReducer;
  const { wallets } = walletsReducer;
  const {user:{mto:{supported_coin}}} = profileReducer

  const history = useHistory();
  const params = useParams();

  let componentRef = useRef();
  console.log(clientInfo, recipientInfo, transferDetails);

  const createRemittance = async () => {
    console.log(clientInfo, recipientInfo, transferDetails);
    setSubmitLoading(true);
    let { type, purpose, other_purpose, sender_document, sender: { id: senderId }, } = clientInfo;
    let {
      recipient: { id: recipientId },
      financeAccount: { id: accountId },
    } = recipientInfo;

    let {
      fee: { outward_fee, inward_fee, user_pay, other_fee, offer },
      deposit_type,
      other_deposit,
      send_currency,
      send_amount,
      receive_currency,
      receive_amount,
      recipient_mto: { id: receiptMtoId },
    } = transferDetails;

    if (deposit_type !== 4) {
      deposit_type = depositTypes.filter((depo) => depo.id === deposit_type)[0].name;
    } else {
      deposit_type = other_deposit;
    }
    if (purpose !== purposeTypes.length) {
      purpose = purposeTypes.filter((pur) => pur.id === purpose)[0].name;
    } else {
      purpose = other_purpose;
    }
    if (!createRemittanceRes) {
      try {
        const response = await merchantApi.post(REMITTANCE_OUTGOING, {
          sender: senderId,
          sender_document,
          receiver: recipientId,
          sender_commission: outward_fee,
          receiver_commission: inward_fee,
          customer_paid_amount: user_pay,
          stb_fee: other_fee,
          send_currency,
          receive_currency,
          send_amount,
          receive_amount,
          receiver_account: accountId,
          receipt_mto: receiptMtoId,
          deposit_type: deposit_type,
          type,
          purpose: purpose,
          exchange_rate: offer,
        });
        setCreateRemittanceRes(response.data.data);
        toast.success('Remittance created successfully!');
        toggleQRDialog()
      } catch (err) {
        toast.error(err?.data?.data?.message || 'Something went wrong');
        setSubmitLoading(false);
      }
    } else {
      try {
        const response = await merchantApi.patch(`${REMITTANCE_OUTGOING}${createRemittanceRes.id}/`, {
          sender: senderId,
          sender_document,
          receiver: recipientId,
          sender_commission: outward_fee,
          receiver_commission: inward_fee,
          customer_paid_amount: user_pay,
          stb_fee: other_fee,
          send_currency,
          receive_currency,
          send_amount,
          receive_amount,
          receiver_account: accountId,
          receipt_mto: receiptMtoId,
          deposit_type: deposit_type,
          type,
          purpose: purpose,
          exchange_rate: offer,
        });
        setCreateRemittanceRes(response.data.data);
        toast.success('Remittance details updated successfully');
        setSubmitLoading(false);
        // if (saveMode === 'draft') {
        //   history.push(Routes.OutwardRemittance.path);
        // } else {
        //   togglePassphraseModal();
        // }
        toast.success('Remittance created successfully!');
        toggleQRDialog()
        setIsRemittanceUpdated(true);
      } catch (err) {
        toast.error(err?.data?.data?.message || 'Something went wrong');
        setSubmitLoading(false);
      }
    }
  };

  const discardRemittance = async () => {
    try {
      setDiscardLoading(true);
      if (createRemittanceRes) {
        const response = await merchantApi.delete(`${REMITTANCE_OUTGOING}${createRemittanceRes?.id}/`);
        console.log(response);
      }

      setDiscardLoading(false);
      history.push(Routes.OutwardRemittance.path);
    } catch (err) {
      setDiscardLoading(false);
      toast.error(err?.data?.data?.message || 'Something went wrong');
    }
  };

  const getcompleted = (r) => {
    return r < activeStep;
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    // seteditable(true);
    setActiveStep(activeStep - 1);
  };

  const togglePassphraseModal = () => {
    setOpenPassphraseModal((state) => !state);
  };
  
  const toggleQRDialog = () => {
    setShowQRDialog((state) => !state);
  }

  const passphraseSubmitHandler = async (passphrase) => {
    setPassphraseLoading(true);
    const response = await merchantApi.get(MEDIATOR)
    const mediatorWallets = response?.data?.results[0]?.wallets
    const { send_amount, receiver_commission, receive_amount, receive_currency, send_currency, receipt_mto } = createRemittanceRes;

    const {
      address: fromAddress,
      coin: { issuer_address: issuerSend, symbol: senderCurrency },
    } = wallets.find((wallet) => wallet.coin.id === send_currency.id);

    const {address: toAddress} = mediatorWallets.find((wallet) => wallet.coin.id === send_currency.id);
    // const { address: toAddress } = receipt_mto.wallet.find((wallet) => wallet.coin.id === send_currency.id);
    let { sender } = clientInfo;
    let { recipient } = recipientInfo;

    // Same currency transaction (sender currency)
    try {
      const blob = await send(
        fromAddress,
        toAddress,
        passphrase,
        Number(send_amount) + Number(receiver_commission),
        Number(send_amount) + Number(receiver_commission),
        senderCurrency,
        senderCurrency,
        issuerSend,
        issuerSend,
        sender.xrpl_tag,
        recipient.xrpl_tag
      );
      if (blob) {
        const response = await merchantApi.post(TRANSACTION, {
          remittance: createRemittanceRes.id,
          blob,
        });
        toast.success('Remittance created successfully');
        setOpenPassphraseModal(false);
        history.push(Routes.OutwardRemittance.path);
      }
    } catch (err) {
      toast.error(err?.data?.data?.message || 'Something went wrong');
    } finally {
      setPassphraseLoading(false);
    }
  };

  const getRemittanceDetailsAndPopulate = useCallback(
    async (id) => {
      setDraftFetchLoading(true);
      try {
        const response = await merchantApi.get(`${REMITTANCE_OUTGOING}${id}/`);
        console.log(response);
        setCreateRemittanceRes(response.data);
        let data = response.data;

        let purpose = data.purpose;

        purpose = purposeTypes.find((pur) => pur.name.toLowerCase() === data.purpose.toLowerCase());
        if (!purpose) {
          purpose = purposeTypes.find((pur) => pur.name.toLowerCase() === 'other');
        }

        const sender_document = data.sender_document.reduce((prev, curr) => {
          prev.push(curr.id);
          return prev;
        }, []);
        console.log(sender_document);

        console.log(purpose);

        remittanceDispatch({
          type: 'UPDATE_CLIENT_INFO',
          payload: {
            checkbox: true,
            purpose: purpose.id,
            other_purpose: purpose.name.toLowerCase() === 'other' ? data.purpose : '',
            sender: data.sender,
            sender_document,
            target_country: data.receiver.country.code2,
            target_country_id: data.receipt_mto.country,
            type: data.type,
          },
        });

        remittanceDispatch({
          type: 'UPDATE_RECIPIENT_INFO',
          payload: { recipient: data.receiver, financeAccount: data.receiver_account },
        });

        remittanceDispatch({
          type: 'UPDATE_TRANSFER_DETAILS',
          payload: {
            currency: { send: data?.send_currency?.symbol, receive: data?.receive_currency?.symbol },
            send_currency: data?.send_currency?.id,
            receive_currency: data?.receive_currency?.id,
          },
        });
        setDraftFetchLoading(false);
        setActiveStep(2);
      } catch (error) {
        toast.error(error?.data?.data?.message || 'Something went wrong');
        console.log(error);
        setDraftFetchLoading(false);
      }
    },
    [purposeTypes, remittanceDispatch]
  );

  useEffect(() => {
    console.log(params);
    if (params?.id) {
      getRemittanceDetailsAndPopulate(params?.id);
    } else {
      remittanceDispatch({
        type: 'UPDATE_TRANSFER_DETAILS',
        payload: {
          send_currency: supported_coin?.id,
          currency: { ...transferDetails.currency, send: supported_coin?.symbol }
        },
      });
      setDraftFetchLoading(false);
    }
    return () => remittanceDispatch({ type: 'RESET_REMITTANCE_INFO' });
  }, [remittanceDispatch, params, getRemittanceDetailsAndPopulate, supported_coin]);

  useEffect(() => {
    const handleTabClose = (event) => {
      event.preventDefault();
      return (event.returnValue = 'You may have unsaved changes, are you sure you want to leave this page?');
    };

    window.addEventListener('beforeunload', handleTabClose);

    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
    };
  }, []);

  return (
    <Box sx={{ padding: '24px' }}>
      <Prompt
        when={params?.id ? !isRemittanceUpdated : !createRemittanceRes}
        message='You may have unsaved changes, are you sure you want to leave this page?'
      />
      <ConfirmModal
        open={formState.openConfirmDiscard}
        onClose={() => {
          remittanceDispatch({ type: 'TOGGLE_DISCARD' });
        }}
        title={'Discard Remittance'}
        content={
          <>
            Are you sure want to <strong> discard </strong> the remittance?
          </>
        }
        action={
          <ButtonPrimary loading={discardLoading} onClick={discardRemittance}>
            Discard
          </ButtonPrimary>
        }
      />
      <Stack mb={2} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        {/* <span onClick={backClickHandler} className='back mb-4'>
          <img src={back} alt='back' />
          <span>Back</span>
        </span> */}
        <ButtonSecondary
          onClick={() => {
            remittanceDispatch({ type: 'TOGGLE_DISCARD' });
          }}
        >
          Discard
        </ButtonSecondary>
        {activeStep === 3 && (
          <ReactToPrint
            trigger={() => (
              <Button className='btn load text-white'>
                <Iconify sx={{ mr: 1 }} icon={'eva:printer-outline'} />
                Print
              </Button>
            )}
            content={() => componentRef.current}
            documentTitle={`Remittance ${format(new Date(), 'dd MMM yyyy')}`}
          />
        )}
      </Stack>
      <Stepper activeStep={activeStep}>
        <Step completed={getcompleted(0)}>
          <StepLabel>
            <span className='d-none d-sm-block'>Client Info</span>
          </StepLabel>
        </Step>

        <Step completed={getcompleted(1)}>
          <StepLabel>
            <span className='d-none d-sm-block'>Recipient Info</span>
          </StepLabel>
        </Step>
        <Step completed={getcompleted(2)}>
          <StepLabel>
            <span className='d-none d-sm-block'>Transfer Details</span>
          </StepLabel>
        </Step>
        <Step completed={getcompleted(3)}>
          <StepLabel>
            <span className='d-none d-sm-block'>Preview and Submit</span>
          </StepLabel>
        </Step>
      </Stepper>
      {/* {getContent(activeStep)} */}
      {draftFetchLoading ? (
        <Skeleton variant='rounded' animation={'wave'} height={500} sx={{ mt: 3 }} />
      ) : (
        <>
          {activeStep === 0 && <ClientInfo handleNext={handleNext} />}
          {activeStep === 1 && <RecipientInfo handleNext={handleNext} handleBack={handleBack} />}
          {activeStep === 2 && <TransferDetails handleNext={handleNext} handleBack={handleBack} />}
          {activeStep === 3 && (
            <>
              <Preview ref={componentRef} />
              <Stack direction={'row'} sx={{ justifyContent: 'space-between', paddingTop: '24px' }}>
                <ButtonSecondary
                  // className="btn white-btn action-button "
                  onClick={handleBack}
                  // appearance="subtle"
                >
                  Back
                </ButtonSecondary>
                <Stack direction={'row'} gap={1}>
               
                  <ButtonPrimary
                    loading={submitLoading}
                    onClick={() => {
                      createRemittance('confirm');
                    }}
                  >
                    Confirm and Submit
                  </ButtonPrimary>
                </Stack>
              </Stack>
            </>
          )}
        </>
      )}

      {openPassphraseModal && (
        <PassphraseModal
          isOpen={openPassphraseModal}
          onClose={togglePassphraseModal}
          // backClickHandler={togglePassphraseModal}
          submitClickHandler={passphraseSubmitHandler}
          passphraseLoading={passphraseLoading}
        />
      )}
      {showQRDialog && (
        <RemittanceQrDialog open={showQRDialog} onClose={() => {
          toggleQRDialog();
          history.replace(Routes.OutwardRemittance.path);
        }}
         address={createRemittanceRes?.stb_blockchain_address}
        total = {createRemittanceRes?.customer_paid_amount}
        paid={createRemittanceRes?.paid_amount}
        currency={createRemittanceRes?.send_currency}
        />
      )}
    </Box>
  );
};

export default AddRemittance;
