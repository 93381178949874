import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as Rsuite from 'rsuite';
import { format } from 'date-fns';
import { startCase, toLower } from 'lodash';
import { Box, Stack, Typography, LinearProgress, Avatar, Tooltip, IconButton } from '@mui/material';

import { DataGrid } from '@mui/x-data-grid';
import { formatCurrency, formatWithoutTimezone, rowsPerPageOptions, tableCellStyle, transactionStatusColors } from '../../../../../utils/common';
import createAvatar from '../../../../../utils/createAvatar';
import { Label } from '../../../../../components';

export default function CreateRedeemTable({
  translist,
  paginateTable,
  setpaginateTable,
  fetchTranslistLoading,
  onSelectedRedeemChange,
  selectedTransactions,
}) {
  const handleChangeRowsPerPage = (newSize) => {
    setpaginateTable({
      ...paginateTable,
      currentPage: 0,
      pageSize: newSize,
    });
  };

  const createDataRow = (transaction) => {
    const type = transaction.type;
    return {
      id: transaction.id,
      referenceNumber: transaction.reference_number,
      // type: capital(transaction?.type) || 'P2P',
      submissionDate: formatWithoutTimezone(transaction.created_date, 'dd/MM/yyyy HH:mm a'),
      sender: (() => {
        const senderType = type.split('2')[0];
        if (senderType === 'p') {
          return startCase(toLower(`${transaction.sender.first_name} ${transaction.sender.last_name}`));
        } else {
          return startCase(toLower(transaction.sender.business_name));
        }
      })(),

      receivedValue: (() => {
        return formatCurrency(
          Number(transaction.send_amount) + Number(transaction.receiver_commission),
          transaction.send_currency?.decimal_place,
          transaction.send_currency?.symbol
        );
      })(),
      // currency: transaction.currency.code,
      status: transaction?.current_status,
      // updateStatus: transaction,
      // details: transaction,
      receiverCurrency: transaction.receive_currency,
    };
  };

  const rows = translist?.length > 0 ? translist.map((transaction) => createDataRow(transaction)) : [];

  const columns = [
    {
      field: 'referenceNumber',
      headerName: 'ID',
      flex: 1,
      minWidth: 120,
      // maxWidth: 200,
      headerClassName: 'header',

      renderCell: (params) => {
        return (
          <Tooltip placement='bottom' sx={{ cursor: 'default' }} title={params.formattedValue}>
            <Typography variant='body2' style={tableCellStyle}>
              {params.formattedValue}
            </Typography>
          </Tooltip>
        );
      },
    },

    {
      field: 'submissionDate',
      headerName: 'Date',
      flex: 1,
      minWidth: 200,
      // maxWidth: 200,
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Typography variant='body2' style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
    {
      field: 'sender',
      headerName: 'Sender',
      flex: 2,
      minWidth: 200,
      // maxWidth: 200,
      textOverflow: 'ellipsis',
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Stack direction={'row'} sx={{ minWidth: 0, alignItems: 'center' }}>
            <Avatar alt={params.formattedValue} sx={{ mr: 1, bgcolor: createAvatar(params.formattedValue).color }}>
              {createAvatar(params.formattedValue).name}
            </Avatar>
            <Typography
              variant='body2'
              sx={{
                color: 'black',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontWeight: '500',
              }}
            >
              {params.formattedValue}
            </Typography>
          </Stack>
        );
      },
    },

    // {
    //   field: 'sentValueInCurrency',
    //   headerName: 'Sent Value in Currency',
    //   flex: 1,
    //   minWidth: 180,
    //   // maxWidth: 200,
    //   headerClassName: 'header',
    // },
    {
      field: 'receivedValue',
      headerName: 'Recv Value',
      flex: 1,
      minWidth: 150,
      // maxWidth: 200,
      headerClassName: 'header',
      sortable: false,
      renderCell: (params) => {
        return (
          <Typography variant='body2' style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },

    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      flex: 1,
      minWidth: 150,
      // align: 'right',
      // headerAlign: 'center',
      headerClassName: 'header',
      renderCell: (params) => {
        let { value, sender_name } = params.formattedValue;
        switch (params.formattedValue.value) {
          case 'pending':
            return (
              <Label variant={'ghost'} color={transactionStatusColors[value]} sx={{ textTransform: 'capitalize' }}>
                {sender_name}
              </Label>
            );
          case 'processed':
            return (
              <Label variant={'ghost'} color={transactionStatusColors[value]} sx={{ textTransform: 'capitalize' }}>
                {sender_name}
              </Label>
            );

          case 'reject_request':
            return (
              <Label variant={'ghost'} color={transactionStatusColors[value]} sx={{ textTransform: 'capitalize' }}>
                {sender_name}
              </Label>
            );
          case 'expired':
            return (
              <Label variant={'ghost'} color={transactionStatusColors[value]} sx={{ textTransform: 'capitalize' }}>
                {sender_name}
              </Label>
            );
          case 'failed':
            return (
              <Label variant={'ghost'} color={transactionStatusColors[value]} sx={{ textTransform: 'capitalize' }}>
                {sender_name}
              </Label>
            );
          case 'payout_done':
            return (
              <Label variant={'ghost'} color={transactionStatusColors[value]} sx={{ textTransform: 'capitalize' }}>
                {sender_name}
              </Label>
            );

          case 'reject_complete':
            return (
              <Label variant={'ghost'} color={transactionStatusColors[value]} sx={{ textTransform: 'capitalize' }}>
                {sender_name}
              </Label>
            );
          default:
            return (
              <Label variant={'ghost'} color={'application submitted'} sx={{ textTransform: 'capitalize' }}>
                {sender_name || '--'}
              </Label>
            );
        }
      },
    },
  ];

  return (
    <>
      <Box
        sx={{
          // height: 825,
          boxShadow: 'rgba(99, 99, 99, 0.2) 0px 0px 8px 0px',
          borderRadius: '8px',
          width: '100%',
          '& .MuiDataGrid-virtualScrollerRenderZone': {
            '& .MuiDataGrid-row': {
              // backgroundColor: 'rgba(235, 235, 235, .7)',
              fontWeight: 500,
              color: '#667085',
              fontFamily: 'Inter, sans-serif',
              fontSize: '14px',
            },
          },
          '& .header': {
            backgroundColor: '#F4F4F4',
            '& div': {
              '& div': {
                '& div': {
                  '& div': {
                    fontSize: '14px',
                    fontWeight: 600,
                    fontFamily: 'Inter, sans-serif',
                  },
                },
              },
            },
          },
        }}
      >
        <DataGrid
          autoHeight
          sx={{
            borderRadius: '8px',
            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 0px 8px 0px',
            width: '100%',
          }}
          components={{
            LoadingOverlay: LinearProgress,
          }}
          rows={rows}
          columns={columns}
          rowHeight={50}
          disableColumnFilter={true}
          disableColumnMenu={true}
          pageSize={paginateTable.pageSize}
          pagination
          rowsPerPageOptions={rowsPerPageOptions}
          rowCount={translist.length}
          onPageSizeChange={(newSize) => handleChangeRowsPerPage(newSize)}
          loading={fetchTranslistLoading}
          checkboxSelection={true}
          onSelectionModelChange={onSelectedRedeemChange}
          selectionModel={selectedTransactions}
          isRowSelectable={(params) => !fetchTranslistLoading}
        />
      </Box>
    </>
  );
}
