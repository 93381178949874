import React from 'react';
import { Paper, Typography } from '@mui/material';

// ----------------------------------------------------------------------

export default function SearchNotFound({ searchQuery = '', toggle, ...other }) {
  console.log(searchQuery);
  return searchQuery !== '' ? (
    <Paper elevation={0} {...other}>
      <Typography gutterBottom align='center' variant='subtitle1'>
        Not found
      </Typography>
      <Typography variant='body2' align='center'>
        No results found for &nbsp;
        <strong>&quot;{searchQuery}&quot;</strong>. Click{' '}
        <strong onClick={toggle} style={{ cursor: 'pointer' }}>
          here
        </strong>{' '}
        to add one.
      </Typography>
    </Paper>
  ) : (
    <Typography variant='body2'> Please enter keywords</Typography>
  );
}
