import React from 'react';
import { ResponsiveContainer, AreaChart, XAxis, Area, Tooltip } from 'recharts';
import { statusColors } from '../../utils/common';

const AreaChartComp = ({ data, lines }) => {
  const keys = Object.keys(statusColors.bgColors);
  return (
    <ResponsiveContainer height='90%' width='100%'>
      <AreaChart
        width='100%'
        height='100%'
        data={data}
        margin={{
          left: 15,
          right: 15,
          top: 10,
          bottom: 10,
        }}
      >
        <XAxis dataKey='name' />
        <Tooltip />
        {lines.map((line, i) => (
          <Area type='monotone' dataKey={line} strokeWidth={4} stroke={statusColors.colors[keys[i]]} fill={statusColors.bgColors[keys[i]]} />
        ))}
        {/* <Area
          type="monotone"
          dataKey="inward"
          strokeWidth={4}
          stroke={statusColors.colors['application submitted']}
          fill={statusColors.bgColors['application submitted']}
        />
        <Area
          type="monotone"
          dataKey="outward"
          strokeWidth={4}
          stroke={statusColors.colors['pending']}
          fill={statusColors.bgColors['pending']}
        />
        <Area
          type="monotone"
          dataKey="total"
          strokeWidth={4}
          stroke={statusColors.colors['complete']}
          fill={statusColors.bgColors['complete']}
        /> */}
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default AreaChartComp;
