import * as actionTypes from '../actionTypes';
import { updateObject } from './utility';

const initialState = {
  loading: false,
  error: null,
  showSidebar: true,
  pageTitle: '',
  pageSubTitle: '',
};

const getConfig = (action, state) => {
  return updateObject(state, {
    loading: true,
  });
};

const setConfig = (action, state) => {
  return updateObject(state, {
    loading: false,
    globalConfig: action.payload,
  });
};

const setCurrency = (action, state) => {
  return updateObject(state, {
    loading: false,
    currencyTypes: action.payload,
  });
};

const setDepositTypes = (action, state) => {
  return updateObject(state, {
    loading: false,
    depositTypes: action.payload,
  });
};
const setPurposeTypes = (action, state) => {
  return updateObject(state, {
    loading: false,
    purposeTypes: action.payload,
  });
};
const setCountriesData = (action, state) => {
  return updateObject(state, {
    loading: false,
    countries: action.payload,
  });
};
const setRemittanceTypes = (action, state) => {
  return updateObject(state, {
    loading: false,
    remittanceTypes: action.payload,
  });
};
const setRemittanceStatuses = (action, state) => {
  return updateObject(state, {
    loading: false,
    remittanceStatuses: action.payload,
  });
};
const setRedeemStatuses = (action, state) => {
  return updateObject(state, {
    loading: false,
    redeemStatuses: action.payload,
  });
};
const setKycDocTypes = (action, state) => {
  return updateObject(state, {
    loading: false,
    kycDocTypes: action.payload,
  });
};
const setAccountTypes = (action, state) => {
  return updateObject(state, {
    loading: false,
    accountTypes: action.payload,
  });
};

const toggleSidebar = (state) => {
  return updateObject(state, {
    showSidebar: !state.showSidebar,
  });
};

const showSidebar = (state) => {
  return updateObject(state, {
    showSidebar: true,
  });
};
const setPageTitle = (action, state) => {
  console.log('recewced', action);
  return updateObject(state, {
    pageTitle: action.payload.pageTitle,
    pageSubTitle: action.payload.pageSubTitle,
  });
};

const configReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CONFIG:
      return getConfig(action, state);
    case actionTypes.SET_CONFIG:
      return setConfig(action, state);
    case actionTypes.SET_CURRENCY:
      return setCurrency(action, state);
    case actionTypes.SET_DEPOSIT_TYPES:
      return setDepositTypes(action, state);
    case actionTypes.SET_REMITTANCE_TYPES:
      return setRemittanceTypes(action, state);
    case actionTypes.SET_ACCOUNT_TYPES:
      return setAccountTypes(action, state);
    case actionTypes.SET_PURPOSE_TYPES:
      return setPurposeTypes(action, state);
      case actionTypes.SET_COUNTRIES_DATA:
        return setCountriesData(action, state);
    case actionTypes.SET_KYC_DOC_TYPES:
      return setKycDocTypes(action, state);
    case actionTypes.SET_REMITTANCE_STATUSES:
      return setRemittanceStatuses(action, state);
    case actionTypes.SET_REDEEM_STATUSES:
      return setRedeemStatuses(action, state);
    case actionTypes.TOGGLE_SIDEBAR:
      return toggleSidebar(state);
    case actionTypes.SHOW_SIDEBAR:
      return showSidebar(state);
    case actionTypes.SET_PAGE_TITLE:
      return setPageTitle(action, state);
    default:
      return state;
  }
};

export default configReducer;
