import React, { useState, useEffect, useCallback } from 'react';
import { merchantApi } from '../../../redux/api/apiConfig';
import * as API from '../../../ApiUrl';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'rsuite/dist/styles/rsuite-default.css';
import { Box, Stack, Autocomplete, TextField, Card, Divider } from '@mui/material';
import { reportTimePeriods } from '../common';
import { ButtonPrimary, ButtonSecondary, ButtonResetFilter, Iconify } from '../../../components';
import { format as Format } from 'date-fns';
import AvailableRedemptionReportTable from './availableRedemptionReportTable';
import { Highlight, HighlightSkeleton } from '../components';
import usePageTitle from '../../../hooks/usePageTitle';

function AvailableRedemptionReport() {
  const [mtoOptions, setMtoOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [highlightLoading, setHighlightLoading] = useState(true);
  const [availableRedemptionReport, setAvailableRedemptionReport] = useState({
    mto: null,
  });
  const [translist, setTranslist] = useState([]);
  const [highlights, setHighlights] = useState([]);

  usePageTitle('Available Settlement Report');

  const getMtoOptions = useCallback(async () => {
    try {
      const response = await merchantApi.get(`${API.MTO}`);
      console.log(response.data);
      setMtoOptions(response.data);
    } catch (error) {
      toast.error(error?.data?.data?.message || 'Something went wrong');
      toast.error('Could not fetch MTO list.');
    }
  }, []);

  const getReport = useCallback(async () => {
    setLoading(true);
    const { mto } = availableRedemptionReport;

    const params = {
      mto: mto.id,
    };
    try {
      const response = await merchantApi.get(API.PENDING_REDEMPTION_REPORT, {
        params,
      });
      console.log(response);
      setTranslist(response.data.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      toast.error(err?.data?.data?.message || 'Something went wrong');
    }
  }, [availableRedemptionReport]);

  const getHighlights = useCallback(async () => {
    setHighlightLoading(true);
    try {
      const { mto } = availableRedemptionReport;

      const params = {
        mto: mto.id,
      };
      const response = await merchantApi.get(`${API.PENDING_REDEMPTION_REPORT}highlight/`, {
        params,
      });
      console.log(response);
      setHighlights(response.data.data);
      setHighlightLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error?.data?.data?.message || 'Something went wrong');
      setHighlightLoading(false);
    }
  }, [availableRedemptionReport]);

  useEffect(() => {
    getMtoOptions();
  }, [getMtoOptions]);

  useEffect(() => {
    if (mtoOptions) {
      setAvailableRedemptionReport((prev) => ({ ...prev, mto: mtoOptions[0] }));
    }
  }, [mtoOptions]);

  useEffect(() => {
    if (availableRedemptionReport.mto) {
      getReport();
      getHighlights();
    }
  }, [availableRedemptionReport.mto, getReport, getHighlights]);

  const resetFilters = () => {
    setAvailableRedemptionReport({
      mto: mtoOptions[0],
    });
  };

  const download = (type) => {
    if (type === 'excel' && translist?.file) {
      window.open(translist?.file, '_blank');
    }
    if (type === 'pdf' && translist?.doc) {
      window.open(translist?.doc, '_blank');
    }
  };
  return (
    <>
      <Box sx={{ padding: '0 24px 24px' }}>
        <Card sx={{ width: '100%' }}>
          <Stack direction='row' divider={<Divider orientation='vertical' flexItem sx={{ borderStyle: 'dashed' }} />} sx={{ py: 2 }}>
            {highlightLoading
              ? [...Array(1)].map((a) => <HighlightSkeleton />)
              : highlights.map((highlight, i) => {
                  return (
                    <Highlight
                      key={highlight.heading}
                      title={highlight.heading}
                      subTitle={highlight.sub_heading}
                      // total={translist.count}
                      percent={100}
                      value={highlight.data}
                      icon={highlight?.meta?.icon}
                      color={highlight?.meta?.color}
                      propCurrency={highlight.currency}
                    />
                  );
                })}
          </Stack>
        </Card>

        <Stack sx={{ width: '100%', gap: 1, mb: 2 }}>
          <Stack
            sx={{
              overflowX: { xs: 'auto' },
              overflowY: { xs: 'visible', md: 'unset' },
              padding: '5px 0',
            }}
            direction={'row'}
            justifyContent={'space-between'}
            gap={1}
            mt={2}
            width={'100%'}
          >
            <Stack sx={{ flexGrow: 1, minWidth: '220px', maxWidth: 'fit-content' }}>
              <Autocomplete
                id='mto'
                value={availableRedemptionReport.mto}
                onChange={(e, newValue) => {
                  console.log(newValue);
                  setAvailableRedemptionReport((prevState) => ({
                    ...prevState,
                    mto: newValue ?? null,
                  }));
                }}
                options={mtoOptions}
                getOptionLabel={(option) => `${option.id}) ${option?.name} (${option?.country?.name})`}
                sx={{ flexGrow: 1, width: '100%' }}
                renderInput={(params) => {
                  return <TextField placeholder='Select MTO' {...params} size='small' />;
                }}
              />
            </Stack>

            <Stack sx={{ flexDirection: 'row', gap: 1, alignItems: 'flex-start' }}>
              <ButtonResetFilter onClick={resetFilters} />

              <ButtonSecondary onClick={() => download('excel')}>
                <Iconify icon={'vscode-icons:file-type-excel'} sx={{ width: '20px' }} />
              </ButtonSecondary>

              <ButtonPrimary
                onClick={() => {
                  getReport();
                  getHighlights();
                }}
                loading={loading}
              >
                Fetch Report
              </ButtonPrimary>
            </Stack>
          </Stack>
        </Stack>
        <AvailableRedemptionReportTable
          translist={translist.data}
          fetchTranslistLoading={loading}
          setPaginateTable={setAvailableRedemptionReport}
          paginateTable={availableRedemptionReport}
        />
      </Box>
    </>
  );
}

export default AvailableRedemptionReport;
