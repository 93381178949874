import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { merchantApi } from '../../../../../redux/api/apiConfig';
import * as API from '../../../../../ApiUrl';
import { Button } from 'rsuite';
import { Autocomplete, DialogActions, DialogContent, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { TextFieldComponent, TextFieldSelect, CountrySelect, PhoneNumberInput } from '../../../../../components';
import { toast } from 'react-toastify';

import { useEffect } from 'react';
import { useSelector } from 'react-redux';
// import { useSelector } from 'react-redux';

const validationSchema = yup.object({
  first_name: yup.string('Enter your first name').min(2, 'Please enter valid name').required('First Name is required!'),

  last_name: yup.string('Enter your last name').min(2, 'Please enter valid name').required('Last Name is required!'),

  sender: yup.object().notRequired().nullable(),

  primary_contact_no: yup.string('Enter your phonenumber'),
  // .matches(numricRegex, 'Only Number Required')
  // .min(10, 'Enter Valid Number')
  // .max(12, 'Phone Number Too Long ! Not Valid'),
  // .required('Phone Number is required'),

  country: yup.string('Select Country'),
  // .required('Country is required'),
  province: yup.string('Select Province'),
  // .required('Province is required'),
  city: yup.string('Enter City'),
  // .required('City is required'),
  address: yup.string('Enter address'),
  // .required('Address is required'),
});

const PersonalInfo = ({ onClose, handleNext, isEdit, personalInfo, loading, fromRemittance }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedProvince, setSelectedProvince] = useState('');
  const [senderOptions, setSenderOptions] = useState([]);
  const [render, setRender] = useState(0);

 const [provinceList, setProvinceList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const countryList = useSelector((state) => state?.profileReducer?.user?.mto?.receiver_supported_country);


  const countryCodes = countryList.reduce((acc, cur) => {
    acc.push(cur.code2);
    return acc;
  }, []);
  const getProvince = async (country) => {
    try {
      const response = await merchantApi.get(`${API.PROVINCE_LIST}?country=${country}&limit=1000`);
      console.log(response);
      setProvinceList(response.data.results);
    } catch (err) {
      toast.error(err?.data?.data?.message || 'Something went wrong');
      console.log(err);
    }
  };
  const getCities = async (country) => {
    try {
      const response = await merchantApi.get(`${API.CITY_LIST}?country=${country}&region=${formik.values.province}&limit=1000`);
      console.log(response);
      setCityList(response.data.results);
    } catch (err) {
      toast.error(err?.data?.data?.message || 'Something went wrong');
      console.log(err);
    }
  };

  const getSenderList = async () => {
    try {
      const response = await merchantApi.get(`${API.SENDER}/`);
      console.log(response);
      setSenderOptions(response.data);
    } catch (error) {
      toast.error(error?.data?.data?.message || 'Something went wrong');
      console.log(error);
    }
  };

  useEffect(() => {
    if (render !== 0) {
      getProvince(selectedCountry);
      setProvinceList([]);
      setCityList([]);
      formik.setFieldValue('province', '');
      formik.setFieldValue('city', '');
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (render !== 0) {
      getCities(selectedCountry);
      setCityList([]);
      formik.setFieldValue('city', '');
    }
  }, [selectedProvince]);

  useEffect(() => {
    console.log(personalInfo);
    getSenderList();
    if (personalInfo.country) {
      const code = countryList.find((country) => country.id === personalInfo.country).code2;
      // setSelectedCountry(personalInfo.country);
      formik.setFieldValue('country', code);
      getProvince(personalInfo.country);
      if (personalInfo.province) {
        formik.setFieldValue('province', personalInfo.province);
        getCities(personalInfo.country);
      }

      if (personalInfo.city) {
        formik.setFieldValue('city', personalInfo.city);
      }
    }

    return () => {
      setProvinceList([]);
      setCityList([]);
    };
  }, []);

  const formik = useFormik({
    initialValues: personalInfo,
    validateOnBlur: true,
    validationSchema: validationSchema,

    // isInitialValid: isEdit,
    validateOnMount: true,
  });
  console.log(formik.values);

  const nextClickHandler = (status) => {
    let country;
    if (formik.values.country) {
      console.log(formik.values.country);
      country = countryList.find((country) => country.code2 === formik.values.country).id;
      console.log(country);
    }
    handleNext({
      address: formik.values.address,
      city: formik.values.city,
      province: formik.values.province,
      country,
      first_name: formik.values.first_name,
      last_name: formik.values.last_name,
      primary_contact_no: formik.values.primary_contact_no,
      sender: formik?.values?.sender,
      status: 'draft',
    });
  };
  return (
    <>
      <DialogContent sx={{ paddingTop: '20px !important', paddingBottom: '0 !important' }}>
        <Box
          sx={{
            display: 'grid',
            columnGap: '16px',
            rowGap: '12px',
            marginBottom: '12px',
            gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
          }}
        >
          <TextFieldComponent formik={formik} name={'first_name'} type={'text'} label={'First Name'} />

          <TextFieldComponent formik={formik} name={'last_name'} type={'text'} label={'Last Name'} />
          <Stack spacing={0} width={'100%'}>
            <Typography>Belongs To</Typography>
            <Autocomplete
              // disablePortal
              disabled={fromRemittance || isEdit}
              id='sender'
              value={formik.values.sender}
              isOptionEqualToValue={(option, value) => {
                console.log(option, value);
                return option.id === value.id;
              }}
              onChange={(e, newValue) => {
                formik.setFieldValue('sender', newValue);
                console.log(newValue);
              }}
              options={senderOptions}
              getOptionLabel={(option) =>
                option.sender_type === 'individual'
                  ? `${option?.first_name} ${option?.last_name} (${option?.xrpl_tag})`
                  : `${option?.business_name} (${option?.xrpl_tag})`
              }
              sx={{ flexGrow: 1, width: '100%' }}
              renderInput={(params, item) => {
                console.log(params);
                return <TextField {...params} />;
              }}
            />
          </Stack>

          <PhoneNumberInput formik={formik} label={'Phone Number'} name={'primary_contact_no'} setPhoneNumber={setPhoneNumber} />

          <CountrySelect
            formik={formik}
            label={'Country'}
            name={'country'}
            options={countryCodes}
            // disabled={true}
            countryList={countryList}
            onChange={(id) => {
              setRender((prevState) => prevState + 1);
              setSelectedCountry(id);
            }}
          />
          <TextFieldSelect
            formik={formik}
            name={'province'}
            label={'Province'}
            options={provinceList}
            onChange={(event) => {
              setRender((prevState) => prevState + 1);
              setSelectedProvince(event.target.value);
            }}
            render={(province) => <MenuItem value={province.id}>{province.name}</MenuItem>}
          />

          <TextFieldSelect
            formik={formik}
            name={'city'}
            label={'City'}
            options={cityList}
            render={(province) => <MenuItem value={province.id}>{province.name}</MenuItem>}
          />
        </Box>
        <TextFieldComponent formik={formik} name={'address'} type={'text'} label={'Address'} multiline minRows={4} maxRows={4} />
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Button className='btn white-btn action-button ' onClick={onClose} appearance='subtle'>
            Cancel
          </Button>
          <Button
            className={`btn action-button primary_color text-white`}
            appearance='primary'
            onClick={nextClickHandler}
            loading={loading}
            disabled={isEdit ? false : !formik.isValid}
          >
            Next
          </Button>
        </DialogActions>
      </DialogContent>
    </>
  );
};

export default PersonalInfo;
