import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import { Label } from '../../../../../components';
import useDeleteModal from '../../../../../hooks/useDeleteModal';
import { DeleteModal } from '../../../../../components/deleteModal';
import { useSelector } from 'react-redux';
import { Box, Stack, Typography, LinearProgress, Tooltip, Avatar } from '@mui/material';
import { formatCurrency, rowsPerPageOptions, tableCellStyle } from '../../../../../utils/common';
import { capitalize } from 'lodash';
import createAvatar from '../../../../../utils/createAvatar';
import { useHistory } from 'react-router-dom';
import { tableStyle } from '../../../../../utils/common';
import { Routes } from '../../../../../routes';
import { format } from 'date-fns';

const BusinessTable = ({
  users,
  paginateTable,
  setPaginateTable,
  fetchUsersLoading,
  setClientToEdit,
  setIsEdit,
  toggleAddUserModal,
  deleteUser,
}) => {
  const [deleteId, setDeleteId] = useState('');
  const { showDeleteModal, toggleShowDeleteModal } = useDeleteModal();
  const history = useHistory();
  const NoRowsOverlay = () => {
    return (
      <Stack height='100%' alignItems='center' justifyContent='center'>
        {paginateTable.search === '' ? 'No Clients Added' : 'No Clients Found'}
      </Stack>
    );
  };

  const confirmDelete = () => {
    deleteUser(deleteId);
    toggleShowDeleteModal();
  };

  const createDataRow = (user) => {
    return {
      id: user.id,
      name: capitalize(user.business_name),
      user: capitalize(user?.primary_contact_name),
      email: user?.sender?.user?.email,
      balance: (() => formatCurrency(user?.wallet_balance, user?.currency?.decimal_place, user?.currency?.symbol, user?.currency?.symbol_prefix))(),
      tag: user?.sender?.xrpl_tag,
      city: user?.city?.name || '--',
      province: user?.province?.name || '--',
      kyb_status: user?.kyb_status,
      kyc_status: user?.kyc_status,
      // actions: user,
    };
  };

  const rows = users?.results?.length > 0 ? users.results.map((user) => createDataRow(user)) : [];

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      // flex: 1,
      minWidth: 80,
      // maxWidth: 80,
      headerClassName: 'header',
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <Typography variant='body2' style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
      // align: 'center',
    },
    {
      field: 'name',
      headerName: 'Business Name',
      sortable: false,
      flex: 1,
      minWidth: 300,
      // maxWidth: 250,
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Stack direction={'row'} sx={{ minWidth: 0, alignItems: 'center' }}>
            <Avatar alt={params.formattedValue} sx={{ mr: 2, bgcolor: createAvatar(params.formattedValue).color }}>
              {createAvatar(params.formattedValue).name}
            </Avatar>
            <Typography
              variant='body2'
              sx={{
                color: 'black',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontWeight: '500',
              }}
            >
              {params.formattedValue}
            </Typography>
          </Stack>
        );
      },
      // align: 'center',
      // headerAlign: 'center',
    },
    {
      field: 'user',
      headerName: 'User',
      // flex: 1,
      minWidth: 150,
      // maxWidth: 80,
      headerClassName: 'header',
      // align: 'center',
      // headerAlign: 'center',
      renderCell: (params) => {
        return (
          <Typography variant='body2' style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
      // align: 'center',
    },
    {
      field: 'email',
      headerName: 'Email',
      // flex: 1,
      minWidth: 150,
      headerClassName: 'header',
      // headerAlign: 'center',
      renderCell: (params) => {
        return (
          <Typography variant='body2' style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
      // align: 'center',
    },
    {
      field: 'tag',
      headerName: 'Xrpl Tag',
      sortable: false,
      flex: 1,
      minWidth: 140,
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Typography variant='body2' sx={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
      // align: 'center',
    },
    {
      field: 'city',
      headerName: 'City',
      sortable: false,
      flex: 1,
      minWidth: 140,
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Typography variant='body2' sx={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
      // align: 'center',
    },
    {
      field: 'province',
      headerName: 'Province',
      sortable: false,
      flex: 1,
      minWidth: 140,
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Typography variant='body2' sx={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
      // align: 'center',
    },
    {
      field: 'kyb_status',
      headerName: 'KYB Status',
      sortable: false,
      // flex: 1,
      minWidth: 150,
      alignItems: 'center',
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Label
            variant={'ghost'}
            color={params.formattedValue === 'complete' ? 'complete' : params.formattedValue === 'awaiting_approval' ? 'application submitted' :  'rejected'}
            sx={{ textTransform: 'capitalize' }}
          >
            {params.formattedValue?.replace(/_/g, ' ')}
          </Label>
        );
      },
      // align: 'center',
    },
    {
      field: 'kyc_status',
      headerName: 'KYC Status',
      sortable: false,
      // flex: 1,
      minWidth: 150,
      alignItems: 'center',
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Label
            variant={'ghost'}
            color={params.formattedValue === 'complete' ? 'complete' : params.formattedValue === 'awaiting_approval' ? 'application submitted' :  'rejected'}
            sx={{ textTransform: 'capitalize' }}
          >
            {params.formattedValue?.replace(/_/g, ' ')}
          </Label>
        );
      },
      // align: 'center',
    },
    // {
    //   field: 'actions',
    //   headerName: 'Actions',
    //   sortable: false,
    //   flex: 1,
    //   minWidth: 150,
    //   // maxWidth: 140,
    //   headerClassName: 'header',
    //   renderCell: (params) => {
    //     return (
    //       <Stack direction='row' spacing={2}>
    //         {permission?.clients?.senders?.business?.permission?.change[role] && (
    //           <Stack>
    //             <Tooltip title='Edit' arrow>
    //               <IconButton
    //                 onClick={(e) => {
    //                   e.stopPropagation();
    //                   onActivateClicked(params.value);
    //                 }}
    //               >
    //                 <EditIcon />
    //               </IconButton>
    //             </Tooltip>
    //           </Stack>
    //         )}
    //         {/* {permission?.clients?.senders?.business?.permission?.delete[
    //           role
    //         ] && (
    //           <Stack>
    //             <Tooltip title="Delete" arrow>
    //               <IconButton
    //                 onClick={() => {
    //                   setDeleteId(params.value.id);
    //                   toggleShowDeleteModal();
    //                 }}
    //               >
    //                 <DeleteIcon />
    //               </IconButton>
    //             </Tooltip>
    //           </Stack>
    //         )} */}
    //       </Stack>
    //     );
    //   },
    // },
  ];

  const onActivateClicked = (client) => {
    setIsEdit(true);
    setClientToEdit(client);
    toggleAddUserModal();
  };

  const handleChangePage = (newPage) => {
    setPaginateTable({ ...paginateTable, currentPage: newPage });
  };

  const handleChangeRowsPerPage = (newSize) => {
    setPaginateTable({
      ...paginateTable,
      currentPage: 0,
      pageSize: newSize,
    });
  };

  return (
    <>
      <Box
        sx={{
          height: 615,
          width: 1,
          '& .MuiDataGrid-virtualScrollerRenderZone': {
            '& .MuiDataGrid-row': {
              // backgroundColor: 'rgba(235, 235, 235, .7)',
              fontWeight: 500,
              color: '#667085',
              fontFamily: 'Inter, sans-serif',
              fontSize: '14px',
            },
          },
          '& .header': {
            backgroundColor: '#F4F4F4',
            '& div': {
              '& div': {
                '& div': {
                  '& div': {
                    fontSize: '14px',
                    fontWeight: 600,
                    fontFamily: 'Inter, sans-serif',
                  },
                },
              },
            },
          },
        }}
      >
        <DataGrid
          autoHeight
          sx={{ ...tableStyle, cursor: 'pointer' }}
          onRowClick={(row) => {
            history.push({
              pathname: Routes.ClientsSenderDetails.path,
              search: `?type=business&id=${row.id}`,
            });
          }}
          rows={rows}
          columns={columns}
          rowHeight={72}
          disableColumnFilter={true}
          disableColumnMenu={true}
          pagination
          paginationMode='server'
          rowsPerPageOptions={rowsPerPageOptions}
          loading={fetchUsersLoading}
          pageSize={paginateTable.pageSize}
          page={paginateTable.currentPage}
          rowCount={users.count}
          onPageChange={(newPage) => handleChangePage(newPage)}
          onPageSizeChange={(newSize) => handleChangeRowsPerPage(newSize)}
          components={{ NoRowsOverlay, LoadingOverlay: LinearProgress }}
        />
      </Box>
      {showDeleteModal && <DeleteModal onClose={toggleShowDeleteModal} isOpen={showDeleteModal} onSubmit={confirmDelete} text={'the client'} />}
    </>
  );
};

export default BusinessTable;
