import { w3cwebsocket as W3CWebSocket } from 'websocket';
import _ from 'lodash';
const WS_ADDRESS_NOTIFICATION = process.env.REACT_APP_WS_NOTIFICATION;

// const WS_ADDRESS = "ws://127.0.0.1:8000";

class WS {
  constructor() {
    this.notificationWS = new W3CWebSocket(WS_ADDRESS_NOTIFICATION);
  }

  static async initNotification(token, onSuccess) {
    this.notificationWS = new W3CWebSocket(`${WS_ADDRESS_NOTIFICATION}?token=${token}`);
    this.notificationWS.onopen = () => {
      console.log('Websocket Notification Client Connected');
      onSuccess();
    };
  }

  static recieiveNotification(callback) {
    this.notificationWS.onmessage = (message) => {
      const data = JSON.parse(message?.data);
      console.log('Initial Notification from Server: ', JSON.parse(message?.data));
      const dataFromServer = data?.data;
      callback(data);
    };
  }
}

export default WS;
