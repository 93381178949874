import React, { useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { ButtonPrimary, TextFieldSelect } from "../../../../../components";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-input-2/lib/style.css";
import { MenuItem } from "@material-ui/core";
import { Stack } from "@mui/material";
import * as API from "../../../../../ApiUrl";
import { merchantApi } from "../../../../../redux/api/apiConfig";
import { toast } from "react-toastify";

const numricRegex = /(?=.*[0-9])/;
const validationSchema = yup.object({
  status: yup.string("Select Status").required("Status is required"),
});

const kycStatuses = [
  {
    name: "PENDING",
    value: "not_done",
  },
  {
    name: "COMPLETE",
    value: "complete",
  },
  {
    name: "Rejected",
    value: "rejected",
  },
  {
    name: "UNDER REVIEW",
    value: "awaiting_approval",
  },
];

const KycKybStatusUpdate = ({ data, id, isKyb }) => {
  const [loading, setloading] = useState(false);

  const onSubmit = async () => {
    const data = { ...formik.values };
    try {
      setloading(true);
      const url = isKyb ? API.UPDATE_KYB : API.UPDATE_KYC;
      const respose = await merchantApi.patch(`${url}${id}/`, data);
      toast.success(
        isKyb ? "KYB updated  successfully" : "KYC updated successfully"
      );
      setloading(false);
    } catch (error) {
      setloading(false);
      toast.error(error?.data?.data?.message || "Error while updating status");
    }
  };

  //   useEffect(() => {
  //     getUserRole();
  //     getUserDocumentType();
  //   }, [getUserRole, getUserDocumentType]);

  const formik = useFormik({
    initialValues: {
      status: data || "",
    },
    validateOnBlur: true,
    validationSchema: validationSchema,
    onSubmit,
    // isInitialValid: false,
    validateOnMount: true,
  });

  return (
    <>
      <Stack flexDirection={"row"} alignItems={"flex-end"} gap={"20px"}>
        <TextFieldSelect
          formik={formik}
          name={"status"}
          //   type={"text"}
          label={isKyb ? "KYB Status" : "KYC Status"}
          options={kycStatuses}
          render={(role) => <MenuItem value={role.value}>{role.name}</MenuItem>}
        />
        <ButtonPrimary onClick={onSubmit} disabled={!formik.isValid || loading}>
          Update Status
        </ButtonPrimary>
      </Stack>
    </>
  );
};

export default KycKybStatusUpdate;
