import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Stack, Typography, LinearProgress, Avatar, Tooltip, IconButton } from '@mui/material';
import useDeleteModal from '../../../../../hooks/useDeleteModal';
import { DeleteModal } from '../../../../../components/deleteModal';
import DeleteIcon from '@mui/icons-material/Delete';

import { DataGrid } from '@mui/x-data-grid';
import { formatCurrency, rowsPerPageOptions, tableCellStyle, tableStyle } from '../../../../../utils/common';

import { Label } from '../../../../../components';
import { useSelector } from 'react-redux';
import { Routes } from '../../../../../routes';

export default function LiquidityTable({ liquidityList, paginateTable, setpaginateTable, fetchLiquidityLoading, deleteAccount }) {
  const history = useHistory();
  const [deleteId, setDeleteId] = useState('');

  const currency = useSelector((state) => state.profileReducer?.user?.mto?.currency);
  const { showDeleteModal, toggleShowDeleteModal } = useDeleteModal();

  const confirmDelete = () => {
    deleteAccount(deleteId);
    toggleShowDeleteModal();
  };

  const handleChangePage = (newPage) => {
    setpaginateTable({ ...paginateTable, currentPage: newPage });
  };

  const handleChangeRowsPerPage = (newSize) => {
    setpaginateTable({
      ...paginateTable,
      currentPage: 0,
      pageSize: newSize,
    });
  };

  const createDataRow = (transaction) => ({
    id: transaction.id,
    amount: transaction?.amount,
    remarks: transaction?.remarks,
    status: transaction?.status,
    // actions: transaction,
  });

  const rows = liquidityList?.results?.length > 0 ? liquidityList.results.map((transaction) => createDataRow(transaction)) : [];

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
      minWidth: 60,
      // maxWidth: 200,
      headerClassName: 'header',
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <Tooltip sx={{ cursor: 'default' }} title={params.formattedValue}>
            <Typography variant='body2' style={tableCellStyle}>
              {params.formattedValue}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: 'amount',
      headerName: 'Amount',
      flex: 1.5,
      minWidth: 250,
      // maxWidth: 200,
      textOverflow: 'ellipsis',
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Typography variant='body2' style={tableCellStyle}>
            {formatCurrency(params.formattedValue, currency?.decimal_place, currency?.code, currency?.symbol_prefix)}
          </Typography>
        );
      },
    },
    {
      field: 'remarks',
      headerName: 'Remarks',
      flex: 10,
      minWidth: 150,
      // maxWidth: 200,
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Typography variant='body2' style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      // flex: 1,
      minWidth: 60,
      // maxWidth: 150,
      headerClassName: 'header',
      renderCell: (params) => {
        switch (params.formattedValue) {
          case 'Approved':
            return (
              <Label variant={'ghost'} color={'pending'} sx={{ textTransform: 'capitalize' }}>
                {params.formattedValue ?? '--'}
              </Label>
            );

          case 'Requested':
            return (
              <Label variant={'ghost'} color={'application submitted'} sx={{ textTransform: 'capitalize' }}>
                {params.formattedValue ?? '--'}
              </Label>
            );
          case 'Transferred':
            return (
              <Label variant={'ghost'} color={'complete'} sx={{ textTransform: 'capitalize' }}>
                {params.formattedValue ?? '--'}
              </Label>
            );

          default:
            return (
              <Label variant={'ghost'} color={'rejected'} sx={{ textTransform: 'capitalize' }}>
                {params.formattedValue ?? '--'}
              </Label>
            );
        }
      },
    },
  ];

  return (
    <>
      <Box
        sx={{
          boxShadow: 'rgba(99, 99, 99, 0.2) 0px 0px 8px 0px',
          borderRadius: '8px',
          width: '100%',
          '& .MuiDataGrid-virtualScrollerRenderZone': {
            '& .MuiDataGrid-row': {
              fontWeight: 500,
              color: '#667085',
              fontFamily: 'Inter, sans-serif',
              fontSize: '14px',
            },
          },
          '& .header': {
            backgroundColor: '#F4F4F4',
            '& div': {
              '& div': {
                '& div': {
                  '& div': {
                    fontSize: '14px',
                    fontWeight: 600,
                    fontFamily: 'Inter, sans-serif',
                  },
                },
              },
            },
          },
        }}
      >
        <DataGrid
          autoHeight
          sx={{
            ...tableStyle,
            width: '100%',
            cursor: 'pointer',
          }}
          components={{
            LoadingOverlay: LinearProgress,
          }}
          onRowClick={(row) => {
            console.log(Routes.Liquidity.path + `/${row?.id}`);
            history.push(Routes.Liquidity.path + `/${row?.id}`);
          }}
          rows={rows}
          columns={columns}
          rowHeight={50}
          disableColumnFilter={true}
          disableColumnMenu={true}
          pagination
          paginationMode='server'
          rowsPerPageOptions={rowsPerPageOptions}
          page={paginateTable.currentPage}
          rowCount={liquidityList?.count}
          pageSize={paginateTable.pageSize}
          onPageChange={(newPage) => handleChangePage(newPage)}
          onPageSizeChange={(newSize) => handleChangeRowsPerPage(newSize)}
          loading={fetchLiquidityLoading}
        />
      </Box>
      {showDeleteModal && <DeleteModal onClose={toggleShowDeleteModal} isOpen={showDeleteModal} onSubmit={confirmDelete} text={'the account'} />}
    </>
  );
}
