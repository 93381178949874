import React from 'react';
import { View } from '@react-pdf/renderer';
import PreviewContainer from './PreviewContainer';
import PreviewItem from './PreviewItem';

const RecipientSection = ({ receiver_account, type, receiver }) => {
  const recipientType = type.split('2')[1];
  let recipientContent;
  if (recipientType === 'p') {
    recipientContent = (
      <>
        <PreviewItem title={'First Name'} content={receiver?.first_name} />
        <PreviewItem title={'Last Name'} content={receiver?.last_name} />
      </>
    );
  } else if (recipientType === 'b') {
    recipientContent = (
      <>
        <PreviewItem title={'Business Name'} content={receiver?.business_name} />
        <PreviewItem title={'Primary Contact Name'} content={receiver?.primary_contact_name} />
      </>
    );
  }
  let financialDetails = '';
  const acType = receiver_account.type;
  if (acType === 'venmo') {
    financialDetails = (
      <>
        <PreviewItem title={'Venmo ID'} content={receiver_account?.venmo_id} />
      </>
    );
  } else if (acType === 'paypal') {
    financialDetails = (
      <>
        <PreviewItem title={'PayPal Email'} content={receiver_account?.email} />
      </>
    );
  } else if (acType === 'bank') {
    const { name, account_no, lei_code, branch, iban, swift_code, transit_number, aba_number, address, ifsc_code } = receiver_account.bank;
    financialDetails = (
      <>
        <PreviewItem title={'Bank Name'} content={name} />
        <PreviewItem title={'Branch'} content={branch} /> <PreviewItem title={'Account Number'} content={account_no} />{' '}
        <PreviewItem title={'LEI Code'} content={lei_code} /> <PreviewItem title={'IBAN'} content={iban} />{' '}
        <PreviewItem title={'SWIFT BIC Code'} content={swift_code} />
        <PreviewItem title={'Transit Number'} content={transit_number} />
        <PreviewItem title={'ABA Routing Number'} content={aba_number} />
        <PreviewItem title={'IFSC Code'} content={ifsc_code} />
        <PreviewItem title={'Address'} content={address} />
      </>
    );
  }

  return (
    <View style={{ marginBottom: '12px', display: 'flex', flexDirection: 'row' }}>
      <View style={{ marginRight: '8px' }}>
        <PreviewContainer title={'Recipient  Details'}>
          {recipientContent}
          <PreviewItem title={'Phone'} content={receiver?.primary_contact_no} />
          <PreviewItem title={'City'} content={receiver?.city?.name} />
          <PreviewItem title={'Province'} content={receiver?.province?.name} />
          <PreviewItem title={'Country'} content={receiver?.country?.name} />
          <PreviewItem title={'Address'} content={receiver?.address} />
        </PreviewContainer>
      </View>
      <View style={{ minWidth: '150px' }}>
        <PreviewContainer title={'Financial Details'}>{financialDetails}</PreviewContainer>
      </View>
    </View>
  );
};

export default RecipientSection;
