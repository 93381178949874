import * as actionTypes from '../actionTypes';

const initialState = {
  userRole: 'abc',
  isSingleWallet: true,
  userPayString: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_USER_ROLE:
      return {
        ...state,
        userRole: action.payload,
      };
    case actionTypes.SET_IS_SINGLE_WALLET:
      return {
        ...state,
        isSingleWallet: action.payload,
      };
    case actionTypes.SET_USER_PAYSTRING:
      return {
        ...state,
        userPayString: action.payload,
      };

    case actionTypes.CLEAR_STORE_DATA:
      return {};
    default:
      return state;
  }
};

export default authReducer;
