import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    borderRadius: '8px',
    borderColor: '#000',
    backgroundColor: '#F0F0F0',
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '1000px',
    // flex: '1',
  },
  contentContainer: {
    marginTop: '10px',
    display: 'flex',
    flexBasis: '150px',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
});

const PreviewContainer = ({ children, title }) => {
  return (
    <View style={styles.container}>
      <Text>{title}</Text>
      <View style={styles.contentContainer}>{children}</View>
    </View>
  );
};

export default PreviewContainer;
