import React from 'react';
import { LinearProgress, Stack, Tooltip, IconButton, Typography, Box, Link } from '@mui/material';

import { format } from 'date-fns';
import LinkRoundedIcon from '@mui/icons-material/LinkRounded';
import { startCase } from 'lodash';

import { formatCurrency, formatWithoutTimezone, rowsPerPageOptions, tableCellStyle } from '../../../../utils/common';

import { DataGrid } from '@mui/x-data-grid';
import { Routes } from '../../../../routes';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_TRANSACTION_REPORT } from '../../../../redux/actionTypes';

export default function TransactionReprotTable({ translist, loading, accountTitle }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const filters = useSelector((state) => state.filters.reports.transactionReport);

  const handleChangePage = (newPage) => {
    dispatch({
      type: UPDATE_TRANSACTION_REPORT,
      payload: {
        currentPage: newPage,
      },
    });
  };

  const handleChangeRowsPerPage = (newSize) => {
    dispatch({
      type: UPDATE_TRANSACTION_REPORT,
      payload: {
        currentPage: 0,
        pageSize: newSize,
      },
    });
  };

  // Datagrid Components

  console.log(loading);

  const createDataRow = (transaction) => ({
    id: transaction.reference_no,
    lastUpdate: formatWithoutTimezone(transaction.created_date, 'dd MMM yyyy HH:mm a'),

    wallet: accountTitle,
    counterParty: transaction?.other_party || '--',
    amount: `${transaction.amount_type === 'credit' ? '+' : '-'} ${formatCurrency(
      transaction.amount_type === 'credit' ? transaction?.credit : transaction?.debit,
      transaction?.coin?.decimal_place,
      transaction?.coin?.symbol
    )}`,
    balance: formatCurrency(transaction?.balance_post_transaction, transaction?.coin?.decimal_place, transaction?.coin?.symbol),
    relatedTo: {
      transactionType: transaction?.transaction_type,
      relatedTo: transaction?.related_transaction,
    },
    actions: transaction?.blockchain_url,
    transactionData: transaction,
  });

  const rows = translist?.results?.length > 0 ? translist.results.map((transaction) => createDataRow(transaction)) : [];

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
      minWidth: 100,
      // maxWidth: 200,
      headerClassName: 'header',
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <Tooltip placement='bottom' sx={{ cursor: 'default' }} title={params.formattedValue}>
            <Typography variant='body2' style={tableCellStyle}>
              {params.formattedValue}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: 'lastUpdate',
      headerName: 'Date',
      flex: 1,
      minWidth: 180,
      // maxWidth: 200,
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Typography variant='body2' style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
    {
      field: 'wallet',
      headerName: 'Wallet',
      flex: 1.5,
      minWidth: 250,
      // maxWidth: 200,
      textOverflow: 'ellipsis',
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Typography variant='body2' style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },

    {
      field: 'amount',
      headerName: 'Amount',
      flex: 1,
      minWidth: 200,
      // maxWidth: 200,
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Typography variant='body2' style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },

    // },
    {
      field: 'balance',
      headerName: 'Balance',
      flex: 1,
      minWidth: 250,
      // maxWidth: 150,
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Typography variant='body2' style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },

    {
      field: 'type',
      headerName: 'Type',
      flex: 1.5,
      minWidth: 200,
      // maxWidth: 200,
      textOverflow: 'ellipsis',
      headerClassName: 'header',
      renderCell: (params) => {
        console.log(params.row.transactionData);
        const { flow_type, type } = params.row.transactionData.transaction_type ?? {};
        return (
          <Typography variant='body2' style={tableCellStyle}>
            {startCase(`${flow_type} ${type}`)}
          </Typography>
        );
      },
    },

    {
      field: 'remarks',
      headerName: 'Remarks',
      flex: 1.5,
      minWidth: 300,
      // maxWidth: 200,
      textOverflow: 'ellipsis',
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Tooltip placement='top' title={params?.row?.transactionData?.remarks}>
            <Typography variant='body2' style={tableCellStyle}>
              {params?.row?.transactionData?.remarks ?? '--'}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: 'relatedTo',
      headerName: 'Related To',
      flex: 1.5,
      minWidth: 150,
      align: 'center',
      headerAlign: 'center',
      // maxWidth: 200,
      headerClassName: 'header',
      renderCell: (params) => {
        const { transactionType, relatedTo } = params.formattedValue;
        const type = transactionType?.type;
        const flow_type = transactionType?.flow_type;
        const linkClickHandler = () => {
          if (type === 'remittance') {
            if (flow_type === 'inward') {
              history.push(Routes.InwardRemittance.path + `/${relatedTo.id}`);
            } else if (flow_type === 'outward') {
              history.push(Routes.OutwardRemittance.path + `/${relatedTo.id}`);
            }
          } else if (type === 'redemption') {
            if (flow_type === 'received') {
              history.push(Routes.RedemptionsReceived.path + `/details/${relatedTo.id}`);
            } else if (flow_type === 'raised') {
              history.push(Routes.RedemptionsRaised.path + `/details/${relatedTo.id}`);
            }
          } else if (type === 'fund') {
            if (flow_type === 'liquidity') {
              history.push(Routes.Liquidity.path + `/${relatedTo?.id}`);
              // history.push(Routes.Deposits.path + `/${relatedTo?.id}`);
            }
          }
        };
        return relatedTo?.ref_no === '' ? (
          '--'
        ) : (
          <Link component='span' variant='subtitle2' color='text.primary' sx={{ cursor: 'pointer', fontWeight: '500' }} onClick={linkClickHandler}>
            {relatedTo?.ref_no === '' ? '--' : relatedTo?.ref_no}
          </Link>
        );
      },
    },
    {
      field: 'counterParty',
      headerName: 'Counter Party',
      flex: 1.5,
      minWidth: 300,
      // maxWidth: 200,
      textOverflow: 'ellipsis',
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Typography variant='body2' style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      // align: 'left',
      flex: 1,
      minWidth: 140,
      // maxWidth: 140,
      headerClassName: 'header',
      renderCell: (params) => {
        console.log(params);
        let isInternal = !params?.formattedValue?.includes('https://testnet.xrpl.org/');
        return (
          <Stack>
            <Tooltip placement='bottom' title={isInternal ? params.formattedValue : 'View in Blockchain'}>
              <span>
                <IconButton disabled={isInternal} href={params?.formattedValue} target={'_blank'}>
                  <LinkRoundedIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
        );
      },
    },
  ];

  return (
    <>
      <Box
        sx={{
          // height: 825,
          boxShadow: 'rgba(99, 99, 99, 0.2) 0px 0px 8px 0px',
          borderRadius: '8px',
          width: '100%',
          '& .MuiDataGrid-virtualScrollerRenderZone': {
            '& .MuiDataGrid-row': {
              // backgroundColor: 'rgba(235, 235, 235, .7)',
              fontWeight: 500,
              color: '#667085',
              fontFamily: 'Inter, sans-serif',
              fontSize: '14px',
            },
          },
          '& .header': {
            backgroundColor: '#F4F4F4',
            '& div': {
              '& div': {
                '& div': {
                  '& div': {
                    fontSize: '14px',
                    fontWeight: 600,
                    fontFamily: 'Inter, sans-serif',
                  },
                },
              },
            },
          },
        }}
      >
        <DataGrid
          autoHeight
          sx={{
            borderRadius: '8px',
            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 0px 8px 0px',
            width: '100%',
          }}
          // componentsProps={{
          //   cell: {
          //     onMouseEnter: handlePopoverOpen,
          //     onMouseLeave: handlePopoverClose,
          //   },
          // }}
          components={{
            LoadingOverlay: LinearProgress,
            // Footer
          }}
          rows={rows}
          columns={columns}
          loading={loading}
          rowHeight={50}
          disableColumnFilter={true}
          disableColumnMenu={true}
          pagination
          paginationMode='server'
          rowsPerPageOptions={rowsPerPageOptions}
          page={filters.currentPage}
          rowCount={translist.count}
          pageSize={filters.pageSize}
          onPageChange={(newPage) => handleChangePage(newPage)}
          onPageSizeChange={(newSize) => handleChangeRowsPerPage(newSize)}
        />
      </Box>
    </>
  );
}
