import React from 'react';
import { Stack, Card, Box } from '@mui/material';
import HistoryItem from './HistoryItem';

const StatusHistory = ({ statuses, type }) => {
  return (
    <Box sx={{ mt: 5 }}>
      <Card sx={{ p: 3 }}>
        <Stack spacing={3} alignItems='center'>
          {statuses?.map((status) => (
            <HistoryItem status={status} type={type} />
          ))}
        </Stack>
      </Card>
    </Box>
  );
};

export default StatusHistory;
