export const GET_PROFILE = 'GET_PROFILE';
export const SET_PROFILE = 'SET_PROFILE';
export const GET_PROFILE_FAIL = 'GET_PROFILE_FAIL';

// CONFIG
export const GET_CONFIG = 'GET_CONFIG';
export const SET_CONFIG = 'SET_CONFIG';

export const GET_WALLETS = 'GET_WALLETS';

export const GET_COINS = 'GET_COINS';

export const SET_CURRENCY = 'SET_CURRENCY';
export const SET_DEPOSIT_TYPES = 'SET_DEPOSIT_TYPES';
export const SET_REMITTANCE_TYPES = 'SET_REMITTANCE_TYPES';
export const SET_PURPOSE_TYPES = 'SET_PURPOSE_TYPES';
export const SET_COUNTRIES_DATA = 'SET_COUNTRIES_DATA';
export const SET_ACCOUNT_TYPES = 'SET_ACCOUNT_TYPES';
export const SET_REMITTANCE_STATUSES = 'SET_REMITTANCE_STATUSES';
export const SET_REDEEM_STATUSES = 'SET_REDEEM_STATUSES';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const SHOW_SIDEBAR = 'SHOW_SIDEBAR';
export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';
export const GET_PERMISSIONS = 'GET_PERMISSIONS';
export const SET_PERMISSIONS = 'SET_PERMISSIONS';

//KYB

export const GET_KYB_STATUS = 'GET_KYB_STATUS';
export const SET_KYB_STATUS = 'SET_KYB_STATUS';
export const GET_KYB_STATUS_FAIL = 'GET_KYB_STATUS_FAIL';
export const SET_KYB_FORM_DATA = 'SET_KYB_FORM_DATA';

export const GET_BUSINESSTYPE = 'GET_BUSINESSTYPE';
export const SET_BUSINESSTYPE = 'SET_BUSINESSTYPE';
export const GET_BUSINESSCATEGORY = 'GET_BUSINESSCATEGORY';
export const SET_BUSINESSCATEGORY = 'SET_BUSINESSCATEGORY';
export const SET_BUSINESS_DETAILS = 'SET_BUSINESS_DETAILS';

export const GET_STORE_LIST = 'GET_STORE_LIST';
export const SET_STORE_LIST = 'SET_STORE_LIST';

// User
export const SET_USER_ROLE = 'SET_USER_ROLE';
export const SET_IS_SINGLE_WALLET = 'SET_IS_SINGLE_WALLET';
export const SET_USER_PAYSTRING = 'SET_USER_PAYSTRING';

export const CLEAR_STORE_DATA = 'CLEAR_STORE_DATA';

// KYC
export const SET_KYC_DOC_TYPES = 'SET_KYC_DOC_TYPES';
export const SET_TICKETS = 'SET_TICKETS';
export const SET_CONVERSATION = 'SET_CONVERSATION';
export const RESET_ACTIVE_KEY = 'RESET_ACTIVE_KEY';
export const SET_LOADING = 'SET_LOADING';
export const RESET_LOADING = 'RESET_LOADING';

// Filters
export const UPDATE_OUTWARD_REMITTANCE = 'UPDATE_OUTWARD_REMITTANCE';
export const CLEAR_OUTWARD_REMITTANCE = 'CLEAR_OUTWARD_REMITTANCE';
export const UPDATE_CUSTOMER_CREATED_REMITTANCE =
  'UPDATE_CUSTOMER_CREATED_REMITTANCE';
export const CLEAR_CUSTOMER_CREATED_REMITTANCE =
  'CLEAR_CUSTOMER_CREATED_REMITTANCE';

export const UPDATE_INWARD_REMITTANCE = 'UPDATE_INWARD_REMITTANCE';
export const CLEAR_INWARD_REMITTANCE = 'CLEAR_INWARD_REMITTANCE';
export const UPDATE_AVAILABLE_SETTLEMENT = 'UPDATE_AVAILABLE_SETTLEMENT';
export const CLEAR_AVAILABLE_SETTLEMENT = 'CLEAR_AVAILABLE_SETTLEMENT';
export const UPDATE_REQUEST_RAISED = 'UPDATE_REQUEST_RAISED';
export const CLEAR_REQUEST_RAISED = 'CLEAR_REQUEST_RAISED';
export const UPDATE_REQUEST_RECEIVED = 'UPDATE_REQUEST_RECEIVED';
export const CLEAR_REQUEST_RECEIVED = 'CLEAR_REQUEST_RECEIVED';
export const UPDATE_TRANSACTION = 'UPDATE_TRANSACTIONS';
export const CLEAR_TRANSACTION = 'CLEAR_TRANSACTION';
export const UPDATE_DEPOSIT = 'UPDATE_DEPOSIT';
export const CLEAR_DEPOSIT = 'CLEAR_DEPOSIT';
export const UPDATE_TRANSACTION_REPORT = 'UPDATE_TRANSACTION_REPORT';
export const CLEAR_TRANSACTION_REPORT = 'CLEAR_TRANSACTION_REPORT';
export const UPDATE_DEPOSIT_REPORT = 'UPDATE_DEPOSIT_REPORT';
export const CLEAR_DEPOSIT_REPORT = 'CLEAR_DEPOSIT_REPORT';
export const UPDATE_OUT_REM_REPORT = 'UPDATE_OUT_REM_REPORT';
export const CLEAR_OUT_REM_REPORT = 'CLEAR_OUT_REM_REPORT';
export const UPDATE_IN_REM_REPORT = 'UPDATE_IN_REM_REPORT';
export const CLEAR_IN_REM_REPORT = 'CLEAR_IN_REM_REPORT';
export const UPDATE_REMDEMPTION_REPORT = 'UPDATE_REMDEMPTION_REPORT';
export const CLEAR_REDEMPTION_REPORT = 'CLEAR_REDEMPTION_REPORT';

export const UPDATE_EXCHANGE_OFFER_HISTORY = 'UPDATE_EXCHANGE_OFFER_HISTORY';
export const CLEAR_EXCHANGE_OFFER_HISTORY = 'CLEAR_EXCHANGE_OFFER_HISTORY';

export const SET_NOTIFICATION_HISTORY = 'SET_NOTIFICATION_HISTORY';
export const SET_NEXT_NOTIFICATIONS = 'SET_NEXT_NOTIFICATIONS';
export const SET_NEW_NOTIFICATION_AVAILABLE = 'SET_NEW_NOTIFICATION_AVAILABLE';
