import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faEnvelope, faUnlockAlt } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Card, Button, Container, InputGroup } from '@themesberg/react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { Routes } from '../../routes';
import TextField from '@material-ui/core/TextField';
import { useFormik } from 'formik';
import * as yup from 'yup';
import * as API from '../../ApiUrl';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Loader } from 'rsuite';
import changepass from '../../assets/img/enterpassword.gif';
import { useDispatch } from 'react-redux';
import * as actionTypes from '../../redux/actionTypes';

const queryString = require('query-string');
const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;

const validationSchema = yup.object({
  password: yup
    .string('Enter your password')
    .matches(passwordRegex, 'Please enter at least 8 character, 1 lowercase, 1 uppercase, 1  special character & 1 number')
    .min(8, 'Password should be of minimum 8 characters length')
    .required('New Password is required'),

  confirmpassword: yup
    .string('Enter your password')
    .oneOf([yup.ref('password')], 'Password does not match')
    .required('Confirm Password is Required'),
});

export default () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const parsed = queryString.parse(history.location.search);

  const [loading, setLoading] = React.useState(false);
  const [isButtonLoading, setIsButtonLoading] = React.useState(false);

  const onSubmit = async (values) => {
    setLoading(true);
    const data = {
      new_password: values.password,
      token: parsed.token,
    };
    try {
      const response = await axios.post(API.NEW_PASSWORD, data);
      console.log('reset data', response);
      toast.success('Password changed successfully');
      localStorage.clear();
      dispatch({ type: actionTypes.CLEAR_STORE_DATA });
      history.push(Routes.Signin.path);
      console.log(response);
    } catch (err) {
      toast.error(err?.data?.data?.message || 'Something went wrong');
    }
    setLoading(false);
  };
  const formik = useFormik({
    initialValues: {
      password: '',
      confirmpassword: '',
    },
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  });
  return (
    <main>
      <section className='vh-lg-100 mt-4 mt-lg-0 bg-white d-flex align-items-center'>
        <Container>
          <Row className='justify-content-center'>
            <Col xl={6}>
              <img src={changepass} />
            </Col>
            <Col xl={6} className='d-flex align-items-center justify-content-center'>
              <div className='bg-white p-4 p-lg-5 w-100'>
                <h3 className='mb-2'>Reset password</h3>
                <Form onSubmit={formik.handleSubmit}>
                  <TextField
                    fullWidth
                    variant='outlined'
                    className='mt-4 font-fam'
                    id='password'
                    name='password'
                    label='New Password'
                    type='password'
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                  />

                  <TextField
                    fullWidth
                    variant='outlined'
                    className='mt-4 font-fam'
                    id='confirmpassword'
                    name='confirmpassword'
                    label='Confirm Password'
                    type='password'
                    value={formik.values.confirmpassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.confirmpassword && Boolean(formik.errors.confirmpassword)}
                    helperText={formik.touched.confirmpassword && formik.errors.confirmpassword}
                  />

                  <Button
                    variant='primary'
                    onClick={() => {
                      setIsButtonLoading(true);
                      setTimeout(() => {
                        setIsButtonLoading(false);
                      }, 2000);
                    }}
                    isLoading={isButtonLoading}
                    disabled={!formik.isValid}
                    className='mt-4 w-100'
                    type='submit'
                  >
                    Reset Password
                  </Button>

                  <ToastContainer />
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
