import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'rsuite/dist/styles/rsuite-default.css';
import * as API from '../../../ApiUrl';
import RedemptionTable from './components/redemptionTable/RedemptionTable';
import { merchantApi } from '../../../redux/api/apiConfig';
import StatusModal from '../../remittance/inward/components/statusModal/StatusModal';
import PassphraseModal from '../../remittance/inward/components/passphraseModal/PassphraseModal';
import { send } from '../../../utils/xrpl_functions';
import { format } from 'date-fns';
import { Autocomplete, Box, Divider, InputAdornment, MenuItem, Stack, TextField } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../../routes';
import usePageTitle from '../../../hooks/usePageTitle';
import { reportTimePeriods } from '../../reports/common';
import { formatDate } from '../../../utils/common';
import { FilterDropdown } from '../../reports/components';
import { StyledDatePicker } from '../../../components/datePicker';
import { ButtonResetFilter, Iconify } from '../../../components';
import { CLEAR_REQUEST_RECEIVED, UPDATE_REQUEST_RECEIVED } from '../../../redux/actionTypes';

function RedemptionRequestReceived() {
  const [translist, setTranslist] = useState([]);
  const [fetchTranslistLoading, setFetchTranslistLoading] = useState(false);
  const [transactionData, setTransactionData] = useState('');
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showPassphraseModal, setShowPassphraseModal] = useState(false);
  const [mtoPatchLoading, setMtoPatchLoading] = useState(false);
  const [passphraseLoading, setPassphraseLoading] = useState(false);
  const { walletsReducer, configReducer } = useSelector((state) => state);
  const { coins = [], wallets = [] } = walletsReducer;
  const { redeemStatuses: allStatuses = [] } = configReducer;

  const history = useHistory();
  const dispatch = useDispatch();

  const filters = useSelector((state) => state.filters.settlements.requestReceived);
  console.log(filters);
  usePageTitle('Settlement Requests Received', 'View your incoming redemption requests');

  const handleClearFilters = () => {
    dispatch({ type: CLEAR_REQUEST_RECEIVED });
  };

  const getTrans = useCallback(async () => {
    setFetchTranslistLoading(true);
    const { calenderValue, pageSize, currentPage, search, coin, status } = filters;
    const created_date_before = formatDate(calenderValue[1], 'yyyy-MM-dd');
    const created_date_after = formatDate(calenderValue[0], 'yyyy-MM-dd');

    let params = {
      offset: currentPage * pageSize,
      limit: pageSize,
      search,
      coin: coin?.id,
      status: status?.id,
      created_date_after,
      created_date_before,
    };

    merchantApi
      .get(`${API.REDEEM_RECEIVED_LIST}`, {
        params,
      })
      .then((response) => {
        setFetchTranslistLoading(false);
        console.log(response);
        if (response.data) {
          setTranslist(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.data?.data?.message || 'Something went wrong');
        setFetchTranslistLoading(false);
      });
  }, [filters]);

  useEffect(() => {
    const timer = setTimeout(() => {
      getTrans();
    }, 500);

    return () => clearTimeout(timer);
  }, [
    filters.currentPage,
    filters.pageSize,
    filters.search,
    filters.coin,
    filters.status,
    filters.calenderValue,
    getTrans,
    // resetData,
  ]);

  const viewDetailsHandler = (id) => {
    history.push(Routes.RedemptionsReceived.path + `/details/${id}`);
  };

  const toggleStatusModal = () => {
    setShowStatusModal((state) => !state);
  };

  const updateStatusClickHandler = (transaction) => {
    console.log(transaction);
    setTransactionData(transaction);
    toggleStatusModal();
  };

  const updateClickHandler = async (status, remarks) => {
    updateStatus(status, remarks);
  };

  const passphraseSubmitHandler = async (passphrase) => {
    const remarks = localStorage.getItem('remarks');
    setPassphraseLoading(true);
    console.log(passphrase, transactionData);
    const { send_amount: amountToReceive, receive_amount: amuntToSend, receive_currency, send_currency } = transactionData;

    const { issuer_address: issuerSend, symbol: senderCurrency } = receive_currency;

    const fromAddress = wallets[0].address;

    const { issuer_address: issuerRecv, symbol: receiverCurrency } = send_currency;

    const { address: toAddress } = transactionData.sender.xrp_address;
    try {
      const blob = await send(
        fromAddress,
        toAddress,
        passphrase,
        amuntToSend,
        amountToReceive,
        senderCurrency,
        receiverCurrency,
        issuerSend,
        issuerRecv
      );
      console.log(blob);
      if (blob) {
        const response = await merchantApi.post(API.TRANSACTION, {
          remittance: transactionData.id,
          blob,
          type: 'refund',
          remarks,
        });
        if (response.status === 200) {
          toast.success('Remittance refunded successfully');
        }
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.data?.data?.message || 'Something went wrong');
    } finally {
      setPassphraseLoading(false);
      setShowPassphraseModal(false);
      toggleStatusModal();
      getTrans();
    }
  };

  const updateStatus = async (status, remarks) => {
    try {
      setMtoPatchLoading(true);
      const response = await merchantApi.patch(`${API.REDEEM_RECEIVED_LIST}${transactionData.id}/`, {
        status,
        remarks,
      });
      console.log(response);
      setMtoPatchLoading(false);
      toggleStatusModal();
      getTrans();
    } catch (error) {
      setMtoPatchLoading(false);
      toast.error(error?.data?.data?.message || 'Something went wrong');
    }
  };

  return (
    <>
      <Box sx={{ padding: '0 24px 24px' }}>
        <Stack
          sx={{
            overflowX: { xs: 'auto' },
            overflowY: { xs: 'visible', md: 'unset' },
            padding: '5px 0',
          }}
          direction={'row'}
          justifyContent={'space-between'}
          gap={1}
          mt={2}
          width={'100%'}
        >
          <Stack sx={{ flexDirection: 'row', gap: 1 }} divider={<Divider orientation='vertical' flexItem sx={{ borderStyle: 'dashed' }} />}>
            <Stack sx={{ minWidth: '150px', flex: 1 }}>
              <FilterDropdown
                name='timeperiod'
                type='text'
                label='Timeperiod'
                options={reportTimePeriods}
                onChange={(e) => {
                  const name = e.target.value;
                  const period = reportTimePeriods.find((report) => report.name === name);
                  console.log(new Date(period.from), new Date(filters.calenderValue[1]), new Date(period.from) > new Date(filters.calenderValue[1]));
                  let toValue;
                  if (filters.calenderValue[1]) {
                    toValue = new Date(period.from) > new Date(filters.calenderValue[1]) ? null : filters.calenderValue[1];
                  } else {
                    toValue = null;
                  }
                  dispatch({
                    type: UPDATE_REQUEST_RECEIVED,
                    payload: {
                      calenderValue: [new Date(period.from), toValue],
                      period,
                    },
                  });
                }}
                value={filters?.period?.name}
                size={'small'}
                render={(period, i) => (
                  <MenuItem key={period.name} value={period.name}>
                    {period.name}
                  </MenuItem>
                )}
              />
            </Stack>
            <Stack sx={{ flexDirection: 'row', gap: 1 }}>
              <Stack sx={{ width: '176px', flex: 1 }}>
                <StyledDatePicker
                  value={filters.calenderValue[0]}
                  disableFuture
                  format={'dd/MM/yyyy'}
                  onChange={(newValue) => {
                    dispatch({
                      type: UPDATE_REQUEST_RECEIVED,
                      payload: {
                        calenderValue: [
                          new Date(format(newValue, 'yyyy/MM/dd')),
                          newValue > filters.calenderValue[1] ? undefined : filters.calenderValue[1],
                        ],
                      },
                    });
                  }}
                />
              </Stack>

              <Stack sx={{ width: '176px', flex: 1 }}>
                <StyledDatePicker
                  value={filters.calenderValue[1]}
                  disableFuture
                  // minDate={filters.calenderValue[0] ?? undefined}
                  format={'dd/MM/yyyy'}
                  onChange={(newValue) => {
                    dispatch({
                      type: UPDATE_REQUEST_RECEIVED,
                      payload: {
                        calenderValue: [
                          newValue < filters.calenderValue[0] ? undefined : filters.calenderValue[0],
                          new Date(format(newValue, 'yyyy/MM/dd')),
                        ],
                      },
                    });
                  }}
                />
              </Stack>
            </Stack>

            <Stack sx={{ flexDirection: 'row', gap: 1, flex: 1 }}>
              {coins && (
                <Stack sx={{ minWidth: '205px', flex: 1 }}>
                  <Autocomplete
                    id='currency'
                    value={filters.coin}
                    onChange={(e, newValue) => {
                      dispatch({
                        type: UPDATE_REQUEST_RECEIVED,
                        payload: {
                          currentPage: 0,
                          coin: newValue,
                        },
                      });
                    }}
                    options={coins}
                    getOptionLabel={(option) => `${option?.name}`}
                    renderInput={(params) => {
                      return <TextField placeholder='Select Currency' {...params} size='small' />;
                    }}
                  />
                </Stack>
              )}
              <Stack sx={{ minWidth: '200px', flex: 1 }}>
                <TextField
                  fullWidth
                  size='small'
                  value={filters.search}
                  onChange={(e) =>
                    dispatch({
                      type: UPDATE_REQUEST_RECEIVED,
                      payload: {
                        search: e.target.value,
                      },
                    })
                  }
                  placeholder='Search...'
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <Iconify icon='eva:search-fill' sx={{ color: 'text.disabled' }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
            </Stack>

            <Stack sx={{ minWidth: '200px', flex: 1 }}>
              <Autocomplete
                id='status'
                value={filters.status}
                onChange={(e, newValue) => {
                  console.log(newValue);
                  dispatch({
                    type: UPDATE_REQUEST_RECEIVED,
                    payload: {
                      status: newValue ?? null,
                    },
                  });
                }}
                options={allStatuses}
                getOptionLabel={(option) => option?.sender_name}
                sx={{ flexGrow: 1, width: '100%' }}
                renderInput={(params) => {
                  return <TextField placeholder='Select Status' {...params} size='small' />;
                }}
              />
            </Stack>
          </Stack>
          <Stack sx={{ flexDirection: 'row', gap: 1 }}>
            <ButtonResetFilter onClick={handleClearFilters} />
          </Stack>
        </Stack>

        <RedemptionTable
          translist={translist}
          fetchTranslistLoading={fetchTranslistLoading}
          viewDetails={viewDetailsHandler}
          updateStatus={updateStatusClickHandler}
        />
      </Box>

      {showStatusModal && (
        <StatusModal
          isOpen={showStatusModal}
          onClose={toggleStatusModal}
          updateClickHandler={updateClickHandler}
          mtoPatchLoading={mtoPatchLoading}
          status={transactionData?.current_status}
          canChangeTo={transactionData?.current_status?.sender_can_change_to}
        />
      )}
      {showPassphraseModal && (
        <PassphraseModal
          isOpen={showPassphraseModal}
          onClose={() => setShowPassphraseModal(false)}
          submitClickHandler={passphraseSubmitHandler}
          passphraseLoading={passphraseLoading}
        />
      )}
    </>
  );
}

// () => {
//   updateStatus('rejected', localStorage.getItem('remarks'));
// }
export default RedemptionRequestReceived;
