import React, { useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Button } from 'rsuite';
import { formatCurrency } from '../../../../../utils/common';
import { TextFieldComponent } from '../../../../../components';

import {
  Stack,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  InputAdornment,
  Typography,
  TextField,
  Table,
  TableContainer,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { merchantApi } from '../../../../../redux/api/apiConfig';
import { BULK_OFFER, FEE } from '../../../../../ApiUrl';

const BulkEditModal = ({ onClose, isEdit, isOpen, onSubmit, side, offers }) => {
  const [loading, setLoading] = useState(false);
  const [newRates, setNewRates] = useState([]);

  const updateRate = (coin, rate) => {
    const curRates = [...newRates];

    if (rate === '') {
      let updatedRates = curRates.filter((obj) => Object.keys(obj)[0] !== coin);
      setNewRates(updatedRates);
    } else {
      const index = curRates.findIndex((rate) => Object.keys(rate)[0] === coin);
      if (index === -1) {
        curRates.push({ [coin]: rate });
      } else {
        curRates[index] = { [coin]: rate };
      }
      console.log(curRates);
      setNewRates(curRates);
    }
  };

  const { user } = useSelector((state) => state.profileReducer) || {};
  const { coins } = useSelector((state) => state.walletsReducer) || {};
  const userCurrency = user?.mto?.currency ?? {};

  const updateClickHandler = async (values) => {
    try {
      setLoading(true);
      const response = await merchantApi.post(BULK_OFFER, newRates);
      console.log(response);
      onSubmit();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      toast.error(err?.data?.data?.message || 'Something went wrong');
    }
  };

  const handleCloseModal = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    onClose();
  };

  return (
    <>
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth={'sm'}
        onClose={handleCloseModal}
        scroll={'paper'}
        BackdropProps={{ style: { backgroundColor: 'rgba(11, 5, 35, 0.8)' } }}
        PaperProps={{
          style: {
            boxShadow: 'none',
            borderRadius: '8px',
            // width: '628px',
            // minHeight: '550px',
            // maxHeight: '90vh',
          },
        }}
      >
        <DialogTitle sx={{ textAlign: 'center' }}>{isEdit ? 'Edit Offer' : 'Add New Exchange Offer'}</DialogTitle>

        <DialogContent sx={{ paddingTop: '5px !important', paddingBottom: '0 !important' }}>
          <Stack spacing={1} direction={'column'} alignItems={'center'} mb={1}>
            <TableContainer sx={{ overflow: 'unset' }}>
              <Table>
                <TableHead
                  sx={{
                    borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                    '& th': {
                      backgroundColor: 'transparent',
                      fontSize: '14px',
                      fontWeight: 600,
                      // fontFamily: 'Inter, sans-serif',
                    },
                  }}
                >
                  <TableRow>
                    <TableCell width={40}>#</TableCell>

                    <TableCell align='left'>Currency Pair</TableCell>

                    <TableCell align='left'>Current Rate</TableCell>

                    <TableCell width={190} align='right'>
                      New Rate
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody
                  sx={{
                    '& td': {
                      fontWeight: 500,
                      color: '#667085',
                      // fontFamily: 'Inter, sans-serif',
                      fontSize: '14px',
                    },
                  }}
                >
                  {offers.map((offer, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                      }}
                    >
                      <TableCell>{index + 1}</TableCell>

                      <TableCell align='left'>{`${offer.base_currency.symbol}/${offer.quote_currency.symbol}`}</TableCell>

                      <TableCell align='left'>
                        {formatCurrency(offer.rate, userCurrency.decimal_place, userCurrency.symbol, userCurrency.symbol_prefix)}
                      </TableCell>

                      <TableCell align='right'>
                        <TextField
                          // fullWidth
                          variant='outlined'
                          name={'new_rate'}
                          id={'new_rate'}
                          type={'number'}
                          // value={}
                          onChange={(e) => {
                            console.log(e.target.value, offer.base_currency.symbol);
                            updateRate(offer.base_currency.symbol, e.target.value);
                            // onChange(e);
                            // formik.handleChange(e);
                          }}
                          size={'small'}
                          InputProps={{
                            endAdornment: <InputAdornment position='end'>{userCurrency.symbol}</InputAdornment>,
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </DialogContent>
        <Typography sx={{ px: '2rem' }} variant='caption'>
          *You can leave blank for rates you don't want to update.
        </Typography>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Button className='btn white-btn action-button ' onClick={onClose} appearance='subtle'>
            Cancel
          </Button>
          <Button
            className={`btn action-button primary_color text-white`}
            appearance='primary'
            type={'submit'}
            loading={loading}
            onClick={updateClickHandler}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BulkEditModal;
