import { merchantApi } from '../api/apiConfig';
import {
  SET_NEXT_NOTIFICATIONS,
  SET_NOTIFICATION_HISTORY,
} from '../actionTypes';

import { toast } from 'react-toastify';
import { NOTIFICATION_HISTORY } from '../../ApiUrl';

const getNotificationHistory =
  (page = 0, limit = 5) =>
  async (dispatch) => {
    console.log(page, limit, 'LAST params');
    try {
      const res = await merchantApi.get(NOTIFICATION_HISTORY, {
        params: {
          limit,
          offset: limit * page,
        },
      });
      console.log(res);
      dispatch({
        type: SET_NOTIFICATION_HISTORY,
        payload: res?.data?.results || [],
      });
    } catch (err) {
      console.log(err, 'errr');
      toast.error('Unable to fetch notifications.');
    }
  };

const loadNextNotifications =
  (page = 0, limit = 5) =>
  async (dispatch) => {
    console.log(page, limit, 'LAST params');
    try {
      const res = await merchantApi.get(NOTIFICATION_HISTORY, {
        params: {
          limit,
          offset: limit * page,
        },
      });
      console.log(res);
      dispatch({
        type: SET_NEXT_NOTIFICATIONS,
        payload: {
          notifications: res?.data?.results || [],
          page,
          hasMore: Boolean(res.data.next),
        },
      });
    } catch (err) {
      console.log(err, 'errr');
      toast.error('Unable to fetch notifications.');
    }
  };

export { getNotificationHistory, loadNextNotifications };
