import React from 'react';
import { PieChart, Pie, ResponsiveContainer, Tooltip, Legend } from 'recharts';
import { Skeleton, Stack } from '@mui/material';

const DoughnutChartComp = ({ data, loading = false }) => {
  const content = !loading ? (
    <ResponsiveContainer height='90%' width='100%'>
      <PieChart
        width='100%'
        height='100%'
        margin={{
          left: 15,
          right: 15,
          top: 10,
          bottom: 10,
        }}
      >
        <Pie
          dataKey='value'
          data={data}
          // innerRadius={40}
          outerRadius={90}
          fill='#82ca9d'
        />

        <Tooltip />
        <Legend formatter={(value, entry, index) => <span className='chart-legend'>{value}</span>} />
      </PieChart>
    </ResponsiveContainer>
  ) : (
    <Stack alignItems={'center'} sx={{ margin: '10px 15px', gap: 1 }}>
      <Skeleton animation={'wave'} variant='circular' width={180} height={180} />
      <Skeleton animation={'wave'} variant='text' sx={{ fontSize: '1rem', width: '100%' }} />
    </Stack>
  );

  return content;
};

export default DoughnutChartComp;
