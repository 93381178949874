import * as actionTypes from '../actionTypes';
import { updateObject } from './utility';
import { reportTimePeriods } from '../../pages/reports/common';
import { cloneDeep } from 'lodash';

const initialState = {
  remittances: {
    outward: {
      outwardRemittance: {
        currentPage: 0,
        pageSize: 7,
        search: '',
        coin: null,
        status: null,
        calenderValue: [new Date(reportTimePeriods[1].from), null],
        period: reportTimePeriods[1],
      },
      customerCreatedRemittance: {
        currentPage: 0,
        pageSize: 7,
        search: '',
        coin: null,
        status: null,
        calenderValue: [new Date(reportTimePeriods[1].from), null],
        period: reportTimePeriods[1],
      },
    },
    inward: {
      currentPage: 0,
      pageSize: 7,
      search: '',
      coin: null,
      status: null,
      statusValue: 'processed',
      calenderValue: [new Date(reportTimePeriods[1].from), null],
      period: reportTimePeriods[1],
    },
  },
  settlements: {
    availableSettlements: {
      pageSize: 7,
      coin: null,
      mto: null,
    },
    requestRaised: {
      currentPage: 0,
      pageSize: 7,
      search: '',
      coin: null,
      status: null,
      calenderValue: [new Date(reportTimePeriods[1].from), null],
      period: reportTimePeriods[1],
    },
    requestReceived: {
      currentPage: 0,
      pageSize: 7,
      search: '',
      coin: null,
      status: null,
      calenderValue: [new Date(reportTimePeriods[1].from), null],
      period: reportTimePeriods[1],
    },
  },
  transactions: {
    currentPage: 0,
    pageSize: 7,
    mtoAccount: null,
    currency: null,
    type: '',
    calenderValue: [new Date(reportTimePeriods[1].from), null],
    period: reportTimePeriods[1],
  },
  clients: {
    sender: {
      deposit: {
        currentPage: 0,
        pageSize: 7,
        search: '',
        status: null,
        sender: null,
      },
    },
  },
  reports: {
    transactionReport: {
      format: 'excel',
      calenderValue: [new Date(reportTimePeriods[1].from), null],
      period: reportTimePeriods[1],
      currentPage: 0,
      pageSize: 7,
      mtoAccount: null,
      currency: null,
      amount_max: 0,
      amount_min: 0,
    },
    depositReport: {
      depositTypes: [],
      calenderValue: [new Date(reportTimePeriods[1].from), null],
      period: reportTimePeriods[1],
      currentPage: 0,
      pageSize: 7,
      amount_max: '',
      amount_min: '',
    },
    outRemReport: {
      mto: [],
      status: [],
      format: 'excel',
      calenderValue: [new Date(reportTimePeriods[1].from), null],
      period: reportTimePeriods[1],
      currentPage: 0,
      pageSize: 7,
      sortModel: [],
      mtoAccount: null,
    },
    inRemReport: {
      mto: [],
      status: [],
      format: 'excel',
      calenderValue: [new Date(reportTimePeriods[1].from), null],
      period: reportTimePeriods[1],
      currentPage: 0,
      pageSize: 7,
      sortModel: [],
      mtoAccount: null,
    },
    redemptionReport: {
      mto: [],
      status: [],
      format: 'excel',
      calenderValue: [new Date(reportTimePeriods[1].from), null],
      period: reportTimePeriods[1],
      currentPage: 0,
      pageSize: 7,
      sortModel: [],
      amount_max: 0,
      amount_min: 0,
    },
  },
  configurations: {
    exchangeOffersHistory: {
      currentPage: 0,
      pageSize: 7,
      calenderValue: [null, null],
      user: null,
    },
  },
};

const updateExchangeOfferHistory = (action, state) => {
  let newState = { ...state };
  newState.configurations.exchangeOffersHistory = {
    ...state.configurations.exchangeOffersHistory,
    ...action.payload,
  };
  return newState;
};

const clearExchangeOfferHistory = (action, state) => {
  let newState = { ...state };
  newState.configurations.exchangeOffersHistory = {
    ...initialState.configurations.exchangeOffersHistory,
  };
  return newState;
};

const updateOutwardRemittance = (action, state) => {
  let newState = { ...state };
  newState.remittances.outward.outwardRemittance = {
    ...state.remittances.outward.outwardRemittance,
    ...action.payload,
  };
  return newState;
};

const clearOutwardRemittance = (action, state) => {
  let newState = { ...state };
  newState.remittances.outward.outwardRemittance = {
    ...initialState.remittances.outward.outwardRemittance,
  };
  return newState;
};

const updateCustomerCreatedRemittance = (action, state) => {
  let newState = { ...state };
  newState.remittances.outward.customerCreatedRemittance = {
    ...state.remittances.outward.customerCreatedRemittance,
    ...action.payload,
  };
  return newState;
};

const clearCustomerCreatedRemittance = (action, state) => {
  let newState = { ...state };
  newState.remittances.outward.customerCreatedRemittance = {
    ...initialState.remittances.outward.customerCreatedRemittance,
  };
  return newState;
};

const updateInwardRemittance = (action, state) => {
  let newState = { ...state };
  newState.remittances.inward = {
    ...state.remittances.inward,
    ...action.payload,
  };
  return newState;
};

const clearInwardRemittance = (action, state) => {
  let newState = { ...state };
  newState.remittances.inward = {
    ...initialState.remittances.inward,
  };
  return newState;
};

const updateAvailableSettlement = (action, state) => {
  let newState = { ...state };
  newState.settlements.availableSettlements = {
    ...state.settlements.availableSettlements,
    ...action.payload,
  };
  return newState;
};

const clearAvailableSettlement = (action, state) => {
  let newState = { ...state };
  newState.settlements.availableSettlements = {
    ...initialState.settlements.availableSettlements,
  };
  return newState;
};
const updateRequestRaised = (action, state) => {
  let newState = { ...state };
  newState.settlements.requestRaised = {
    ...state.settlements.requestRaised,
    ...action.payload,
  };
  return newState;
};

const clearRequestRaised = (action, state) => {
  let newState = { ...state };
  newState.settlements.requestRaised = {
    ...initialState.settlements.requestRaised,
  };
  return newState;
};

const updateRequestReceived = (action, state) => {
  let newState = { ...state };
  newState.settlements.requestReceived = {
    ...state.settlements.requestReceived,
    ...action.payload,
  };
  return newState;
};

const clearRequestReceived = (action, state) => {
  let newState = { ...state };
  newState.settlements.requestReceived = {
    ...initialState.settlements.requestReceived,
  };
  return newState;
};

const updateTransaction = (action, state) => {
  let newState = { ...state };
  newState.transactions = {
    ...state.transactions,
    ...action.payload,
  };
  return newState;
};

const clearTransaction = (action, state) => {
  let newState = { ...state };
  newState.transactions = {
    ...initialState.transactions,
  };
  return newState;
};

const updateDeposit = (action, state) => {
  let newState = { ...state };
  newState.clients.sender.deposit = {
    ...state.clients.sender.deposit,
    ...action.payload,
  };
  return newState;
};

const clearDeposit = (action, state) => {
  let newState = { ...state };
  newState.clients.sender.deposit = {
    ...initialState.clients.sender.deposit,
  };
  return newState;
};

const updateTransactionReport = (action, state) => {
  let newState = { ...state };
  newState.reports.transactionReport = {
    ...state.reports.transactionReport,
    ...action.payload,
  };
  return newState;
};

const clearTransactionReport = (action, state) => {
  let newState = { ...state };
  newState.reports.transactionReport = {
    ...initialState.reports.transactionReport,
  };
  return newState;
};

const updateDepositReport = (action, state) => {
  let newState = { ...state };
  newState.reports.depositReport = {
    ...state.reports.depositReport,
    ...action.payload,
  };
  return newState;
};

const clearDepositReport = (action, state) => {
  let newState = { ...state };
  console.log(state, newState);
  newState.reports.depositReport = {
    ...initialState.reports.depositReport,
  };
  return newState;
};

const updateOutRemReport = (action, state) => {
  let newState = { ...state };
  newState.reports.outRemReport = {
    ...state.reports.outRemReport,
    ...action.payload,
  };
  return newState;
};

const clearOutRemReport = (action, state) => {
  let newState = { ...state };
  console.log(state, newState);
  newState.reports.outRemReport = {
    ...initialState.reports.outRemReport,
  };
  return newState;
};
const updateInRemReport = (action, state) => {
  let newState = { ...state };
  newState.reports.inRemReport = {
    ...state.reports.inRemReport,
    ...action.payload,
  };
  return newState;
};

const clearInRemReport = (action, state) => {
  let newState = { ...state };
  console.log(state, newState);
  newState.reports.inRemReport = {
    ...initialState.reports.inRemReport,
  };
  return newState;
};

const updateRedemptionReport = (action, state) => {
  let newState = { ...state };
  newState.reports.redemptionReport = {
    ...state.reports.redemptionReport,
    ...action.payload,
  };
  return newState;
};

const clearRedemptionReport = (action, state) => {
  let newState = { ...state };
  console.log(state, newState);
  newState.reports.redemptionReport = {
    ...initialState.reports.redemptionReport,
  };
  return newState;
};
const filters = (state = cloneDeep(initialState), action) => {
  switch (action.type) {
    case actionTypes.UPDATE_OUTWARD_REMITTANCE:
      return updateOutwardRemittance(action, state);
    case actionTypes.CLEAR_OUTWARD_REMITTANCE:
      return clearOutwardRemittance(action, state);
    case actionTypes.UPDATE_CUSTOMER_CREATED_REMITTANCE:
      return updateCustomerCreatedRemittance(action, state);
    case actionTypes.CLEAR_CUSTOMER_CREATED_REMITTANCE:
      return clearCustomerCreatedRemittance(action, state);
    case actionTypes.UPDATE_INWARD_REMITTANCE:
      return updateInwardRemittance(action, state);
    case actionTypes.CLEAR_INWARD_REMITTANCE:
      return clearInwardRemittance(action, state);
    case actionTypes.UPDATE_AVAILABLE_SETTLEMENT:
      return updateAvailableSettlement(action, state);
    case actionTypes.CLEAR_AVAILABLE_SETTLEMENT:
      return clearAvailableSettlement(action, state);
    case actionTypes.UPDATE_REQUEST_RAISED:
      return updateRequestRaised(action, state);
    case actionTypes.CLEAR_REQUEST_RAISED:
      return clearRequestRaised(action, state);
    case actionTypes.UPDATE_REQUEST_RECEIVED:
      return updateRequestReceived(action, state);
    case actionTypes.CLEAR_REQUEST_RECEIVED:
      return clearRequestReceived(action, state);
    case actionTypes.UPDATE_TRANSACTION:
      return updateTransaction(action, state);
    case actionTypes.CLEAR_TRANSACTION:
      return clearTransaction(action, state);
    case actionTypes.UPDATE_DEPOSIT:
      return updateDeposit(action, state);
    case actionTypes.CLEAR_DEPOSIT:
      return clearDeposit(action, state);
    case actionTypes.UPDATE_TRANSACTION_REPORT:
      return updateTransactionReport(action, state);
    case actionTypes.CLEAR_TRANSACTION_REPORT:
      return clearTransactionReport(action, state);
    case actionTypes.UPDATE_DEPOSIT_REPORT:
      return updateDepositReport(action, state);
    case actionTypes.CLEAR_DEPOSIT_REPORT:
      return clearDepositReport(action, state);
    case actionTypes.UPDATE_OUT_REM_REPORT:
      return updateOutRemReport(action, state);
    case actionTypes.CLEAR_OUT_REM_REPORT:
      return clearOutRemReport(action, state);
    case actionTypes.UPDATE_IN_REM_REPORT:
      return updateInRemReport(action, state);
    case actionTypes.CLEAR_IN_REM_REPORT:
      return clearInRemReport(action, state);
    case actionTypes.UPDATE_REMDEMPTION_REPORT:
      return updateRedemptionReport(action, state);
    case actionTypes.CLEAR_REDEMPTION_REPORT:
      return clearRedemptionReport(action, state);
    case actionTypes.UPDATE_EXCHANGE_OFFER_HISTORY:
      return updateExchangeOfferHistory(action, state);
    case actionTypes.CLEAR_EXCHANGE_OFFER_HISTORY:
      return clearExchangeOfferHistory(action, state);
    default:
      return state;
  }
};

export default filters;
