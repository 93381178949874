import React, { useEffect } from "react";
import { format } from "date-fns";
import { capitalize } from "lodash";
import { Box, Typography, LinearProgress, Tooltip, Link } from "@mui/material";
import { rowsPerPageOptions } from "../../../../../utils/common";

import { DataGrid } from "@mui/x-data-grid";
import { formatCurrency, tableCellStyle } from "../../../../../utils/common";
import { Label } from "../../../../../components";
import { Routes } from "../../../../../routes";
import { useHistory } from "react-router-dom";

export default function Transactiontable({
  translist,
  paginateTable,
  setpaginateTable,
  fetchTranslistLoading,
  viewDetails,

  signTransaction,
  customerCreated = false,
}) {
  const history = useHistory();

  const handleChangePage = (newPage) => {
    setpaginateTable({ ...paginateTable, currentPage: newPage });
  };

  const handleChangeRowsPerPage = (newSize) => {
    setpaginateTable({
      ...paginateTable,
      currentPage: 0,
      pageSize: newSize,
    });
  };

  const createDataRow = (transaction) => {
    const type = transaction.type;
    return {
      id: transaction?.id,
      created_by: transaction?.created_user,
      status: transaction?.current_status?.value,
      submissionDate: format(
        new Date(transaction.created_date),
        "dd MMM yyyy HH:mm a"
      ),
      send_amount: (() => {
        return formatCurrency(
          transaction?.send_amount,
          transaction?.send_currency?.decimal_place,
          transaction?.send_currency?.symbol,
          transaction?.send_currency?.symbol_prefix
        );
      })(),
      payout_amount: (() => {
        return formatCurrency(
          transaction?.receive_amount,
          transaction?.receive_currency?.decimal_place,
          transaction?.receive_currency?.symbol,
          transaction?.receive_currency?.symbol_prefix
        );
      })(),
      purpose: transaction?.purpose?.name,
      purpose_description: transaction?.purpose_description,
      balance: (() => {
        return formatCurrency(
          transaction?.balance_post_transaction,
          transaction?.currency?.decimal_place,
          transaction?.currency?.symbol,
          transaction?.currency?.symbol_prefix
        );
      })(),
      deposit_type: transaction?.deposit_type,
      sender: transaction?.sender?.business_name,
      reciever: transaction?.receiver?.business_name,
    };
  };

  const rows =
    translist?.results?.length > 0
      ? translist.results.map((transaction) => createDataRow(transaction))
      : [];

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      minWidth: 120,
      // maxWidth: 200,
      headerClassName: "header",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Tooltip
            placement="bottom"
            sx={{ cursor: "default" }}
            title={params.formattedValue}
          >
            <Typography variant="body2" style={tableCellStyle}>
              {params.formattedValue}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "submissionDate",
      headerName: "Created Date",
      flex: 1,
      minWidth: 200,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Typography variant="body2" style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
    {
      field: "created_by",
      headerName: "Created By",
      flex: 1,
      minWidth: 200,
      // maxWidth: 200,
      headerClassName: "header",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Tooltip
            placement="bottom"
            sx={{ cursor: "default" }}
            title={params.formattedValue}
          >
            <Typography variant="body2" style={tableCellStyle}>
              {params.formattedValue}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "sender",
      headerName: "Sender",
      flex: 1,
      minWidth: 300,
      // maxWidth: 200,
      headerClassName: "header",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Tooltip
            placement="bottom"
            sx={{ cursor: "default" }}
            title={params.formattedValue}
          >
            <Typography variant="body2" style={tableCellStyle}>
              {params.formattedValue}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "reciever",
      headerName: "Reciever",
      flex: 1,
      minWidth: 300,
      // maxWidth: 200,
      headerClassName: "header",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Tooltip
            placement="bottom"
            sx={{ cursor: "default" }}
            title={params.formattedValue}
          >
            <Typography variant="body2" style={tableCellStyle}>
              {params.formattedValue}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 200,
      // maxWidth: 150,
      headerClassName: "header",
      renderCell: (params) => {
        // const {value, }
        return (
          <Label
            variant={"ghost"}
            color={
              (params.formattedValue === "paid" && "complete") ||
              (params.formattedValue === "payout_done" && "complete") ||
              (params.formattedValue === "awaiting_approval" &&
                "application submitted") ||
              "rejected"
            }
            sx={{ textTransform: "capitalize" }}
          >
            {params.formattedValue?.replace(/_/g, " ")}
          </Label>
        );
      },
    },
    {
      field: "send_amount",
      headerName: "Send Amount",
      flex: 1.5,
      minWidth: 200,
      // maxWidth: 200,
      textOverflow: "ellipsis",
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Typography variant="body2" style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
    {
      field: "payout_amount",
      headerName: "Payout Amount",
      flex: 1.5,
      minWidth: 200,
      // maxWidth: 200,
      textOverflow: "ellipsis",
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Typography variant="body2" style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
    {
      field: "purpose",
      headerName: "Purpose",
      flex: 1.5,
      minWidth: 300,
      // maxWidth: 200,
      textOverflow: "ellipsis",
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Typography variant="body2" style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
    {
      field: "purpose_description",
      headerName: "Purpose Description",
      flex: 1.5,
      minWidth: 300,
      // maxWidth: 200,
      textOverflow: "ellipsis",
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Typography variant="body2" style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
    {
      field: "deposit_type",
      headerName: "Deposit Type",
      flex: 1.5,
      minWidth: 200,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Typography variant="body2" style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
  ];

  return (
    <>
      <Box
        sx={{
          // height: 825,
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 0px 8px 0px",
          borderRadius: "8px",
          width: "100%",
          "& .MuiDataGrid-virtualScrollerRenderZone": {
            "& .MuiDataGrid-row": {
              // backgroundColor: 'rgba(235, 235, 235, .7)',
              fontWeight: 500,
              color: "#667085",
              fontFamily: "Inter, sans-serif",
              fontSize: "14px",
            },
          },
          "& .header": {
            backgroundColor: "#F4F4F4",
            "& div": {
              "& div": {
                "& div": {
                  "& div": {
                    fontSize: "14px",
                    fontWeight: 600,
                    fontFamily: "Inter, sans-serif",
                  },
                },
              },
            },
          },
        }}
      >
        <DataGrid
          autoHeight
          sx={{
            borderRadius: "8px",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 0px 8px 0px",
            width: "100%",
            cursor: "pointer",
          }}
          components={{
            LoadingOverlay: LinearProgress,
          }}
          onRowClick={(row) => {
            history.push(Routes.OutwardRemittanceBase.path + `/${row?.id}`);
          }}
          rows={rows}
          columns={columns}
          rowHeight={50}
          disableColumnFilter={true}
          disableColumnMenu={true}
          pagination
          paginationMode="server"
          rowsPerPageOptions={rowsPerPageOptions}
          page={paginateTable.currentPage}
          rowCount={translist?.count || 0}
          pageSize={paginateTable.pageSize}
          onPageChange={(newPage) => handleChangePage(newPage)}
          onPageSizeChange={(newSize) => handleChangeRowsPerPage(newSize)}
          loading={fetchTranslistLoading}
        />
      </Box>
    </>
  );
}
