import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
// @mui
import { Dialog, Stack, Alert, AlertTitle, MenuItem, DialogTitle, DialogActions, DialogContent } from '@mui/material';
import { TextFieldSelect, TextFieldComponent, ButtonSecondary, ButtonPrimary } from '../../../../components';

import { merchantApi } from '../../../../redux/api/apiConfig';
import { KYC_LEVELS, KYC_USERS } from '../../../../ApiUrl';
import { toast } from 'react-toastify';

// ----------------------------------------------------------------------

export default function UpdateKycModal({ open, onClose, kycId, getUsers, ...other }) {
  const [kycLevels, setKycLevels] = useState([]);

  const validationSchema = yup.object({
    kycLevel: yup.string('Select KYC level').required('KYC level is required!'),
    remarks: yup.string('Enter remarks'),
  });

  const getKycLevels = useCallback(async () => {
    try {
      const response = await merchantApi.get(KYC_LEVELS);
      // console.log(response);
      setKycLevels(response.data);
    } catch (error) {
      console.log(error);
      toast.error(error?.data?.data?.message || 'Something went wrong');
      // toast.error('Could not fetch KYC levels.');
    }
  }, []);

  const updateKycLevel = async (values) => {
    const { kycLevel } = values;
    try {
      const response = await merchantApi.patch(`${KYC_USERS}${kycId}/`, {
        level: kycLevel,
      });
      toast.success('KYC level updated successfully.');
      getUsers();
      onClose();
    } catch (error) {
      toast.error(error?.data?.data?.message || 'Something went wrong');
      // toast.error('Could not update KYC level, please try again.');
    }
  };

  const formik = useFormik({
    initialValues: {
      kycLevel: '',
      remarks: '',
    },
    validateOnBlur: true,
    validationSchema: validationSchema,
    onSubmit: updateKycLevel,
    // isInitialValid: isEdit,
    validateOnMount: true,
  });

  useEffect(() => {
    getKycLevels();
  }, [getKycLevels]);

  const { kycLevel } = formik.values;

  let currKycLevel = {};

  if (kycLevel) {
    currKycLevel = kycLevels.find((level) => level.id === kycLevel);
  }

  return (
    <Dialog
      fullWidth
      maxWidth='xs'
      open={open}
      onClose={onClose}
      BackdropProps={{ style: { backgroundColor: 'rgba(11, 5, 35, 0.8)' } }}
      PaperProps={{
        style: {
          boxShadow: 'none',
          borderRadius: '8px',
        },
      }}
      {...other}
    >
      <DialogTitle sx={{ textAlign: 'center' }}>{'Update KYC Level'}</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent sx={{ typography: 'body2' }}>
          <Stack spacing={1} direction={'column'} alignItems={'center'}>
            <TextFieldSelect
              formik={formik}
              name='kycLevel'
              type='text'
              label='KYC level'
              options={kycLevels}
              // onChange={(e) => {
              //   fromAccountChangeHandler(e.target.value);
              // }}
              render={(kycLevel, i) => (
                <MenuItem key={kycLevel.id} value={kycLevel.id}>
                  {kycLevel.name}
                </MenuItem>
              )}
            />

            {kycLevel && (
              <Alert severity='info' sx={{ width: '100%' }}>
                <AlertTitle>{currKycLevel.name}</AlertTitle>
                {currKycLevel.description}
              </Alert>
            )}

            <TextFieldComponent formik={formik} name={'remarks'} label={'Remarks'} type={'text'} multiline required={false} maxRows={3} minRows={3} />
          </Stack>
        </DialogContent>

        <DialogActions
          sx={{
            paddingX: '24px',
            paddingBottom: '15px',
          }}
        >
          <ButtonSecondary onClick={onClose}>Cancel</ButtonSecondary>
          <ButtonPrimary type={'submit'}>Update</ButtonPrimary>
        </DialogActions>
      </form>{' '}
    </Dialog>
  );
}
