import React from 'react';
import PhoneInput from 'react-phone-input-2';
// import 'react-phone-input-2/lib/bootstrap.css';

import 'react-phone-input-2/lib/style.css';
import { Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

const PhoneNumberInput = ({ formik, label, name, onlyCountries = [], setPhoneNumber = () => {}, country = '' }) => {
  let userCountryCode = country;
  const storedUserCountryCode = useSelector((state) => state?.profileReducer?.user?.mto?.country?.code2?.toLowerCase());
  if (userCountryCode === '') {
    userCountryCode = storedUserCountryCode;
  }
  return (
    <Stack>
      <Typography>{label}</Typography>
      <PhoneInput
        onlyCountries={onlyCountries}
        value={formik.values[`${name}`]}
        country={userCountryCode}
        placeholder=''
        inputStyle={{
          height: 54,
          borderRadius: 8,
          border: 'none',
          width: '100%',
          color: 'rgba(0,0,0,0.87)',
        }}
        buttonStyle={{
          height: 54,
          border: 'none',
          background: 'transparent',
        }}
        containerClass='phone-container'
        enableSearch
        disableSearchIcon
        disableCountryGuess={true}
        countryCodeEditable={false}
        isValid={(value, country) => {
          setPhoneNumber(value.replace(country.dialCode, ''), country.dialCode);
          return true;
        }}
        id={name}
        name={name}
        className='bg-white'
        onChange={(phone) => {
          let e = {
            target: {
              name: name,
              value: phone,
            },
          };
          formik.handleChange(e);
        }}
        error={formik.touched[`${name}`] && Boolean(formik.errors[`${name}`])}
        helperText={formik.touched[`${name}`] && formik.errors[`${name}`]}
      />
    </Stack>
  );
};

export default PhoneNumberInput;
