import * as API from '../../ApiUrl';
import { GET_COINS, GET_WALLETS } from '../actionTypes';
import { merchantApi } from '../api/apiConfig';

const getWallets = () => async (dispatch) => {
  setTimeout(() => {
    console.log('wewewe');
  }, 3000);

  try {
    const res = await merchantApi.get(`${API.GET_WALLETS}?limit=1000`);
    dispatch({
      type: GET_WALLETS,
      payload: res.data.results,
    });
  } catch (err) {
    console.log(err, 'errr');
  }
};

const getCoins = () => async (dispatch) => {
  try {
    const res = await merchantApi.get(`${API.GET_COINS}`);
    dispatch({
      type: GET_COINS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err, 'errr');
  }
};
export { getWallets, getCoins };
