import React from 'react';
import { Stack, Box } from '@mui/material';
import HistoryItem from './HistoryItem';

const StatusHistory = ({ statuses, type }) => {
  console.log(statuses);
  return (
    <Box sx={{ marginTop: '1.5rem' }}>

    <Stack spacing={3} alignItems='center'>
      {statuses.map((status) => (
        <HistoryItem status={status} type={type} />
        ))}
    </Stack>
        </Box>
  );
};

export default StatusHistory;
