import React from 'react';
import PropTypes from 'prop-types';
// @mui
import { Box, Card, Stack, Divider, CardHeader, Typography, CardContent } from '@mui/material';

// ----------------------------------------------------------------------

export default function RedeemSummary({ total, discount, subtotal, currency, enableEdit = false }) {
  return (
    <Card sx={{ mb: 3, borderRadius: '8px' }}>
      <CardHeader title='Settlement Summary' />

      <CardContent>
        <Stack spacing={2}>
          <Stack direction='row' justifyContent='space-between'>
            <Typography variant='body2' sx={{ color: 'text.secondary' }}>
              Sub Total
            </Typography>
            <Typography variant='subtitle2'>
              {subtotal} {currency}
            </Typography>
          </Stack>

          <Stack direction='row' justifyContent='space-between'>
            <Typography variant='body2' sx={{ color: 'text.secondary' }}>
              Portal Fees
            </Typography>
            <Typography variant='subtitle2'>{discount ? ` - ${discount} ${currency}` : '-'}</Typography>
          </Stack>

          <Divider />

          <Stack direction='row' justifyContent='space-between'>
            <Typography variant='subtitle1'>Total</Typography>
            <Box sx={{ textAlign: 'right' }}>
              <Typography variant='subtitle1' sx={{ color: 'error.main' }}>
                {total} {currency}
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
