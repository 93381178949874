import React from 'react';
import { TextField, Table, TableContainer, TableRow, TableBody, TableHead, TableCell, Typography, InputAdornment } from '@mui/material';

import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { formatCurrency } from '../../../../../utils/common';
const PaymentDetails = ({ fee, setFee, sendCurrency }) => {
  const StyledRowResult = styled(TableRow)(({ theme }) => ({
    '& td': {
      paddingTop: '1rem',
      paddingBottom: '1rem',
    },
  }));

  
  const coins = useSelector((state) => state.walletsReducer.coins);
  const currency = useSelector((state) => state.configReducer.currencyTypes);
  
  const coin = coins?.find((coin) => coin.id === sendCurrency);
  console.log(sendCurrency, coins)
  
  // const sendCoin = currency?.find((cur) => cur.code === coin.symbol) || {};

  const { symbol = '', decimal_place = 2 } = coin;

  return (
    <TableContainer sx={{ overflow: 'unset' }}>
      <Table>
        <TableHead
          sx={{
            borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
            '& th': {
              backgroundColor: 'transparent',
              fontSize: '14px',
              fontWeight: 600,
              // fontFamily: 'Inter, sans-serif',
            },
          }}
        >
          <TableRow>
            <TableCell width={40}>#</TableCell>

            <TableCell align='left'>Description</TableCell>

            {/* <TableCell align="left">Amount</TableCell> */}

            <TableCell width={320} align='right'>
              Amount
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody
          sx={{
            '& td': {
              fontWeight: 500,
              color: '#667085',
              // fontFamily: 'Inter, sans-serif',
              fontSize: '14px',
            },
          }}
        >
          <TableRow
            // key={index}
            sx={{
              borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
            }}
          >
            <TableCell>{1}</TableCell>

            <TableCell align='left'>Transfer Amount</TableCell>

            <TableCell align='right'>{formatCurrency(fee.send_amount, decimal_place, symbol)}</TableCell>
          </TableRow>
          <TableRow
            // key={index}
            sx={{
              borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
            }}
          >
            <TableCell>{2}</TableCell>

            <TableCell align='left'>Sender MTO Fee</TableCell>

            <TableCell align='right' width={150}>
              <TextField
                // fullWidth
                variant='outlined'
                name={'new_rate'}
                id={'new_rate'}
                type={'number'}
                value={fee.outward_fee}
                onChange={(e) => {
                  let value = e.target.value;
                  setFee((prevState) => ({
                    ...prevState,
                    outward_fee: value,
                    user_pay: prevState.user_pay + (value - prevState.outward_fee),
                  }));
                }}
                size={'small'}
                InputProps={{
                  endAdornment: <InputAdornment position='end'>{symbol}</InputAdornment>,
                }}
              />
            </TableCell>
          </TableRow>{' '}
          <TableRow
            // key={index}
            sx={{
              borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
            }}
          >
            <TableCell>{3}</TableCell>

            <TableCell align='left'>Recipient MTO Fee</TableCell>

            <TableCell align='right'>{formatCurrency(Number(fee.inward_fee), decimal_place, symbol)}</TableCell>
          </TableRow>{' '}
          <TableRow
            // key={index}
            sx={{
              borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
            }}
          >
            <TableCell>{4}</TableCell>

            <TableCell align='left'>Other Fee</TableCell>

            <TableCell align='right'>{formatCurrency(fee.other_fee, decimal_place, symbol)}</TableCell>
          </TableRow>{' '}
          <StyledRowResult>
            <TableCell colSpan={1} />
            <TableCell colSpan={1} />

            {/* <TableCell align="right">
              <Typography variant="h6" sx={{ color: 'black' }}>
               
              </Typography>
            </TableCell> */}

            <TableCell align='right'>
              <Typography variant='h6' sx={{ color: 'black' }}>
                Total {formatCurrency(fee.user_pay, decimal_place, symbol)}
              </Typography>
            </TableCell>
          </StyledRowResult>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PaymentDetails;
